<template>
  <el-dialog
    class="editDialog"
    :append-to-body="true"
    width="600px"
    :visible.sync="showEditDialog"
    :close-on-click-modal="false"
    :destroy-on-close="true"
    @close="$emit('update:isShow', false)"
    :show-close="true"
  >
    <div slot="title" class="title">
      {{ options.user_id ? '编辑' : '新增' }}用户
    </div>

    <div class="content">
      <el-form label-width="120px" :model="form" :rules="rules" ref="form">
        <el-form-item label="头像" prop="nickname" ref="nickname">
          <UploadImage v-model="form.head_pic" />
        </el-form-item>
        <el-form-item label="昵称" prop="nickname" ref="nickname">
          <el-input v-model="form.nickname" placeholder="请输入昵称"></el-input>
        </el-form-item>

        <template v-if="!form.user_id">
          <el-form-item label="密码" prop="password" ref="password">
            <el-input
              v-model="form.password"
              type="password"
              placeholder="请输入密码"
            ></el-input>
          </el-form-item>

          <el-form-item
            label="确认密码"
            prop="password_confirm"
            ref="password_confirm"
          >
            <el-input
              v-model="form.password_confirm"
              type="password"
              placeholder="请再次输入密码"
            ></el-input>
          </el-form-item>
        </template>

        <el-form-item label="手机号" prop="mobile" ref="mobile">
          <el-input v-model="form.mobile" placeholder="请输入手机号"></el-input>
        </el-form-item>

        <el-form-item label="邮箱" prop="email" ref="email">
          <el-input v-model="form.email" placeholder="请输入邮箱"></el-input>
        </el-form-item>

        <el-form-item label="身份类型" prop="user_level_id" ref="user_level_id">
          <el-select
            v-model="form.user_level_id"
            placeholder="请选择身份类型"
            @change="userLevelChange"
          >
            <el-option
              v-for="(item, index) in levelList"
              :key="index"
              :label="item.name"
              :value="item.user_level_id"
            >
            </el-option>
          </el-select>
        </el-form-item>

        <el-form-item
          v-if="form.user_level_id !== 1"
          label="会员开始时间"
          prop="level_start_time"
          ref="level_start_time"
        >
          <el-date-picker
            :clearable="false"
            v-model="form.level_start_time"
            type="datetime"
            placeholder="选择会员开始时间"
          >
          </el-date-picker>
        </el-form-item>

        <el-form-item
          v-if="form.user_level_id !== 1"
          label="会员结束时间"
          prop="level_end_time"
          ref="level_end_time"
        >
          <el-date-picker
            :clearable="false"
            v-model="form.level_end_time"
            type="datetime"
            placeholder="选择会员结束时间"
          >
          </el-date-picker>
        </el-form-item>

        <el-form-item label="性别" prop="sex" ref="sex">
          <el-radio-group v-model="form.sex">
            <el-radio :label="1">男</el-radio>
            <el-radio :label="2">女</el-radio>
          </el-radio-group>
        </el-form-item>

        <el-form-item label="内部员工" prop="is_staff" ref="is_staff">
          <el-radio-group v-model="form.is_staff">
            <el-radio :label="1">是</el-radio>
            <el-radio :label="0">否</el-radio>
          </el-radio-group>
        </el-form-item>
      </el-form>
    </div>

    <div slot="footer">
      <el-button type="success" :loading="submitLoading" @click="submit"
        >保存</el-button
      >
      <el-button type="info" plain @click="$emit('update:isShow', false)"
        >取消</el-button
      >
    </div>
  </el-dialog>
</template>
<script>
import { mobile, email } from '@/regular'
import dayjs from 'dayjs'

export default {
  components: {
    UploadImage: () => import('@/components/upload/Image.vue'),
  },
  props: {
    isShow: {
      type: Boolean,
      default: false,
    },
    options: {
      type: Object,
      default: function () {
        return {}
      },
    },
  },
  data() {
    // 二次密码校验
    const confirmPasswordValidate = (rule, value, callback) => {
      if (value !== this.form.password) {
        callback(new Error('两次输入密码不一致!'))
      } else {
        callback()
      }
    }
    return {
      showEditDialog: false,
      submitLoading: false,
      form: {
        level_start_time: '',
        level_end_time: '',
      },
      groupData: [],
      levelList: [],
      rules: {
        nickname: [
          {
            required: true,
            message: '请输入昵称',
            trigger: ['blur', 'change'],
          },
        ],
        password: [
          {
            required: true,
            message: '请输入密码',
            trigger: ['blur', 'change'],
          },
        ],
        password_confirm: [
          {
            required: true,
            message: '请再次输入密码',
            trigger: ['blur', 'change'],
          },
          { validator: confirmPasswordValidate, trigger: ['blur', 'change'] },
        ],
        mobile: [
          {
            required: false,
            message: '请输入手机号',
            trigger: ['blur', 'change'],
          },
          {
            pattern: mobile,
            message: '请输入正确的手机号',
            trigger: ['blur', 'change'],
          },
        ],
        email: [
          {
            required: false,
            message: '请输入邮箱',
            trigger: ['blur', 'change'],
          },
          {
            pattern: email,
            message: '请输入正确的邮箱',
            trigger: ['blur', 'change'],
          },
        ],
        user_level_id: [
          {
            required: true,
            message: '请选择身份类型',
            trigger: ['blur', 'change'],
          },
        ],
        level_start_time: [
          {
            required: true,
            message: '请选择会员开始时间',
            trigger: ['blur', 'change'],
          },
        ],
        level_end_time: [
          {
            required: true,
            message: '请选择会员结束时间',
            trigger: ['blur', 'change'],
          },
        ],
      },
    }
  },
  inject: ['refresh'],
  watch: {
    isShow: {
      immediate: true, //初始化立即执行
      handler: function (newVal) {
        this.$nextTick(() => {
          newVal && this.$refs.form && this.$refs.form.clearValidate()
        })
        this.showEditDialog = newVal
      },
    },
    options: {
      immediate: true, //初始化立即执行
      deep: true, //对象深度监测
      handler: function (newVal, oldVal) {
        this.init(newVal)
      },
    },
  },
  created() {
    this.$api.user
      .user_level({
        method: 'get.user.level.list',
        page_no: 1,
        page_size: 100,
      })
      .then((res) => {
        this.levelList = res.data
      })
  },
  methods: {
    init(options) {
      console.log(options)
      if (options.user_id) {
        //编辑进来的会有id需要
        this.form = JSON.parse(JSON.stringify(options))
        this.form.level_start_time = dayjs(
          dayjs(this.form.level_start_time * 1000)
        ).format('YYYY-MM-DD HH:mm')
        this.form.level_end_time = dayjs(
          dayjs(this.form.level_end_time * 1000)
        ).format('YYYY-MM-DD HH:mm')
      } else {
        this.form = {
          sex: 1,
          is_staff: 0,
          user_level_id: 1,
          level_start_time: '',
          level_end_time: '',
        }
      }
    },
    userLevelChange() {
      let currItem = this.levelList.find((item) => {
        return item.user_level_id === this.form.user_level_id
      })
      console.log(currItem)
      if (currItem.user_level_id !== 1) {
        this.form.level_start_time = (
          this.form.level_start_time * 1000
        )().format('YYYY-MM-DD HH:mm')
        this.form.level_end_time = dayjs()
          .add(currItem.validaty, 'day')
          .format('YYYY-MM-DD HH:mm')
      }
    },
    submit() {
      this.$refs.form.validate((valid, obj) => {
        if (valid) {
          console.log('this.form:', this.form)
          this.submitLoading = true
          this.$api.user
            .user(
              Object.assign(this.form, {
                method: this.form.user_id ? 'set.user.item' : 'add.user.item',
              })
            )
            .then((res) => {
              this.submitLoading = false
              this.refresh(1)
              this.$message.success('操作成功！')
            })
            .catch((err) => {
              this.submitLoading = false
              console.log(err)
            })
        } else {
          this.scrollView(obj)
        }
      })
    },
  },
}
</script>
