<template>
  <el-dialog
    class="editDialog"
    :append-to-body="true"
    width="1200px"
    :visible.sync="showEditDialog"
    :close-on-click-modal="false"
    :destroy-on-close="true"
    @close="$emit('update:isShow', false)"
    :show-close="true"
  >
    <template v-if="showEditDialog">
      <div slot="title" class="title">
        {{ options.course_id ? "编辑" : "新增" }}课程
      </div>
      <div class="content" v-loading="loading">
        <el-form
          class="column2"
          label-width="120px"
          :model="form"
          :rules="rules"
          ref="form"
        >
          <el-form-item label="类型" prop="type" ref="type">
            <el-select v-model="form.type" placeholder="请选择类型">
              <el-option
                v-for="item in typeData"
                :key="item.type_id"
                :label="item.name"
                :value="item.type_id"
              >
              </el-option>
            </el-select>
          </el-form-item>

          <el-form-item label="标题" prop="title">
            <el-input v-model="form.title" placeholder="请输入标题"></el-input>
          </el-form-item>

          <el-form-item
            class="treeselect-el"
            label="课程分类"
            prop="course_cat_id"
            ref="course_cat_id"
          >
            <Treeselect
              :multiple="true"
              :normalizer="normalizer"
              @input="changeParentId"
              placeholder="请选择父级分类"
              v-model="form.course_cat_id"
              :options="catTreeData"
            />
          </el-form-item>

          <el-form-item :label="'课程标签'" prop="course_tag_id">
            <el-select v-model="form.course_tag_id" clearable multiple>
              <el-option
                v-for="item in tagData"
                :key="item.course_tag_id"
                :label="item.title"
                :value="item.course_tag_id"
              >
              </el-option>
            </el-select>
          </el-form-item>

          <el-form-item :label="'讲师/机构'" prop="tutor_id">
            <ChoiceData
              :selectedData="[{ organ_id: form.tutor_id }]"
              :configure="configure"
              @choiceDataChange="choiceDataChange"
              :show-clear="tutor_name !== ''"
              @clear="tutor_name = ''"
            >
              <el-input
                slot="button"
                v-model="tutor_name"
                placeholder="请选择讲师/机构"
              ></el-input>
              <div
                class="mainList_operation clearfloat"
                slot="search"
                slot-scope="{ params, search }"
              >
                <div class="mainList_operation_search">
                  <el-input
                    class="item"
                    placeholder="请输入机构名称"
                    clearable
                    v-model="params.company_name"
                  ></el-input>
                  <el-input
                    class="item"
                    placeholder="请输入手机号"
                    clearable
                    v-model="params.mobile"
                  ></el-input>
                  <el-checkbox
                    style="margin-right: 10px"
                    v-model="isOrganAllData"
                    @change="organDataChange"
                    >查看全部讲师/机构?</el-checkbox
                  >
                  <el-button
                    type="primary"
                    plain
                    icon="el-icon-search"
                    @click="search(1)"
                    >搜索</el-button
                  >
                </div>
              </div>
              <template slot="tableColumn">
                <el-table-column
                  align="center"
                  prop="company_name"
                  label="机构名称"
                ></el-table-column>
                <el-table-column
                  align="center"
                  prop="company_logo"
                  label="机构LOGO"
                >
                  <template slot-scope="scope">
                    <div class="img_box">
                      <img
                        v-if="scope.row.company_logo"
                        :src="scope.row.company_logo"
                      />
                    </div>
                  </template>
                </el-table-column>
                <el-table-column
                  align="center"
                  prop="mobile"
                  label="手机号"
                ></el-table-column>
                <el-table-column
                  align="center"
                  prop="nickname"
                  label="昵称"
                ></el-table-column>
                <el-table-column
                  align="center"
                  prop="email"
                  label="邮箱"
                ></el-table-column>
              </template>
            </ChoiceData>
          </el-form-item>

          <el-form-item label="讲师名称" prop="tutor_name">
            <el-input
              v-model="form.tutor_name"
              placeholder="请输入讲师名称"
            ></el-input>
          </el-form-item>

          <el-form-item label="封面" prop="image">
            <UploadImage v-model="form.image" />
          </el-form-item>

          <el-form-item label="讲师头像" prop="tutor_head_pic">
            <UploadImage v-model="form.tutor_head_pic" />
          </el-form-item>

          <el-form-item label="讲师简介" prop="tutor_desc">
            <Editor
              :value.sync="form.tutor_desc"
              :offUploadImg="false"
            ></Editor>
          </el-form-item>

          <el-form-item label="课程描述" prop="description" ref="description">
            <Editor
              :value.sync="form.description"
              :offUploadImg="false"
            ></Editor>
          </el-form-item>

          <template v-if="form.type === 2 || form.type === 4">
            <el-form-item
              label="折扣价格"
              prop="price"
              v-if="form.is_activity === 1"
            >
              <el-input
                v-model="form.price"
                placeholder="请输入折扣价格"
              ></el-input>
            </el-form-item>

            <el-form-item label="原价" prop="scribe_price">
              <el-input
                v-model="form.scribe_price"
                placeholder="请输入原价"
              ></el-input>
            </el-form-item>

            <el-form-item
              v-if="form.type === 2 || form.type === 4"
              label="是否折扣"
              prop="is_activity"
            >
              <el-radio-group v-model="form.is_activity" size="medium">
                <el-radio :label="1">是</el-radio>
                <el-radio :label="2">否</el-radio>
              </el-radio-group>
            </el-form-item>
            <el-form-item
              v-if="form.is_activity == 1"
              label="折扣起止时间"
              prop="activity_time"
              required
            >
              <el-date-picker
                v-model="form.activity_time"
                type="daterange"
                range-separator="至"
                start-placeholder="开始日期"
                end-placeholder="结束日期"
              >
              </el-date-picker>
            </el-form-item>

            <el-form-item
              v-if="form.type === 2 && form.is_activity === 1"
              label="VIP会员折扣价"
              prop="activity_price"
            >
              <el-input
                format="timestamp"
                v-model="form.activity_price"
                placeholder="请输入VIP会员折扣价"
              ></el-input>
            </el-form-item>

            <!-- <el-form-item
              v-if="form.type === 2 && form.is_activity === 1"
              label="折扣起止时间"
              prop="activity_date"
            >
              <el-date-picker
                value-format="yyyy-MM-dd HH:mm"
                v-model="form.activity_date"
                type="datetimerange"
                range-separator="至"
                start-placeholder="开始日期"
                end-placeholder="结束日期"
                @change="activityDateChange"
              >
              </el-date-picker>
            </el-form-item> -->

            <el-form-item label="有效期" prop="is_end_time" ref="is_end_time">
              <el-radio-group v-model="form.is_end_time">
                <el-radio :value="1" :label="1">有效天数</el-radio>
                <el-radio :value="2" :label="2">截止日</el-radio>
              </el-radio-group>
            </el-form-item>

            <el-form-item
              v-if="form.is_end_time == 1"
              label="有效期/天"
              prop="useful_time"
            >
              <el-input-number
                :min="0"
                v-model="form.useful_time"
                placeholder="请输入购买后的有效期天数（0为长期有效）"
              ></el-input-number>
            </el-form-item>
            <el-form-item
              v-if="form.is_end_time == 2"
              label="截止日"
              prop="end_time"
            >
              <el-date-picker
                value-format="yyyy-MM-dd"
                v-model="form.end_time"
                type="date"
              >
              </el-date-picker>
            </el-form-item>
          </template>

          <el-form-item label="视频地址" prop="video_url">
            <el-row type="flex" align="middle">
              <el-col :span="8">
                <UploadVideo v-model="form.video_url" />
              </el-col>
              <el-col :span="16">
                <el-input
                  type="textarea"
                  resize="none"
                  style="margin-top: 10px"
                  v-model="form.video_url"
                  placeholder="请输入视频地址"
                ></el-input>
              </el-col>
            </el-row>
          </el-form-item>

          <el-form-item
            v-if="form.type === 2 || form.type === 4"
            label="免费试看"
            prop="is_try"
            ref="is_try"
          >
            <el-radio-group v-model="form.is_try">
              <el-radio :value="1" :label="1">是</el-radio>
              <el-radio :value="2" :label="2">否</el-radio>
            </el-radio-group>
          </el-form-item>
          <el-form-item
            v-if="
              (form.type === 2 && form.is_try === 1) ||
              (form.type === 4 && form.is_try === 1)
            "
            label="试看时长"
            prop="try_time"
            ref="try_time"
          >
            <el-input
              v-model="form.try_time"
              placeholder="请输入试看时长（单位秒）"
              ><template slot="append">s</template></el-input
            >
          </el-form-item>

          <el-form-item label="是否置顶" prop="is_top">
            <el-radio-group v-model="form.is_top" size="medium">
              <el-radio :label="1">是</el-radio>
              <el-radio :label="0">否</el-radio>
            </el-radio-group>
          </el-form-item>

          <el-form-item label="发布时间" prop="release_time">
            <el-date-picker
              value-format="yyyy-MM-dd HH:mm:ss"
              v-model="form.release_time"
              type="datetime"
            >
            </el-date-picker>
          </el-form-item>

          <el-form-item label="是否启用" prop="status">
            <el-radio-group v-model="form.status" size="medium">
              <el-radio :label="1">启用</el-radio>
              <el-radio :label="0">禁用</el-radio>
            </el-radio-group>
          </el-form-item>
        </el-form>
      </div>

      <div slot="footer" v-show="!loading">
        <el-button type="success" :loading="submitLoading" @click="submit"
          >保存</el-button
        >
        <el-button type="info" plain @click="$emit('update:isShow', false)"
          >取消</el-button
        >
      </div>
    </template>
  </el-dialog>
</template>
<script>
import dayjs from "dayjs";
import { initTreeData } from "@/util/common";
import "@riophae/vue-treeselect/dist/vue-treeselect.css";

export default {
  components: {
    Editor: () => import("@/components/editor/index.vue"),
    Treeselect: () => import("@riophae/vue-treeselect"),
    UploadImage: () => import("@/components/upload/Image.vue"),
    ChoiceData: () => import("@/components/ChoiceData.vue"),
    UploadVideo: () => import("@/components/upload/Video.vue")
  },
  props: {
    isShow: {
      type: Boolean,
      default: false
    },
    options: {
      type: Object,
      default: function () {
        return {};
      }
    }
  },
  data() {
    return {
      showEditDialog: false,
      loading: true,
      submitLoading: false,
      form: { useful_time: 365, is_end_time: 1 },
      activityDate: [],
      organData: [],
      rules: {
        course_cat_id: [
          {
            required: true,
            message: "请选择课程分类",
            trigger: ["blur", "change"]
          }
        ],
        course_tag_id: [
          {
            required: true,
            message: "请选择课程标签",
            trigger: ["blur", "change"]
          }
        ],
        tutor_id: [
          {
            required: false,
            message: "请选择讲师",
            trigger: ["blur", "change"]
          }
        ],
        tutor_name: [
          {
            required: true,
            message: "请输入讲师名称",
            trigger: ["blur", "change"]
          }
        ],
        tutor_desc: [
          {
            required: true,
            message: "请输入讲师简介",
            trigger: ["blur", "change"]
          }
        ],
        tutor_head_pic: [
          {
            required: false,
            message: "请上传讲师头像",
            trigger: ["blur", "change"]
          }
        ],
        title: [
          {
            required: true,
            message: "请输入标题",
            trigger: ["blur", "change"]
          }
        ],
        image: [
          {
            required: true,
            message: "请上传封面",
            trigger: ["blur", "change"]
          }
        ],
        video_url: [
          {
            required: true,
            message: "请上传视频或填写视频地址",
            trigger: ["blur", "change"]
          }
        ],
        keywords: [
          {
            required: true,
            message: "请输入关键词",
            trigger: ["blur", "change"]
          }
        ],
        price: [
          {
            required: true,
            message: "请输入价格",
            trigger: ["blur", "change"]
          }
        ],
        scribe_price: [
          {
            required: true,
            message: "请输入原价",
            trigger: ["blur", "change"]
          }
        ],
        activity_price: [
          {
            required: true,
            message: "请输入VIP会员折扣价",
            trigger: ["blur", "change"]
          }
        ],
        activity_date: [
          {
            required: true,
            message: "请选择折扣起止时间",
            trigger: ["blur", "change"]
          }
        ],
        useful_time: [
          {
            required: true,
            message: "请输入有效期天数",
            trigger: ["blur", "change"]
          }
        ],
        type: [
          {
            required: true,
            message: "请选择类型",
            trigger: ["blur", "change"]
          }
        ],
        description: [
          {
            required: true,
            message: "请输入课程描述",
            trigger: ["blur", "change"]
          }
        ]
      },
      typeData: [
        {
          type_id: 1,
          name: "公开课"
        },
        {
          type_id: 2,
          name: "精品课"
        },
        {
          type_id: 3,
          name: "员工专属"
        },
        {
          type_id: 4,
          name: "会员课程"
        },
        {
          type_id: 5,
          name: "系列课程"
        }
      ],
      tagData: [],
      catProps: {
        // 配置项（必选）
        value: "course_cat_id",
        label: "name",
        children: "children"
        // disabled:true
      },
      catTreeData: [],
      tutor_name: "",
      isOrganAllData: false,
      configure: {
        request: this.$api.organ.organ,
        idKey: "organ_id",
        params: {
          method: "get.organ.list"
        },
        title: "讲师/机构列表"
      }
    };
  },
  inject: ["refresh"],
  watch: {
    isShow: {
      immediate: true, //初始化立即执行
      handler: function (newVal) {
        this.$nextTick(() => {
          newVal && this.$refs.form && this.$refs.form.clearValidate();
        });
        this.showEditDialog = newVal;
      }
    },
    options: {
      immediate: true, //初始化立即执行
      deep: true, //对象深度监测
      handler: function (newVal, oldVal) {
        console.log(newVal);
        this.init(newVal);
      }
    },
    tutor_name(newVal, oldVal) {
      if (newVal === "") {
        this.form.tutor_id = 0;
      }
    }
  },
  created() {
    this.getOrganData();
    this.getCatData();
    this.getTagData();
  },
  methods: {
    organDataChange(data) {
      console.log(data);
      if (data) {
        let params = JSON.parse(JSON.stringify(this.configure.params));
        delete params.is_admin;
        this.configure.params = params;
      } else {
        let params = JSON.parse(JSON.stringify(this.configure.params));
        params.is_admin = 1;
        this.configure.params = params;
      }
    },
    choiceDataChange(r) {
      this.form.tutor_id = r.organ_id;
      if (r.type === 1) {
        this.tutor_name = r.name;
      } else {
        this.tutor_name = r.company_name;
      }
      this.$refs.form.validateField("tutor_id");
    },
    activityDateChange(date) {
      if (date) {
        this.form.activity_start_time = dayjs(date[0]).unix();
        this.form.activity_end_time = dayjs(date[1]).unix();
      } else {
        this.form.activity_start_time = "";
        this.form.activity_end_time = "";
      }
    },
    init(options) {
      if (options.course_id) {
        //编辑进来的会有id需要
        this.loading = true;

        this.$api.course
          .course({
            course_id: options.course_id,
            method: "get.course.item"
          })
          .then((res) => {
            res.data.course_tag_id = res.data.course_tag_id.map((item) => {
              return Number(item);
            });

            res.data.activity_date = [
              new Date(res.data.activity_start_time),
              new Date(res.data.activity_end_time)
            ];
            console.log(res.data);
            this.form = res.data;
            if (res.data.get_organ) {
              if (res.data.get_organ.type === 1) {
                this.tutor_name = res.data.get_organ.name;
              } else {
                this.tutor_name = res.data.get_organ.company_name;
              }
            }

            this.loading = false;
          })
          .catch((err) => {
            this.loading = false;
            console.log(err);
          });
      } else {
        this.form = {
          status: 1,
          tutor_id: 0,
          is_top: 0,
          is_activity: 2,
          activity_date: []
        };
        this.loading = false;
      }
    },
    getOrganData() {
      this.$api.organ
        .organ({
          method: "get.organ.list"
        })
        .then((res) => {
          this.organData = res.data.items;
        })
        .catch((err) => {
          console.log(err);
        });
    },
    // 获取课程分类列表
    getCatData() {
      this.$api.course
        .course_cat({
          method: "get.course_cat.list"
        })
        .then((res) => {
          this.catTreeData = initTreeData(
            res.data.items,
            "parent_id",
            "course_cat_id"
          );
        })
        .catch((err) => {
          console.log(err);
        });
    },
    // 获取课程标签列表
    getTagData() {
      this.$api.course
        .course_tag({
          method: "get.course_tag.list",
          page_no: 1,
          page_size: 999
        })
        .then((res) => {
          this.tagData = res.data.items;
        })
        .catch((err) => {
          console.log(err);
        });
    },
    submit() {
      this.$refs.form.validate((valid, obj) => {
        if (valid) {
          this.submitLoading = true;
          if (this.form.type === 1 || this.form.type === 3) {
            delete this.form.price;
            delete this.form.scribe_price;
            delete this.form.is_try;
            delete this.form.try_time;
          }
          if (this.form.type === 2) {
            delete this.form.activity_date;
          }
          this.$api.course
            .course(
              this.form.combination({
                module: "admin",
                method: this.form.course_id
                  ? "set.course.item"
                  : "add.course.item"
              })
            )
            .then((res) => {
              this.submitLoading = false;
              this.refresh(1);
              this.$message.success("操作成功！");
            })
            .catch((err) => {
              this.submitLoading = false;
              console.log(err);
            });
        } else {
          this.scrollView(obj);
        }
      });
    },
    normalizer(node) {
      return {
        id: node.course_cat_id,
        label: node.cat_name,
        children: node.children
      };
    },
    changeParentId() {
      this.$refs.form.validateField("parentId");
    }
  }
};
</script>
<style scoped lang="scss">
.el-tag {
  margin-right: 5px;
}

.img_box {
  display: inline-flex;
  justify-content: left;
  align-items: flex-end;

  img {
    width: 30px;
    height: 30px;
    border-radius: 100%;
    margin-right: 5px;
  }
}

/deep/.treeselect-el {
  .el-form-item__content {
    line-height: unset;

    .vue-treeselect__control {
      height: 40px;
    }
  }
}
</style>
