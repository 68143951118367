<template>
  <div>
    <Crumbs></Crumbs>
    <div class="mainList">
      <div class="mainList_operation clearfloat">
        <div class="mainList_operation_search">
          <el-input
            v-if="selectType == 1"
            class="item"
            placeholder="请输入搜索内容"
            clearable
            v-model="parameter.keywords"
            style="background-color: #fff; width: 300px"
          >
            <el-select
              v-model="selectType"
              slot="prepend"
              placeholder="请选择"
              style="width: 100px"
            >
              <el-option label="商品名称" :value="1"></el-option>
              <el-option label="用户名" :value="2"></el-option>
              <el-option label="手机号" :value="3"></el-option>
              <el-option label="机构" :value="4"></el-option> </el-select
          ></el-input>
          <el-input
            v-else
            class="item"
            placeholder="请输入搜索内容"
            clearable
            v-model="parameter.account"
            style="background-color: #fff; width: 300px"
          >
            <el-select
              v-model="selectType"
              slot="prepend"
              placeholder="请选择"
              style="width: 100px"
            >
              <el-option label="商品名称" :value="1"></el-option>
              <el-option label="用户名" :value="2"></el-option>
              <el-option label="手机号" :value="3"></el-option>
              <el-option label="机构" :value="4"></el-option> </el-select
          ></el-input>
          <el-select
            class="item"
            placeholder="请选择交易状态 "
            clearable
            v-model="parameter.status"
          >
            <el-option label="已过期" value="0"></el-option>
            <el-option label="待支付" value="1"></el-option>
            <el-option label="待发货" value="2"></el-option>
            <el-option label="待签收货" value="3"></el-option>
            <el-option label="已签收" value="4"></el-option>
            <el-option label="已完结" value="5"></el-option>
            <el-option label="已取消" value="6"></el-option>
          </el-select>
          <el-select
            class="item"
            placeholder="请选择支付状态 "
            v-model="parameter.is_pay"
          >
            <el-option label="已支付" value="1"></el-option>
            <el-option label="未支付" value="2"></el-option>
          </el-select>
          <el-date-picker
            clearable
            style="margin-right: 15px"
            v-model="parameter.add_time"
            type="datetimerange"
            range-separator="至"
            start-placeholder="开始日期"
            end-placeholder="结束日期"
          >
          </el-date-picker>
          <el-button
            type="primary"
            plain
            icon="el-icon-search"
            @click="pageChangeHandler(1)"
            >搜索</el-button
          >
        </div>
        <div class="fr">
          <!-- <el-button type="primary" icon="el-icon-plus" @click="add"
            >新增</el-button
          > -->
          <!-- <el-button
            icon="el-icon-delete"
            v-if="selectRows.length > 0"
            type="danger"
            @click="handleDelete"
          >
            批量删除
          </el-button> -->
        </div>
      </div>
      <div class="mainList_content">
        <div class="mainList_content_sub">
          <el-table
            ref="multipleTable"
            border
            :data="tableData"
            tooltip-effect="dark"
            height="string"
            v-loading="loading"
            @selection-change="setSelectRows"
          >
            <!-- <el-table-column
              align="center"
              show-overflow-tooltip
              type="selection"
            ></el-table-column> -->
            <!-- <el-table-column
              label="序号"
              type="index"
              align="center"
              width="70"
            ></el-table-column> -->
            <el-table-column
              label="订单id"
              align="center"
              prop="order_id"
              width="100"
            ></el-table-column>
            <el-table-column
              label="订单号"
              align="center"
              prop="order_no"
              width="100"
            ></el-table-column>
            <el-table-column align="center" label="商品名称">
              <template slot-scope="scope">
                {{
                  scope.row.get_order_goods
                    .map((item) => {
                      return item.goods_name;
                    })
                    .join(",")
                }}
              </template>
            </el-table-column>
            <el-table-column
              align="center"
              prop="pay_amount"
              label="支付金额"
              width="100"
            ></el-table-column>
            <el-table-column
              align="center"
              prop="goods_amount"
              label="商品金额"
              width="100"
            ></el-table-column>
            <el-table-column
              align="center"
              prop="total_amount"
              label="应付金额"
              width="100"
            ></el-table-column>
            <el-table-column
              align="center"
              prop="payment_no"
              label="支付流水号"
              width="100"
            ></el-table-column>
            <el-table-column
              align="center"
              prop="delivery_company"
              label="物流公司"
              width="100"
            ></el-table-column>
            <el-table-column
              align="center"
              prop="delivery_code"
              label="物流单号"
              width="100"
            ></el-table-column>

            <el-table-column
              align="center"
              prop="delivery_fee"
              label="运费"
              width="100"
            ></el-table-column>

            <el-table-column
              align="center"
              prop="consignee"
              label="买家"
              width="150"
            >
              <template slot-scope="scope">
                <div class="order-text">
                  <p style="width: 130px">
                    <span class="son">{{ scope.row.get_user.nickname }}</span
                    ><br />
                    <span class="son">{{ scope.row.get_user.mobile }}</span>
                    <span class="son">{{ scope.row.get_user.email }}</span>
                  </p>

                  <p>
                    <span class="son">{{
                      scope.row.get_delivery | getDelivery
                    }}</span>
                  </p>

                  <p>
                    <el-tooltip placement="top">
                      <div slot="content">
                        姓名：{{ scope.row.get_user.nickname }}<br />
                        手机：{{ scope.row.get_user.mobile }}<br />

                        邮箱：{{ scope.row.get_user.email }}
                      </div>
                      <i class="el-icon-house" />
                    </el-tooltip>

                    <el-tooltip
                      v-if="scope.row.buyer_remark"
                      :content="scope.row.buyer_remark"
                      placement="top"
                    >
                      <i class="el-icon-chat-dot-round cs-ml-10" />
                    </el-tooltip>

                    <el-tooltip
                      v-if="scope.row.invoice_type > 0"
                      placement="top"
                    >
                      <div slot="content">
                        发票抬头：{{ scope.row.invoice_title }}
                        <template v-if="scope.row.invoice_type === 2"
                          ><br />纳税人号：{{ scope.row.tax_number }}</template
                        >
                      </div>
                      <i class="el-icon-tickets cs-ml-10" />
                    </el-tooltip>
                  </p>
                </div>
              </template>
            </el-table-column>
            <el-table-column
              align="center"
              prop="consignee"
              label="收件人"
              width="100"
            ></el-table-column>

            <el-table-column
              align="center"
              prop="mobile"
              label="手机号"
              width="100"
            ></el-table-column>

            <el-table-column
              align="center"
              prop="user_address"
              label="地址"
              width="200"
            ></el-table-column>

            <el-table-column
              align="center"
              prop="payment_status"
              label="支付状态"
              width="100"
            >
              <template slot-scope="scope">
                {{ paymentStatus[scope.row.payment_status] }}
              </template>
            </el-table-column>

            <el-table-column
              align="center"
              prop="trade_status"
              label="订单状态"
              width="100"
            >
              <template slot-scope="scope">
                {{ tradeStatusMap[scope.row.trade_status] }}
              </template>
            </el-table-column>
            <el-table-column
              align="center"
              prop="payment_time"
              label="支付时间"
              width="200"
            ></el-table-column>
            <el-table-column
              align="center"
              prop="finished_time"
              label="完成时间"
              width="200"
            ></el-table-column>
            <el-table-column
              label="操作"
              align="right"
              width="200"
              fixed="right"
            >
              <template slot-scope="scope">
                <el-button type="text" size="small" @click="lookDetail(scope)"
                  >查看详情</el-button
                >
                <el-button
                  v-if="scope.row.trade_status === 2"
                  type="text"
                  size="small"
                  @click="affirmSeed(scope)"
                  >确认发货</el-button
                >
                <el-button type="text" size="small" @click="cancelOrder(scope)"
                  >取消订单</el-button
                >
              </template>
            </el-table-column>
          </el-table>
          <el-pagination
            @size-change="handleSizeChange"
            @current-change="pageChangeHandler"
            :current-page.sync="parameter.page_no"
            :page-size="parameter.page_size"
            prev-text="上一页"
            next-text="下一页"
            :total="parameter.total_result"
          >
          </el-pagination>
        </div>
      </div>
    </div>
    <el-dialog title="确认发货吗?" width="30%" :visible.sync="seedOrderDialog">
      <div style="margin-bottom: 20px">
        <el-input
          v-model="delivery_company"
          placeholder="请输入物流公司"
        ></el-input>
      </div>
      <div>
        <el-input
          v-model="delivery_code"
          placeholder="请输入快递单号"
        ></el-input>
      </div>
      <span slot="footer" class="dialog-footer">
        <el-button @click="seedOrderDialog = false">取 消</el-button>
        <el-button type="primary" @click="seedOrderConfirm">确 定</el-button>
      </span>
    </el-dialog>
  </div>
</template>
<script>
export default {
  components: {
    Crumbs: () => import("@/components/Crumbs.vue")
  },
  data() {
    return {
      selectType: 1,
      paymentStatus: {
        0: "未付",
        1: "已付"
      },
      tradeStatusMap: {
        0: "已过期",
        1: "待支付",
        2: "待发货",
        3: "待签收货",
        4: "已签收",
        5: "已完结",
        6: "已取消"
      },
      parameter: {
        method: "get.order.list",
        page_no: 1,
        page_size: 50,
        total_result: 0,
        keywords: "",
        goods_name: "",
        account: "",
        status: "",
        is_pay: "",
        add_time: [],
        order_field: "create_time",
        order_type: "desc"
      },
      selectRows: [],
      activeData: {},
      options: {},
      tableData: [],
      loading: false,
      showEditDialog: false,
      seedOrderDialog: false,
      seedOrderOpt: {},
      delivery_company: "",
      delivery_code: ""
    };
  },
  provide() {
    return {
      refresh: this.refresh
    };
  },
  watch: {},
  created() {
    this.getData();
  },
  methods: {
    setSelectRows(val) {
      this.selectRows = val;
    },
    refresh() {
      this.showEditDialog = false;
      this.pageChangeHandler(1);
    },
    pageChangeHandler(val) {
      this.parameter.page_no = val;
      this.getData();
    },
    handleSizeChange(val) {
      this.parameter.page_size = val;
      this.parameter.page_no = 1;
      this.getData();
    },
    lookDetail(scope) {
      this.options = scope.row;
      this.$message({
        message: "功能开发中,敬请期待🙏",
        type: "success"
      });
      this.showEditDialog = true;
    },
    add() {
      this.options = {};
      this.showEditDialog = true;
    },
    getData() {
      this.loading = true;
      this.$api.orderManage
        .orderList(this.parameter)
        .then((res) => {
          this.loading = false;
          console.log(res.data);
          if (res.data.items && Array.isArray(res.data.items)) {
            this.tableData = res.data.items;
          } else {
            this.tableData = [];
          }
          this.parameter.total_result = res.data.total_result;
        })
        .catch((err) => {
          console.log(err);
          this.loading = false;
        });
    },
    affirmSeed(scope) {
      this.seedOrderDialog = true;
      this.seedOrderOpt = scope.row;
    },
    seedOrderConfirm() {
      this.$api.orderManage
        .seedOrder({
          method: "confirm.order.item",
          order_no: this.seedOrderOpt.order_no,
          delivery_company: this.delivery_company,
          delivery_code: this.delivery_code
        })
        .then((res) => {
          this.refresh();
        });
    },
    cancelOrder(scope) {
      this.$confirm("确认取消订单吗?", "提示", {
        callback: (act) => {
          console.log(act);
          if (act === "confirm") {
            this.$api.orderManage
              .cancelOrder({
                method: "cancel.order.item",
                order_no: scope.row.order_no
              })
              .then((res) => {
                this.$message({
                  message: "取消成功",
                  type: "success"
                });
                this.refresh();
              });
          }
        }
      });
    }
  }
};
</script>
<style scoped lang="scss">
@import "@/styles/config.scss";
</style>
