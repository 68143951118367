<template>
  <el-dialog class="editDialog" :append-to-body="true" width="900px" :visible.sync="showEditDialog"
    :close-on-click-modal="false" :destroy-on-close="true" @close="$emit('update:isShow', false)" :show-close="true">
    <div slot="title" class="title">
      {{ options.article_id ? '编辑' : '新增' }}资讯内容
    </div>

    <div class="content">
      <el-form label-width="120px" :model="form" :rules="rules" ref="form">
        <el-form-item label="标题" prop="title" ref="title">
          <el-input v-model="form.title" placeholder="请输入标题"></el-input>
        </el-form-item>
        <el-form-item label="关键词" prop="keywords" ref="keywords">
          <el-input v-model="form.keywords" placeholder="请输入关键词,例：律师，当事人"></el-input>
        </el-form-item>

        <!-- 资讯类型 -->
        <el-form-item label="资讯类型" prop="module" ref="module">
          <el-select class="item" v-model="form.module">
            <el-option label="文章资讯" :value="1"></el-option>
            <el-option label="知识百科" :value="2"></el-option>
            <el-option label="行业分析" :value="3"></el-option>
            <el-option label="行业书籍" :value="4"></el-option>
            <el-option label="员工专属内部资料" :value="5"></el-option>
          </el-select>
        </el-form-item>
        <el-form-item v-if="form.module === 2 && articleList.length" label="知识百科分类" prop="knowledge" ref="knowledge">
          <el-row type="flex" :gutter="15">
            <el-col :span="12">
              <el-select class="item" placeholder="请选择考试内容" multiple v-model="form.article_cat_id"
                @change="articleCatChange">
                <el-option v-for="(item, index) in articleList[1].next_info" :key="index" :label="item.cat_name"
                  :value="item.article_cat_id"></el-option>
              </el-select>
            </el-col>
            <el-col :span="12">
              <el-select class="item" placeholder="请选择知识体系" multiple v-model="form.article_two_cat_id"
                @change="articleTwoCatChange">
                <el-option v-for="(item, index) in articleList[0].next_info" :key="index" :label="item.cat_name"
                  :value="item.article_cat_id"></el-option>
              </el-select>
            </el-col>
          </el-row>
        </el-form-item>
        <!-- 讲师 -->
        <el-form-item label="讲师" prop="tutor_id" ref="tutor_id">
          <ChoiceData :selectedData="[{ organ_id: form.tutor_id }]" :configure="tutorConfigure"
            @choiceDataChange="choiceTutorChange">
            <el-input slot="button" v-model="form.organ_name" placeholder="请选择讲师"></el-input>
            <div class="mainList_operation clearfloat" slot="search" slot-scope="{ params, search }">
              <div class="mainList_operation_search">
                <el-input class="item" placeholder="请输入讲师名称" clearable v-model="params.keywords"></el-input>
                <!-- <el-select class="item" v-model="params.type">
                  <el-option label="请选择类型" value=""></el-option>
                  <el-option label="讲师" value="1"></el-option>
                  <el-option label="机构" value="2"></el-option>
                </el-select> -->
                <el-button type="primary" plain icon="el-icon-search" @click="search(1)">搜索</el-button>
              </div>
            </div>
            <template slot="tableColumn">
              <el-table-column align="center" prop="name" label="讲师"></el-table-column>
              <el-table-column align="center" label="类型">
                <template slot-scope="scope">
                  {{ scope.row.type == 1 ? '讲师' : '机构' }}
                </template>
              </el-table-column>
              <el-table-column align="center" prop="company_name" label="所属企业"></el-table-column>
            </template>
          </ChoiceData>
        </el-form-item>

        <template v-if="form.module === 4">
          <el-form-item label="作者" prop="author" ref="author">
            <el-input v-model="form.author" placeholder="请输入作者"></el-input>
          </el-form-item>
          <el-form-item label="出版社" prop="press" ref="press">
            <el-input v-model="form.press" placeholder="请输入出版社"></el-input>
          </el-form-item>
          <el-form-item label="出版时间" prop="publish_time" ref="publish_time">
            <el-date-picker v-model="form.publish_time" type="month" placeholder="选择出版时间">
            </el-date-picker>
          </el-form-item>
        </template>

        <el-form-item v-if="form.module === 3" label="发布日期" prop="publish_time" ref="publish_time">
          <el-date-picker v-model="form.publish_time" type="date" placeholder="选择发布日期">
          </el-date-picker>
        </el-form-item>

        <el-form-item label="封面图" prop="image" ref="image">
          <UploadImage v-model="form.image" />
        </el-form-item>

        <el-form-item label="简介" prop="description" ref="description">
          <el-input type="textarea" :rows="2" placeholder="请输入简介内容" v-model="form.description">
          </el-input>
        </el-form-item>

        <el-form-item label="内容" prop="content" ref="content">
          <Editor :value.sync="form.content"></Editor>
        </el-form-item>

        <el-form-item label="是否置顶" prop="is_top">
          <el-radio-group v-model="form.is_top" size="medium">
            <el-radio :label="1">是</el-radio>
            <el-radio :label="0">否</el-radio>
          </el-radio-group>
        </el-form-item>

        <el-form-item label="来源" prop="source" ref="source">
          <el-input v-model="form.source" placeholder="请输入来源"></el-input>
        </el-form-item>

        <el-form-item label="来源地址" prop="source_url" ref="source_url">
          <el-input v-model="form.source_url" placeholder="请输入来源地址"></el-input>
        </el-form-item>
        <el-form-item label="附件名称" prop="file_title" ref="file_title">
          <el-input v-model="form.file_title" placeholder="请输入附件名称"></el-input>
        </el-form-item>
        <el-form-item label="附件" prop="url" ref="url">
          <el-upload action="#" name="img" :file-list="fileList" :before-upload="beforeUploadUrl">
            <el-button size="small" type="primary">点击上传</el-button>
          </el-upload>
        </el-form-item>
        <el-form-item label="状态" prop="status" ref="status">
          <el-select v-model="form.status">
            <el-option :key="1" label="启用" :value="1"></el-option>
            <el-option :key="2" label="禁用" :value="2"></el-option>
          </el-select>
        </el-form-item>
      </el-form>
    </div>

    <div slot="footer">
      <el-button type="success" :loading="submitLoading" @click="submit">保存</el-button>
      <el-button type="info" plain @click="$emit('update:isShow', false)">取消</el-button>
    </div>
  </el-dialog>
</template>
<script>
import Axios from '@/axios'
export default {
  components: {
    ChoiceData: () => import('@/components/ChoiceData.vue'),
    Editor: () => import('@/components/editor/index.vue'),
    UploadImage: () => import('@/components/upload/Image.vue'),
  },
  props: {
    isShow: {
      type: Boolean,
      default: false,
    },
    options: {
      type: Object,
      default: function () {
        return {}
      },
    },
  },
  data() {
    var checkKnowledge = (rule, value, callback) => {
      console.log(this.form.article_cat_id.length);
      console.log(this.form.article_two_cat_id.length);
      if (this.form.article_cat_id.length === 0 && this.form.article_two_cat_id.length === 0) {
        return callback(new Error('请选择考试内容或知识百科'));
      }
      callback()
    };
    return {
      tutorConfigure: {
        request: this.$api.organ.organ,
        idKey: 'organ_id',
        params: {
          method: 'get.organ.list',
          keywords: '',
          type: '1',
        },
        title: '请选择讲师',
      },
      showEditDialog: false,
      submitLoading: false,
      form: {
        tutor_id: '',
        organ_name: '',
      },
      articleList: [],
      groupData: [],
      rules: {
        title: [
          {
            required: true,
            message: '请输入标题',
            trigger: ['blur', 'change'],
          },
        ],
        keywords: [
          {
            required: false,
            message: '请输入关键词',
            trigger: ['blur', 'change'],
          },
        ],
        tutor_id: [
          {
            required: true,
            message: '请选择讲师',
            trigger: ['blur', 'change', 'input'],
          },
        ],
        module: [
          {
            required: true,
            message: '请选择资讯类型',
            trigger: ['blur', 'change'],
          },
        ],
        knowledge: [
          {
            required: true,
            validator: checkKnowledge,
            trigger: 'blur',
          },
        ],
        image: [
          {
            required: true,
            message: '请选择封面',
            trigger: ['blur', 'change', 'input'],
          },
        ],
        description: [
          {
            required: true,
            message: '请输入简介',
            trigger: ['blur', 'change'],
          },
        ],
        content: [
          {
            required: true,
            message: '请输入内容',
            trigger: ['blur', 'change', 'input'],
          },
        ],
        author: [
          {
            required: true,
            message: '请输入作者',
            trigger: ['blur', 'change', 'input'],
          },
        ],
        press: [
          {
            required: true,
            message: '请输入出版社',
            trigger: ['blur', 'change', 'input'],
          },
        ],
        publish_time: [
          {
            required: true,
            message: '请输入出版时间',
            trigger: ['blur', 'change', 'input'],
          },
        ],
      },
      fileList: [],
    }
  },
  inject: ['refresh'],
  watch: {
    isShow: {
      immediate: true, //初始化立即执行
      handler: function (newVal) {
        this.$refs.form && this.$refs.form.resetFields()
        this.showEditDialog = newVal
      },
    },
    options: {
      immediate: true, //初始化立即执行
      deep: true, //对象深度监测
      handler: function (newVal, oldVal) {
        const xxx = newVal
        this.init(xxx)
      },
    },
  },
  created() {
    this.$api.articleCat.articleCategory({
      method: 'get.article.cat.tree',
    }).then(res => {
      this.articleList = res.data
    })
  },
  methods: {
    choiceTutorChange(r) {
      console.log('选择讲师', r)
      this.form.organ_name = ''
      this.form.tutor_id = r.organ_id
      this.form = {
        ...this.form,
        organ_name: r.name,
      }
      // this.$nextTick(() => {
      //   this.$refs.form.validateField('tutor_id')
      // })
    },
    init(options) {
      if (options.article_id) {
        //编辑进来的会有id需要

        const {
          user_name,
          article_id,
          article_cat_id,
          article_two_cat_id,
          tutor_id,
          title,
          image,
          keywords,
          description,
          is_top,
          module,
          source,
          source_url,
          status,
          get_article_info,
        } = { ...options }
        const {
          author,
          press,
          publish_time,
          url,
          content,
          title: file_title,
        } = get_article_info
        this.form = {
          author,
          press,
          publish_time,
          article_id,
          article_cat_id,
          article_two_cat_id,
          tutor_id,
          title,
          image,
          keywords,
          description,
          is_top,
          module,
          source,
          source_url,
          status,
        }
        this.form.organ_name = user_name
        this.form.file_title = file_title
        this.form.url = url
        this.form.content = content
        this.form.article_cat_id = this.form.article_cat_id.map(item => {
          return +item
        })
        this.form.article_two_cat_id = this.form.article_two_cat_id.map(item => {
          return +item
        })
        this.fileList = [{ name: file_title, url }]
      } else {
        this.form = { tutor_id: '', organ_name: '' }
      }
    },
    articleCatChange() {
      this.form.article_two_cat_id = []
    },
    articleTwoCatChange() {
      this.form.article_cat_id = []
    },
    submit() {
      this.$refs.form.validate((valid, obj) => {
        if (valid) {
          this.submitLoading = true
          let postData = {
            ...this.form,
            article_cat_id: this.form.article_cat_id && this.form.article_cat_id.length ? this.form.article_cat_id.join(',') : '',
            article_two_cat_id: this.form.article_two_cat_id && this.form.article_two_cat_id.length ? this.form.article_two_cat_id.join(',') : '',
          }
          if (this.form.article_id) {
            postData.method = 'set.article.item'
          } else {
            postData.method = 'add.article.item'
          }
          this.$api.article
            .article(postData)
            .then((res) => {
              this.submitLoading = false
              this.refresh(1)
              this.$message.success('操作成功！')
            })
            .catch((err) => {
              this.submitLoading = false
              console.log(err)
            })
        } else {
          this.scrollView(obj)
        }
      })
    },
    // 上传附件
    beforeUploadUrl(file) {
      var form = new FormData()
      form.append('file', file)
      form.append('method', 'set.upload.figure')
      Axios({
        url: '/adminapi/v1/upload',
        method: 'post',
        data: form,
        headers: {
          accept: '*/*',
        },
        transformRequest: [
          function (data) {
            return data
          },
        ],
      })
        .then((res) => {
          this.form.url = res.data.url
          this.fileList = [{ name: this.form.file_title, url: res.data.url }]
        })
        .catch((err) => { })
    },
  },
}
</script>
