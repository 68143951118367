<template>
  <el-dialog
    class="editDialog"
    :append-to-body="true"
    width="600px"
    :visible.sync="showEditDialog"
    :close-on-click-modal="false"
    :destroy-on-close="true"
    @close="$emit('update:isShow', false)"
    :show-close="true"
  >
    <div slot="title" class="title">设置状态</div>
    <div class="content">
      <el-form label-width="6em" :model="form" :rules="rules" ref="form">

          <el-form-item label="状态" prop="status">
              <el-radio-group v-model="form.status" size="medium">
                  <el-radio :label="1">审核通过</el-radio>
                  <el-radio :label="2">驳回</el-radio>
              </el-radio-group>
          </el-form-item>

          <el-form-item label="备注" prop="remarks" ref="remarks">
              <el-input
                      type="textarea"
                      :rows="2"
                      placeholder="请输入备注"
                      v-model="form.remarks"
              >
              </el-input>
          </el-form-item>
      </el-form>
    </div>

    <div slot="footer">
      <el-button type="success" :loading="submitLoading" @click="submit"
        >保存</el-button
      >
      <el-button type="info" plain @click="$emit('update:isShow', false)"
        >取消</el-button
      >
    </div>
  </el-dialog>
</template>
<script>
import { mobile, email } from "@/regular";

export default {
  props: {
    isShow: {
      type: Boolean,
      default: false,
    },
    options: {
      type: Object,
      default: function () {
        return {};
      },
    },
  },
  data() {
    return {
      showEditDialog: false,
      submitLoading: false,
      form: {},
      groupData: [],
      rules: {
          status: [
          {
            required: true,
            message: "必须选择",
            trigger: ["blur", "change"],
          },
        ],

      },
    };
  },
  inject: ["refresh"],
  watch: {
    isShow: {
      immediate: true, //初始化立即执行
      handler: function (newVal) {
        this.$refs.form && this.$refs.form.resetFields();
        this.showEditDialog = newVal;
      },
    },
    options: {
      immediate: true, //初始化立即执行
      deep: true, //对象深度监测
      handler: function (newVal, oldVal) {
        const xxx=newVal;
        this.init(xxx);
      },
    },
  },
  created() {},
  methods: {
    init(options) {
        //编辑进来的会有id需要
      this.form = options;
    },
    submit() {
      this.$refs.form.validate((valid, obj) => {
        if (valid) {
          this.submitLoading = true;
          if(this.form.status==2){
              if(!this.form.remarks){
                  this.$message.warning("必须填写备注！");
                  return ;
              }
          }
          this.$api.ask.ask(
              this.form.combination({
                module: "admin",
                method:  "set.ask.status" ,
              })
            )
            .then((res) => {
              this.submitLoading = false;
              this.refresh(1);
              this.$message.success("操作成功！");
            })
            .catch((err) => {
              this.submitLoading = false;
              console.log(err);
            });
        } else {
          this.scrollView(obj);
        }
      });
    },
  },
};
</script>
