<template>
    <el-dialog class="editDialog" :append-to-body="true" width="600px" :visible.sync="showEditDialog"
        :close-on-click-modal="false" :destroy-on-close="true" @close="$emit('update:isShow', false)"
        :show-close="true">

        <div slot="title" class="title">审核笔记</div>

        <div class="content">
            <ReplyCard :reply-data="options"></ReplyCard>
            <el-divider></el-divider>
            <el-form label-width="7em" :model="form" :rules="rules" ref="form">

                <el-form-item :label="'审核状态'" prop="status">
                    <el-input disabled value="拒绝"></el-input>
                </el-form-item>

                <el-form-item label="拒绝原因" prop="remarks" ref="remarks">
                    <el-input type="textarea" v-model="form.remarks" placeholder="请输入拒绝原因"></el-input>
                </el-form-item>

            </el-form>
        </div>

        <div slot="footer">
            <el-button type="success" :loading="submitLoading" @click="submit">保存</el-button>
            <el-button type="info" plain @click="$emit('update:isShow', false)">取消</el-button>
        </div>

    </el-dialog>

</template>
<script>

export default {
    components: {
        ReplyCard: () => import("@/components/ReplyCard.vue"),
    },
    props: {
        isShow: {
            type: Boolean,
            default: false
        },
        options: {
            type: Object,
            default: function () {
                return {}
            }
        },
    },
    data() {
        return {
            showEditDialog: false,
            submitLoading: false,
            form: {},
            rules: {
                remarks: [{ required: true, message: '请输入拒绝原因', trigger: ['blur', 'change'] }],
            },
        };
    },
    inject: ['refresh'],
    watch: {
        isShow: {
            immediate: true,//初始化立即执行
            handler: function (newVal) {
                this.$nextTick(() => {
                    newVal && this.$refs.form && this.$refs.form.clearValidate();
                });
                this.showEditDialog = newVal;
            }
        },
        options: {
            immediate: true,//初始化立即执行
            deep: true,//对象深度监测
            handler: function (newVal, oldVal) {
                this.init(newVal);
            }
        }
    },
    created() { },
    methods: {

        init(options) {
            this.form = {
                method: "set.course_note.status",
                course_note_id: [options.course_note_id],
                status: 2,
                remarks: options.remarks
            }
        },
        submit() {
            this.$refs.form.validate((valid, obj) => {
                if (valid) {
                    this.submitLoading = true;
                    this.$api.course.course_note(this.form).then(res => {
                        this.submitLoading = false;
                        this.refresh(1);
                        this.$message.success("操作成功！");

                    }).catch(err => {
                        this.submitLoading = false;
                        console.log(err);
                    })
                } else {
                    this.scrollView(obj)
                };
            });
        },
    },
};
</script>
