<template>
  <el-dialog class="editDialog" :append-to-body="true" width="600px" :visible.sync="showEditDialog"
    :close-on-click-modal="false" :destroy-on-close="true" @close="$emit('update:isShow', false)" :show-close="true">
    <div slot="title" class="title">
      {{ options.delivery_id ? "编辑" : "新增" }}快递公司
    </div>

    <div class="content">
      <el-form label-width="6em" :model="form" :rules="rules" ref="form">

        <el-form-item label="名称" prop="name" ref="name">
          <el-input v-model="form.name" placeholder="请输入名称"></el-input>
        </el-form-item>
        <el-form-item label="编码" prop="code" ref="code">
          <el-input v-model="form.code" placeholder="请输入编码"></el-input>
        </el-form-item>

      </el-form>
    </div>

    <div slot="footer">
      <el-button type="success" :loading="submitLoading" @click="submit">保存</el-button>
      <el-button type="info" plain @click="$emit('update:isShow', false)">取消</el-button>
    </div>
  </el-dialog>
</template>
<script>
import { mobile, email } from "@/regular";

export default {
  props: {
    isShow: {
      type: Boolean,
      default: false,
    },
    options: {
      type: Object,
      default: function () {
        return {};
      },
    },
  },
  data() {
    return {
      showEditDialog: false,
      submitLoading: false,
      form: {},
      groupData: [],
      positionOptions: [],
      rules: {
        name: [
          {
            required: true,
            message: "请输入名称",
            trigger: ["blur", "change"],
          },
        ],

      },
    };
  },
  inject: ["refresh"],
  watch: {
    isShow: {
      immediate: true, //初始化立即执行
      handler: function (newVal) {
        this.$refs.form && this.$refs.form.resetFields();
        this.showEditDialog = newVal;
      },
    },
    options: {
      immediate: true, //初始化立即执行
      deep: true, //对象深度监测
      handler: function (newVal, oldVal) {
        const xxx = newVal;
        this.init(xxx);
      },
    },
  },
  created() { },
  methods: {

    init(options) {

      if (options.delivery_id) {
        //编辑进来的会有id需要
        this.form = options;

      } else {
        this.form = {}
      }
    },
    submit() {
      this.$refs.form.validate((valid, obj) => {
        if (valid) {
          this.submitLoading = true;
          this.$api.delivery.delivery(
            this.form.combination({
              module: "admin",
              method: this.form.delivery_id ? "set.delivery.item" : "add.delivery.item",
            })
          )
            .then((res) => {
              this.submitLoading = false;
              this.refresh(1);
              this.$message.success("操作成功！");
            })
            .catch((err) => {
              this.submitLoading = false;
              console.log(err);
            });
        } else {
          this.scrollView(obj);
        }
      });
    },
  },
};
</script>
