<template>
  <el-dialog class="editDialog" :append-to-body="true" width="700px" :visible.sync="showEditDialog" :close-on-click-modal="false" :destroy-on-close="true" @close="$emit('update:isShow', false)" :show-close="true">
    <div slot="title" class="title">
      {{ options.coupon_id ? "编辑" : "新增" }}优惠券
    </div>

    <div class="content">
      <el-form label-width="120px" :model="form" :rules="rules" ref="form">
        <el-form-item label="优惠券名称" prop="name" ref="name">
          <el-input v-model="form.name" placeholder="请输入名称"></el-input>
        </el-form-item>
        <el-form-item label="优惠券类型" prop="type" ref="type">
          <el-select v-model="form.type" @change="typeChange">
            <el-option label="新用户优惠券" :value="1"></el-option>
            <el-option label="专属优惠券" :value="2"></el-option>
            <el-option label="邀新奖励优惠券" :value="3"></el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="优惠券专属类型" v-if="form.type===2" prop="module" ref="module">
          <el-select v-model="form.module">
            <el-option label="会员卡优惠券" :value="1"></el-option>
            <el-option label="课程优惠券" :value="2"></el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="优惠金额" prop="money" ref="money">
          <el-input-number v-model="form.money" controls-position="right" :min="1"></el-input-number>
        </el-form-item>
        <el-form-item label="限制使用金额" prop="quota" ref="quota">
          <el-input-number v-model="form.quota" controls-position="right" :min="1"></el-input-number>
        </el-form-item>
        <el-form-item label="限制领取次数" prop="frequency" ref="frequency">
          <el-input-number v-model="form.frequency" controls-position="right" :min="1"></el-input-number>
        </el-form-item>
        <el-form-item label="有效期" prop="validity" ref="validity">
          <el-input-number v-model="form.validity" controls-position="right" :min="1"></el-input-number>
        </el-form-item>
        <el-form-item label="描述" prop="description" ref="description">
          <el-input type="textarea" :rows="2" placeholder="请输入描述" v-model="form.description">
          </el-input>
        </el-form-item>
        <el-form-item label="优惠券背景图" prop="image">
          <UploadImage v-model="form.image" />
        </el-form-item>
        <el-form-item label="状态" prop="is_delete" ref="is_delete">
          <el-select v-model="form.is_delete">
            <el-option label="开启" :value="0"></el-option>
            <el-option label="禁用" :value="1"></el-option>
          </el-select>
        </el-form-item>
      </el-form>
    </div>
    <div slot="footer">
      <el-button type="success" :loading="submitLoading" @click="submit">保存</el-button>
      <el-button type="info" plain @click="$emit('update:isShow', false)">取消</el-button>
    </div>
  </el-dialog>
</template>
<script>
export default {
  props: {
    isShow: {
      type: Boolean,
      default: false,
    },
    options: {
      type: Object,
      default: function () {
        return {}
      },
    },
  },
  components: {
    UploadImage: () => import('@/components/upload/Image.vue'),
  },
  data() {
    return {
      showEditDialog: false,
      submitLoading: false,
      form: {},
      rules: {
        name: [
          {
            required: true,
            message: '请输入名称',
            trigger: ['blur', 'change'],
          },
        ],
        description: [
          {
            required: true,
            message: '请输入描述',
            trigger: ['blur', 'change'],
          },
        ],
        type: [
          {
            required: true,
            message: '请选择红包类型',
            trigger: ['blur', 'change'],
          },
        ],
        money: [
          {
            required: true,
            message: '请输入优惠金额',
            trigger: ['blur', 'change'],
          },
        ],
        quota: [
          {
            required: true,
            message: '请输入可使用的最低金额',
            trigger: ['blur', 'change'],
          },
        ],
        frequency: [
          {
            required: true,
            message: '请输入最多可领取次数',
            trigger: ['blur', 'change'],
          },
        ],
        validity: [
          {
            required: true,
            message: '请输入优惠券有效期',
            trigger: ['blur', 'change'],
          },
        ],
      },
    }
  },
  inject: ['refresh'],
  watch: {
    isShow: {
      immediate: true, //初始化立即执行
      handler: function (newVal) {
        this.$refs.form && this.$refs.form.resetFields()
        this.showEditDialog = newVal
      },
    },
    options: {
      immediate: true, //初始化立即执行
      deep: true, //对象深度监测
      handler: function (newVal, oldVal) {
        const xxxx = newVal
        this.init(xxxx)
      },
    },
  },
  methods: {
    init(options) {
      if (options.coupon_id) {
        //编辑进来的会有id需要 (不能直接从列表获取值，好像列表的值不全)
        this.form = options
      } else {
        this.form = {
          is_delete: 0,
          module: 0,
          type: 1,
        }
      }
    },
    typeChange() {
      if (this.form.type !== 2) {
        this.form.module = 0
      } else {
        this.form.module = 1
      }
    },
    submit() {
      this.$refs.form.validate((valid, obj) => {
        if (valid) {
          this.submitLoading = true
          this.form.method = this.form.coupon_id
            ? 'set.coupon.item'
            : 'add.coupon.item'
          this.$api.coupon
            .coupon(this.form)
            .then((res) => {
              this.submitLoading = false
              this.refresh(1)
              this.$message.success('操作成功！')
            })
            .catch((err) => {
              this.submitLoading = false
              console.log(err)
            })
        } else {
          this.scrollView(obj)
        }
      })
    },
  },
}
</script>
