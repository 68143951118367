//身份证
export const idCard = /(^\d{15}$)|(^\d{18}$)|(^\d{17}(\d|X|x)$)/

//手机号码
export const mobile = /^[1][3,4,5,7,8,9][0-9]{9}$/

//简单电话号码的验证(手机，固定电话)
export const phone = /^(\(\d{3,4}\)|\d{3,4}-|\s)?\d{7,14}$/

//正整数（包括0）
export const integer = /^([1-9]\d*|[0]{1,1})$/

//邮箱
// export const email = /^([a-zA-Z0-9_-])+@([a-zA-Z0-9_-])+(.[a-zA-Z0-9_-])+/
export const email =
  /^\w+((-\w+)|(\.\w+))*\@[A-Za-z0-9]+((\.|-)[A-Za-z0-9]+)*\.[A-Za-z0-9]+$/

//字母和数字
export const cardNumber = /^[0-9a-zA-Z]*$/

//正数
export const positiveNumber = /^\d+(\.\d+)?$/

//最多保留2位小数点包含0
export const positiveInteger =
  /^(([0-9][0-9]*)|(([0]\.\d{1,2}|[1-9][0-9]*\.\d{1,2})|([0]\.\d{1,2}|[0-9][0-9]*\.)))$/

//最多保留2位小数点不包含
export const positiveInteger2 =
  /^(([1-9][0-9]*)|(([0]\.\d{1,2}|[1-9][0-9]*\.\d{1,2})))$/
