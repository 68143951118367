<template>
  <el-dialog class="editDialog" :append-to-body="true" width="600px" :visible.sync="showEditDialog"
    :close-on-click-modal="false" :destroy-on-close="true" @close="$emit('update:isShow', false)" :show-close="true">
    <div slot="title" class="title">
      {{ options.article_cat_id ? "编辑" : "新增" }}资讯分类
    </div>

    <div class="content">
      <el-form label-width="6em" :model="form" :rules="rules" ref="form">
        <el-form-item label="父级" v-if="form.parent_id !== 0" prop="parent_id" ref="parent_id">
          <el-select v-model="form.parent_id" placeholder="请选择父级分类">
            <el-option v-for="(item, index) in list" :key="index" :label="item.cat_name" :value="item.article_cat_id">
            </el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="名称" prop="cat_name" ref="cat_name">
          <el-input v-model="form.cat_name" placeholder="请输入名称"></el-input>
        </el-form-item>

        <el-form-item label="排序" prop="sort" ref="sort">
          <el-input-number v-model="form.sort" controls-position="right" :min="1"></el-input-number>
        </el-form-item>

        <el-form-item label="状态" prop="status" ref="status">
          <el-select v-model="form.status">
            <el-option :key="1" label="开启" :value="1"></el-option>
            <el-option :key="2" label="禁用" :value="2"></el-option>
          </el-select>
        </el-form-item>

        <el-form-item label="描述" prop="description" ref="description">
          <el-input type="textarea" :rows="2" placeholder="请输入描述内容" v-model="form.description">
          </el-input>
        </el-form-item>
      </el-form>
    </div>

    <div slot="footer">
      <el-button type="success" :loading="submitLoading" @click="submit">保存</el-button>
      <el-button type="info" plain @click="$emit('update:isShow', false)">取消</el-button>
    </div>
  </el-dialog>
</template>
<script>
import { mobile, email } from "@/regular";

export default {
  props: {
    isShow: {
      type: Boolean,
      default: false,
    },
    list: {
      type: Array,
      default: () => {
        return []
      },
    },
    options: {
      type: Object,
      default: () => {
        return {}
      },
    },
  },
  data() {
    return {
      showEditDialog: false,
      submitLoading: false,
      form: {},
      groupData: [],
      rules: {
        cat_name: [
          {
            required: true,
            message: "请输入名称",
            trigger: ["blur", "change"],
          },
        ],

      },
    };
  },
  inject: ["refresh"],
  watch: {
    isShow: {
      immediate: true, //初始化立即执行
      handler: function (newVal) {
        this.$refs.form && this.$refs.form.resetFields();
        this.showEditDialog = newVal;
      },
    },
    options: {
      immediate: true, //初始化立即执行
      deep: true, //对象深度监测
      handler: function (newVal, oldVal) {
        const xxx = newVal;
        this.init(xxx);
      },
    },
  },
  created() { },
  methods: {
    init(options) {
      console.log(options)
      if (options.article_cat_id) {
        //编辑进来的会有id需要
        this.form = options;
        console.log(this.form)
      } else {
        this.form = { parent_id: '', status: 1 }
      }
    },
    submit() {
      this.$refs.form.validate((valid, obj) => {
        if (valid) {
          this.submitLoading = true;
          this.$api.articleCat
            .articleCategory(
              this.form.combination({
                module: "admin",
                method: this.form.article_cat_id ? "set.article.cat.item" : "add.article.cat.item",
              })
            )
            .then((res) => {
              this.submitLoading = false;
              this.refresh(1);
              this.$message.success("操作成功！");
            })
            .catch((err) => {
              this.submitLoading = false;
              console.log(err);
            });
        } else {
          this.scrollView(obj);
        }
      });
    },
  },
};
</script>
