<template>
  <div class="choiceData">
    <el-row type="flex" justify="space-between">
      <el-col :span="showClear?20:24">
        <label @click="dlgVisible = true" class="choiceDataBtn">
          <slot name="button"></slot>
        </label>
      </el-col>
      <el-col :span="2" v-if="showClear">
        <i @click="clear" class="el-icon-circle-close"></i>
      </el-col>
    </el-row>
    <el-dialog class="choiceDataCon" :title="parameter.title" :visible.sync="dlgVisible" :append-to-body='true' width="60%">
      <div class="el-transfer-panel" v-if="parameter.type!='radio'">
        <p class="el-transfer-panel__header">
          <label class="el-checkbox">
            <span class="el-checkbox__label">
              已选
              <span>{{ choiceList.length }}</span>
            </span>
          </label>
        </p>

        <div class="el-transfer-panel__body">
          <div class="el-checkbox-group el-transfer-panel__list">
            <div v-for="(n,i) in choiceList" class="el-checkbox el-transfer-panel__item" :key="i">
              <slot name="choiceItem" :data="n">{{ n.id }}</slot>
              <i class="move el-icon-close" @click="remove(i)"></i>
              <template v-if="parameter.type=='checkboxMove'">
                <i v-if="i>0" class="move el-icon-top" @click="move(i,1)"></i>
                <i v-if="i<(choiceList.length - 1)" class="move el-icon-bottom" @click="move(i,-1)"></i>
              </template>
            </div>
          </div>
        </div>
      </div>

      <div class="selected-list">
        <div class="mainList">
          <slot name="search" :params="parameter.params" :search="pageChangeHandler">
          </slot>
          <div class="mainList_content">
            <div class="mainList_content_sub">
              <el-table ref="multipleTable" :border="true" :data="selectedList" tooltip-effect="dark" height="string" @row-click="rowClick" v-loading="selectedListLoad">
                <el-table-column label="操作" width="55" align="center">
                  <template slot-scope="scope">
                    <el-button v-if="parameter.type=='radio'" type="text" size="small" @click="$emit('choiceDataChange',scope.row);dlgVisible = false;">选择</el-button>
                    <el-checkbox v-else v-model="scope.row.shuttleBoxChecked"></el-checkbox>
                  </template>
                </el-table-column>
                <slot name="tableColumn"></slot>
              </el-table>
              <el-pagination @current-change="pageChangeHandler" @size-change="handleSizeChange" :current-page="filter.page_no" :page-size="filter.page_size" prev-text="上一页" next-text="下一页" :total="filter.total_result">
              </el-pagination>
            </div>
          </div>
        </div>
      </div>
      <div v-if="parameter.type!='radio'" slot="footer" class="dialog-footer">
        <el-button type="success" @click="save">确定</el-button>
        <el-button type="info" plain @click="dlgVisible = false;">取消</el-button>
      </div>
    </el-dialog>
  </div>
</template>

<script>
export default {
  name: 'ChoiceData',
  components: {},
  props: {
    showClear: {
      type: Boolean,
      default: () => {
        return false
      },
    },
    selectedData: {
      type: Array,
      default: function () {
        return []
      },
    },
    configure: {
      type: Object,
      default: function () {
        return {}
      },
    },
  },
  data() {
    return {
      parameter: {
        title: '请选择',
        request: null, //请求api例如  this.$api.department.findPage
        type: 'radio', // radios 单选  checkbox 多选   checkboxMove 多选可移动
        idKey: 'id',
        params: {
          keywords: '',
        },
      }.combination(this.configure),
      dlgVisible: false,
      selectedListLoad: false,
      selectedList: [],
      choiceList: this.selectedData.deepClone(),
      filter: {
        page_no: 1,
        total_result: 1,
        page_size: 50,
      },
    }
  },
  watch: {
    configure: {
      immediate: true,
      deep: true,
      handler: function (newVal) {
        // delete this.parameter.params
        // this.parameter = this.parameter.combination(newVal)
        this.parameter = Object.assign(this.parameter, newVal)
      },
    },
    selectedData(newVal) {
      var idKey = this.parameter.idKey
      this.choiceList = newVal.deepClone()
      for (let i = 0; i < this.selectedList.length; i++) {
        let obj = this.selectedList[i]
        if (
          this.choiceList.some(function (s) {
            return s[idKey] == obj[idKey]
          })
        ) {
          obj.shuttleBoxChecked = true
        } else {
          obj.shuttleBoxChecked = false
        }
      }
    },
  },
  mounted() {
    this.getSelectedList()
  },
  methods: {
    rowClick(row, column, event) {
      if (this.parameter.type == 'radio') {
        this.$emit('choiceDataChange', row)
        this.dlgVisible = false
      } else {
        row.shuttleBoxChecked = !row.shuttleBoxChecked
        this.checkboxChange(row)
      }
    },
    remove(i) {
      var idKey = this.parameter.idKey
      var obj = this.choiceList[i].deepClone()
      this.choiceList.splice(i, 1)
      for (let i = 0; i < this.selectedList.length; i++) {
        if (this.selectedList[i][idKey] == obj[idKey]) {
          this.selectedList[i].shuttleBoxChecked = false
          this.$set(this.selectedList, i, this.selectedList[i])
          return ''
        }
      }
    },
    move(i, j) {
      var obj = this.choiceList[i].deepClone()
      this.choiceList.splice(i, 1)
      this.choiceList.splice(i - j, 0, obj)
    },

    pageChangeHandler(val) {
      this.filter.page_no = val
      this.getSelectedList()
    },
    handleSizeChange(val) {
      this.filter.page_size = val
      this.filter.page_no = 1
      this.getSelectedList()
    },
    getSelectedList() {
      if (this.parameter.request) {
      } else {
        this.$message.error('request参数不能为空！')
      }

      this.selectedListLoad = true
      this.parameter
        .request(this.parameter.params.combination(this.filter))
        .then((res) => {
          var idKey = this.parameter.idKey
          if (res.data.items) {
            res.data.items.forEach((item) => {
              item.shuttleBoxChecked = false
            })
            for (let i = 0; i < res.data.items.length; i++) {
              let obj = res.data.items[i]
              if (
                this.choiceList.some(function (s) {
                  return s[idKey] == obj[idKey]
                })
              ) {
                obj.shuttleBoxChecked = true
              } else {
                obj.shuttleBoxChecked = false
              }
            }
            this.selectedList = res.data.items.deepClone()
          } else {
            this.selectedList = []
          }
          this.filter.total_result = res.data.total_result
          this.selectedListLoad = false
        })
        .catch((err) => {
          console.log(err)
          this.selectedListLoad = false
        })
    },
    checkboxChange(row) {
      if (row.shuttleBoxChecked) {
        this.choiceList.push(row)
      } else {
        var idKey = this.parameter.idKey
        for (let i = 0; i < this.choiceList.length; i++) {
          if (this.choiceList[i][idKey] == row[idKey]) {
            this.choiceList.splice(i, 1)
            return ''
          }
        }
      }
    },
    save() {
      this.$emit('choiceDataChange', this.choiceList.deepClone())
      this.dlgVisible = false
    },
    clear() {
      this.$emit('clear')
    },
  },
}
</script>
<style scoped lang='scss'>
</style>
