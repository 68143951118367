<template>
  <div>
    <Crumbs></Crumbs>
    <div class="mainList">
      <div class="mainList_operation clearfloat">
        <div class="mainList_operation_search">
          <el-input
            class="item"
            placeholder="请输入昵称"
            clearable
            v-model="parameter.nickname"
          ></el-input>
          <el-input
            class="item"
            placeholder="请输入手机号"
            clearable
            v-model="parameter.mobile"
          ></el-input>
          <el-date-picker
            clearable
            style="margin-right: 15px"
            v-model="parameter.add_time"
            type="datetimerange"
            range-separator="至"
            start-placeholder="开始日期"
            end-placeholder="结束日期"
          >
          </el-date-picker>
          <el-button
            type="primary"
            plain
            icon="el-icon-search"
            @click="pageChangeHandler(1)"
            >搜索</el-button
          >
          <el-button
            type="primary"
            plain
            icon="el-icon-download"
            @click="handleExport"
            >导出</el-button
          >
          <el-button
            type="primary"
            plain
            icon="el-icon-upload2"
            @click="dialog = true"
            >导入</el-button
          >
        </div>
        <div class="fr">
          <el-button type="primary" icon="el-icon-plus" @click="add"
            >新增</el-button
          >
        </div>
      </div>
      <div class="mainList_content">
        <ReceivingAddress :options="activeData">
          receivingAddress
        </ReceivingAddress>
        <div class="mainList_content_sub">
          <el-table
            ref="multipleTable"
            border
            :data="tableData"
            tooltip-effect="dark"
            height="string"
            v-loading="loading"
            @selection-change="handleSelectionChange"
          >
            <el-table-column type="selection" width="55"> </el-table-column>
            <el-table-column
              label="序号"
              type="index"
              align="center"
              width="70"
            ></el-table-column>
            <el-table-column
              align="center"
              prop="user_id"
              label="用户ID"
            ></el-table-column>
            <el-table-column
              align="center"
              prop="nickname"
              label="昵称"
            ></el-table-column>
            <el-table-column
              align="center"
              prop="mobile"
              label="手机号"
            ></el-table-column>
            <el-table-column align="center" prop="user_level" label="身份类型">
              <template slot-scope="scope">
                <div>{{ scope.row.get_user_level.name }}</div>
                <div v-if="scope.row.get_user_level.user_level_id !== 1">
                  会员有效期：{{ scope.row.level_start_time }}
                  至
                  {{ scope.row.level_end_time }}
                </div>
              </template>
            </el-table-column>
            <el-table-column align="center" prop="points" label="剩余积分">
              <template slot-scope="scope">
                <div>{{ scope.row?.user_money?.points || 0 }}</div>
              </template>
            </el-table-column>
            <el-table-column
              align="center"
              prop="email"
              label="邮箱"
            ></el-table-column>
            <el-table-column
              prop="get_user_attr.company_name"
              align="center"
              label="公司名"
            ></el-table-column>
            <el-table-column
              prop="get_user_attr.cultural_title"
              align="center"
              label="职位"
            ></el-table-column>
            <el-table-column
              prop="create_time"
              align="center"
              label="创建日期"
            ></el-table-column>
            <!-- <el-table-column align="center" prop="sex" label="性别">
              <template slot-scope="scope">
                {{  scope.row.sex | sex  }}
              </template>
            </el-table-column> -->
            <el-table-column
              label="操作"
              align="center"
              width="160"
              fixed="right"
            >
              <template slot-scope="scope">
                <el-button
                  type="text"
                  size="small"
                  @click="activeData = scope.row"
                  >收货地址</el-button
                >
                <el-button type="text" size="small" @click="edit(scope)"
                  >编辑</el-button
                >
                <el-button
                  type="text"
                  size="small"
                  class="danger"
                  @click="del(scope)"
                  >删除</el-button
                >
              </template>
            </el-table-column>
          </el-table>
          <el-pagination
            @size-change="handleSizeChange"
            @current-change="pageChangeHandler"
            :current-page.sync="parameter.page_no"
            :page-size="parameter.page_size"
            prev-text="上一页"
            next-text="下一页"
            :total="parameter.total_result"
          >
          </el-pagination>
        </div>
      </div>
    </div>
    <Edit :isShow.sync="showEditDialog" :options="options"></Edit>
    <!-- 导入弹框 -->
    <el-dialog
      :visible.sync="dialog"
      v-if="dialog"
      title="导入"
      width="21%"
      :close-on-click-modal="false"
      append-to-body
      destroy-on-close
    >
      <el-progress
        v-if="loading"
        :text-inside="true"
        :stroke-width="20"
        :percentage="percentage"
        style="margin-bottom: 15px"
      />
      <div v-loading="loading">
        <el-upload
          ref="uploader"
          drag
          action="#"
          :accept="accept"
          :maxSize="maxSize"
          :limit="1"
          :data="formData"
          :show-file-list="false"
          :before-upload="importBefore"
          :on-progress="importProgress"
          :on-success="importSuccess"
          :on-error="importError"
        >
          <slot name="uploader">
            <i class="el-icon-upload"></i>
            <div class="el-upload__text">
              将文件拖到此处或 <em>点击选择文件上传</em>
            </div>
          </slot>
          <template #tip>
            <div class="el-upload__tip">
              <template
                >请上传小于或等于 {{ maxSize }}M 的
                {{ accept }} 格式文件</template
              >
              <p v-if="templateUrl" style="margin-top: 7px">
                <el-link
                  :href="templateUrl"
                  target="_blank"
                  type="primary"
                  :underline="false"
                  >下载导入模板</el-link
                >
              </p>
            </div>
          </template>
        </el-upload>
        <el-form
          v-if="$slots.form"
          inline
          label-width="100px"
          label-position="left"
          style="margin-top: 18px"
        >
          <slot name="form" :formData="formData"></slot>
        </el-form>
      </div>
    </el-dialog>
  </div>
</template>
<script>
import store from "@/store/index.js";
import Axios from "@/axios";
export default {
  components: {
    ReceivingAddress: () => import("./ReceivingAddress.vue"),
    Edit: () => import("./Edit.vue"),
    Crumbs: () => import("@/components/Crumbs.vue")
  },
  data() {
    return {
      dialog: false,
      loading: false,
      percentage: 0,
      formData: {},
      accept: ".xls, .xlsx",
      maxSize: 10,
      templateUrl: "/modelTemp.xlsx",
      parameter: {
        method: "get.user.list",
        page_no: 1,
        page_size: 50,
        total_result: 0
      },
      activeData: {},
      options: {},
      tableData: [],
      loading: false,
      showEditDialog: false,
      multipleSelection: []
    };
  },
  provide() {
    return {
      refresh: this.refresh
    };
  },
  watch: {},
  created() {
    this.getData();
  },
  methods: {
    // 导入数据
    importBefore(file) {
      const maxSize = file.size / 1024 / 1024 < this.maxSize;
      if (!maxSize) {
        this.$message.warning(`上传文件大小不能超过 ${this.maxSize}MB!`);
        return false;
      }
      this.loading = true;
      this.importRequest(file);
    },
    importProgress(e) {
      this.percentage = e.percent;
    },
    importSuccess(res, file) {
      this.$refs.uploader.handleRemove(file);
      this.$refs.uploader.clearFiles();
      this.loading = false;
      this.percentage = 0;
    },
    importError(err) {
      this.loading = false;
      this.percentage = 0;
      this.$notify.error({
        title: "上传文件未成功",
        message: err
      });
    },
    importRequest(param) {
      var data = new FormData();
      data.append("file", param);
      data.append("method", "get.user.import");
      Axios({
        url: "/adminapi/v1/user",
        method: "post",
        data: data,
        headers: {
          accept: "*/*"
        },
        transformRequest: [
          function (data) {
            return data;
          }
        ]
      })
        .then((res) => {
          let data = res.data;
          let content = "导入" + data.total + "条,失败" + data.fail + "条";
          this.$alert(content, "导入完成", {
            confirmButtonText: "确定"
          });
        })
        .catch((err) => {
          this.$alert(err.message, "导入失败", {
            confirmButtonText: "确定"
          });
        });
    },
    handleSelectionChange(val) {
      this.multipleSelection = val;
    },
    refresh() {
      this.showEditDialog = false;
      this.pageChangeHandler(1);
    },
    pageChangeHandler(val) {
      this.parameter.page_no = val;
      this.getData();
    },
    handleSizeChange(val) {
      this.parameter.page_size = val;
      this.parameter.page_no = 1;
      this.getData();
    },
    edit(scope) {
      this.options = scope.row;
      this.showEditDialog = true;
    },
    add() {
      this.options = {};
      this.showEditDialog = true;
    },
    getData() {
      this.loading = true;
      this.$api.user
        .user(this.parameter)
        .then((res) => {
          this.loading = false;
          this.tableData = res.data.items;
          this.parameter.total_result = res.data.total_result;
        })
        .catch((err) => {
          console.log(err);
          this.loading = false;
        });
    },
    del(scope) {
      this.$confirm("此操作将永久删除该数据, 是否继续?", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning"
      })
        .then(() => {
          this.$api.user
            .user({
              module: "admin",
              method: "del.user.list",
              "client_id[]": scope.row.user_id
            })
            .then((res) => {
              this.$message.success("删除成功！");

              var hasLastPage =
                this.parameter.page_size * this.parameter.page_no >=
                this.parameter.total_result;
              var lastData = this.tableData.length < 2;
              if (lastData && hasLastPage && this.parameter.page_no > 1) {
                this.parameter.page_no--;
              }
              this.getData();
            })
            .catch((err) => {
              console.log(err);
            });
        })
        .catch(() => {
          //用户取消
        });
    },
    removeEmptyField(obj) {
      var newObj = {};
      if (typeof obj === "string") {
        obj = JSON.parse(obj);
      }
      if (obj instanceof Array) {
        newObj = [];
      }
      if (obj instanceof Object) {
        for (var attr in obj) {
          if (
            Object.prototype.hasOwnProperty.call(obj, attr) &&
            obj[attr] !== "" &&
            obj[attr] !== null &&
            obj[attr] !== undefined
          ) {
            if (obj[attr] instanceof Object) {
              newObj[attr] = this.removeEmptyField(obj[attr]);
            } else if (
              typeof obj[attr] === "string" &&
              ((obj[attr].indexOf("{") > -1 && obj[attr].indexOf("}") > -1) ||
                (obj[attr].indexOf("[") > -1 && obj[attr].indexOf("]") > -1))
            ) {
              try {
                var attrObj = JSON.parse(obj[attr]);
                if (attrObj instanceof Object) {
                  newObj[attr] = this.removeEmptyField(attrObj);
                }
              } catch (e) {
                newObj[attr] = obj[attr];
              }
            } else {
              newObj[attr] = obj[attr];
            }
          }
        }
      }
      return newObj;
    },
    handleExport() {
      let exportData = {
        nickname: this.parameter.nickname,
        mobile: this.parameter.mobile,
        add_time: this.parameter.add_time
      };
      exportData.method = "export.user.list";
      exportData.is_export = 1;
      if (this.multipleSelection.length > 0) {
        exportData.user_ids = [];
        this.multipleSelection.forEach((item) => {
          exportData.user_ids.push(item.user_id);
        });
      }
      exportData = this.removeEmptyField(exportData);
      console.log(exportData);
      let url = "/adminapi/v1/user?";
      Object.keys(exportData).forEach((item) => {
        url += item + "=" + exportData[item] + "&";
      });
      console.log(url);
      // url += "method=export.user.list";
      window.open(url);
      // this.exportLoading = false;
      // dowloneOrderList(exportData)
      //   .then(() => {
      //     this.exportLoading = false;
      //   })
      //   .catch(() => {
      //     this.exportLoading = false;
      //   });
    }
  }
};
</script>
<style scoped lang="scss">
@import "@/styles/config.scss";
</style>
