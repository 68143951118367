<template>
  <div>
    <Crumbs></Crumbs>
    <div class="setUp">
      <el-tabs v-model="activeName">
        <el-tab-pane label="物流设置">
          <Delivery v-if="activeName==0" />
        </el-tab-pane>
        <el-tab-pane label="系统设置">
          <SystemInfo v-if="activeName==1" />
        </el-tab-pane>
        <el-tab-pane label="购物系统">
          <Shopping v-if="activeName==2" />
        </el-tab-pane>
        <el-tab-pane label="上传配置">
          <Upload v-if="activeName==3" />
        </el-tab-pane>
        <el-tab-pane label="证书信息">
          <Certificate v-if="activeName==4" />
        </el-tab-pane>
      </el-tabs>
    </div>
  </div>
</template>
<script>
export default {
  components: {
    Crumbs: () => import('@/components/Crumbs.vue'),
    Delivery: () => import('./Delivery.vue'),
    SystemInfo: () => import('./SystemInfo.vue'),
    Shopping: () => import('./Shopping.vue'),
    Upload: () => import('./Upload.vue'),
    Certificate: () => import('./Certificate.vue'),
  },
  data() {
    return {
      activeName: '0',
    }
  },
  created() {},
  methods: {},
}
</script>
<style scoped lang='scss'>
@import '@/styles/config.scss';
/deep/.setUp {
  padding: 20px;
  background: #fff;
  height: calc(100% - 45px);
  .el-tabs {
    height: 100%;
    .el-tabs__content {
      height: calc(100% - 55px);
      .el-tab-pane {
        height: 100%;
      }
    }
  }
}
</style>
