<template>
  <el-dialog
    class="editDialog"
    :append-to-body="true"
    width="800px"
    :visible.sync="showEditDialog"
    :close-on-click-modal="false"
    :destroy-on-close="true"
    @close="$emit('update:isShow', false)"
    :show-close="true"
  >
    <div slot="title" class="title">
      {{ options.jobs_id ? "编辑" : "新增" }}招聘信息
    </div>

    <div class="content">
      <el-form label-width="120px" :model="form" :rules="rules" ref="form">
        <el-form-item label="工作名称" prop="title" ref="title">
          <el-input
            v-model="form.title"
            placeholder="请输入工作名称"
          ></el-input>
        </el-form-item>

        <el-form-item
          label="岗位名称"
          prop="position_title"
          ref="position_title"
        >
          <ChoiceData
            :selectedData="[{ position_id: form.position_id }]"
            :configure="configurePosition"
            @choiceDataChange="choiceDataPositionChange"
          >
            <el-input
              slot="button"
              v-model="form.position_title"
              placeholder="请选择岗位"
            ></el-input>
            <div
              class="mainList_operation clearfloat"
              slot="search"
              slot-scope="{ params, search }"
            >
              <div class="mainList_operation_search">
                <el-input
                  class="item"
                  placeholder="请输入岗位名称"
                  clearable
                  v-model="params.position_title"
                ></el-input>
                <el-button
                  type="primary"
                  plain
                  icon="el-icon-search"
                  @click="search(1)"
                  >搜索</el-button
                >
                <el-button
                  type="primary"
                  plain
                  icon="el-icon-plus"
                  @click="toAddPosition"
                  >新增岗位</el-button
                >
              </div>
            </div>
            <template slot="tableColumn">
              <el-table-column
                align="center"
                prop="title"
                label="岗位名称"
              ></el-table-column>
              <el-table-column align="center" prop="is_hot" label="是否热门">
                <template slot-scope="scope">
                  {{ scope.row.is_hot | isHot }}
                </template>
              </el-table-column>
            </template>
          </ChoiceData>
        </el-form-item>
        <el-form-item label="选择机构" prop="organ_id" ref="organ_id">
          <ChoiceData
            :selectedData="[{ organ_id: form.organ_id }]"
            :configure="configure"
            @choiceDataChange="choiceDataChange"
          >
            <el-input
              slot="button"
              v-model="form.organ_title"
              placeholder="请选择机构"
            ></el-input>
            <div
              class="mainList_operation clearfloat"
              slot="search"
              slot-scope="{ params, search }"
            >
              <div class="mainList_operation_search">
                <el-input
                  class="item"
                  placeholder="请输入机构名称"
                  clearable
                  v-model="params.company_name"
                ></el-input>
                <el-input
                  class="item"
                  placeholder="请输入手机号"
                  clearable
                  v-model="params.mobile"
                ></el-input>
                <el-checkbox
                  style="margin-right: 10px"
                  v-model="isOrganAllData"
                  @change="organDataChange"
                  >查看全部机构?</el-checkbox
                >
                <el-button
                  type="primary"
                  plain
                  icon="el-icon-search"
                  @click="search(1)"
                  >搜索</el-button
                >
                <el-button
                  type="primary"
                  plain
                  icon="el-icon-plus"
                  @click="toAddOrgan"
                  >新增机构</el-button
                >
              </div>
            </div>
            <template slot="tableColumn">
              <el-table-column
                align="center"
                prop="company_name"
                label="机构名称"
              ></el-table-column>
              <el-table-column
                align="center"
                prop="company_logo"
                label="机构LOGO"
              >
                <template slot-scope="scope">
                  <div class="img_box">
                    <img
                      v-if="scope.row.company_logo"
                      :src="scope.row.company_logo"
                    />
                  </div>
                </template>
              </el-table-column>
              <el-table-column
                align="center"
                prop="mobile"
                label="手机号"
              ></el-table-column>
              <el-table-column
                align="center"
                prop="nickname"
                label="昵称"
              ></el-table-column>
              <el-table-column
                align="center"
                prop="email"
                label="邮箱"
              ></el-table-column>
            </template>
          </ChoiceData>
        </el-form-item>

        <el-form-item label="联系电话" prop="mobile" ref="mobile">
          <el-input
            v-model="form.mobile"
            placeholder="请输入联系电话"
          ></el-input>
        </el-form-item>
        <el-form-item label="邮箱" prop="email" ref="email">
          <el-input v-model="form.email" placeholder="请输入邮箱"></el-input>
        </el-form-item>
        <el-form-item label="机构地址" prop="organ_address" ref="organ_address">
          <el-input
            v-model="form.organ_address"
            placeholder="请输入机构地址"
          ></el-input>
        </el-form-item>
        <el-form-item label="岗位归属地" prop="address" ref="address">
          <el-cascader
            size="large"
            :options="cityOptions"
            v-model="form.address_arr"
            @change="handleChange"
          >
          </el-cascader>
        </el-form-item>
        <el-form-item label="工作年限" prop="year" ref="year">
          <el-select v-model="form.year" placeholder="请选择工作年限">
            <el-option
              v-for="(item, index) in yearList"
              :key="index"
              :label="item.name"
              :value="item.value"
            >
            </el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="学历" prop="education" ref="education">
          <el-select v-model="form.education" placeholder="请选择学历">
            <el-option
              v-for="(item, index) in educationList"
              :key="index"
              :label="item.name"
              :value="item.value"
            >
            </el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="薪资" prop="salary" ref="salary">
          <el-row type="flex" justify="space-between">
            <el-col :span="11">
              <el-input
                v-model="form.salary"
                min="0"
                type="number"
                placeholder="请输入最低薪资"
              ></el-input>
            </el-col>
            <el-col :span="2" style="text-align: center">至</el-col>
            <el-col :span="11">
              <el-input
                v-model="form.salary_max"
                min="0"
                type="number"
                placeholder="请输入最高薪资"
              ></el-input>
            </el-col>
          </el-row>
          <div style="margin-top: 10px">
            <el-alert
              title="最低薪资和最高薪资都设置为0时前端显示为面议"
              :closable="false"
              type="warning"
              show-icon
            ></el-alert>
          </div>
        </el-form-item>
        <el-form-item label="发布时间" prop="release_time">
          <el-date-picker
            value-format="yyyy-MM-dd HH:mm:ss"
            v-model="form.release_time"
            type="datetime"
          >
          </el-date-picker>
        </el-form-item>
        <el-form-item
          label="有效期"
          prop="is_end_time"
          ref="is_end_time"
          required
        >
          <el-radio-group v-model="form.is_end_time">
            <el-radio :value="1" :label="1">有效天数</el-radio>
            <el-radio :value="2" :label="2">截止日</el-radio>
          </el-radio-group>
        </el-form-item>

        <el-form-item
          v-if="form.is_end_time == 1"
          label="有效期/天"
          prop="useful_time"
        >
          <el-input-number
            :min="0"
            v-model="form.useful_time"
            placeholder="请输入购买后的有效期天数（0为长期有效）"
          ></el-input-number>
        </el-form-item>
        <el-form-item
          v-if="form.is_end_time == 2"
          label="截止日"
          prop="end_time"
        >
          <el-date-picker
            value-format="yyyy-MM-dd"
            v-model="form.end_time"
            type="date"
          >
          </el-date-picker>
        </el-form-item>
        <el-form-item label="标签" prop="tags" ref="tags">
          <ChoiceData
            :selectedData="selectedTags"
            :configure="tagsConfigure"
            @choiceDataChange="tagsChange"
          >
            <el-input
              slot="button"
              v-model="tagsText"
              placeholder="请选择标签"
            ></el-input>
            <div slot="choiceItem" slot-scope="{ data }">{{ data.title }}</div>
            <template slot="tableColumn">
              <el-table-column
                align="center"
                prop="title"
                label="标签"
              ></el-table-column>
              <el-table-column
                align="center"
                prop="create_time"
                label="创建时间"
              ></el-table-column>
            </template>
          </ChoiceData>
        </el-form-item>
        <el-form-item label="岗位详情" prop="content" ref="content">
          <Editor :value.sync="form.content"></Editor>
        </el-form-item>
      </el-form>
    </div>

    <div slot="footer">
      <el-button type="success" :loading="submitLoading" @click="submit"
        >保存</el-button
      >
      <el-button type="info" plain @click="$emit('update:isShow', false)"
        >取消</el-button
      >
    </div>
  </el-dialog>
</template>
<script>
import { provinceAndCityData, CodeToText } from "element-china-area-data";
export default {
  components: {
    Editor: () => import("@/components/editor/index.vue"),
    ChoiceData: () => import("@/components/ChoiceData.vue")
  },
  filters: {
    isHot(val) {
      return val == 0 ? "否" : "是";
    }
  },
  props: {
    isShow: {
      type: Boolean,
      default: false
    },
    options: {
      type: Object,
      default: function () {
        return {};
      }
    }
  },
  data() {
    return {
      cityOptions: provinceAndCityData,
      showEditDialog: false,
      submitLoading: false,
      isOrganAllData: false,
      configure: {
        request: this.$api.organ.organ,
        idKey: "organ_id",
        params: {
          method: "get.organ.list",
          is_admin: 1,
          type: "2"
        },
        title: "机构列表"
      },
      tagsConfigure: {
        request: this.$api.jobsTag.jobsTag,
        idKey: "jobs_tag_id",
        type: "checkbox",
        params: {
          method: "get.jobs_tag.list"
        },
        title: "招聘标签"
      },
      tagsText: "",
      configurePosition: {
        request: this.$api.position.position,
        idKey: "organ_id",
        params: {
          method: "get.position.list"
        },
        title: "岗位列表"
      },
      yearList: [
        {
          value: "不限",
          name: "不限"
        },
        {
          value: "在校生",
          name: "在校生"
        },
        {
          value: "应届生",
          name: "应届生"
        },
        {
          value: "1年以内",
          name: "1年以内"
        },
        {
          value: "1-3年",
          name: "1-3年"
        },
        {
          value: "3-5年",
          name: "3-5年"
        },
        {
          value: "5-10年",
          name: "5-10年"
        },
        {
          value: "10年以上",
          name: "10年以上"
        }
      ],
      educationList: [
        {
          value: "不限",
          name: "不限"
        },
        {
          value: "初中及以下",
          name: "初中及以下"
        },
        {
          value: "中专/技校",
          name: "中专/技校"
        },
        {
          value: "高中",
          name: "高中"
        },
        {
          value: "大专",
          name: "大专"
        },
        {
          value: "本科",
          name: "本科"
        },
        {
          value: "硕士",
          name: "硕士"
        },
        {
          value: "博士",
          name: "博士"
        }
      ],
      salaryList: [
        {
          value: "面议",
          name: "面议"
        },
        {
          value: "3000",
          name: "3000"
        },
        {
          value: "5000",
          name: "5000"
        },
        {
          value: "10000",
          name: "10000"
        },
        {
          value: "20000",
          name: "20000"
        }
      ],
      form: {},
      selectedTags: [],
      groupData: [],
      rules: {
        title: [
          {
            required: true,
            message: "请输入工作名称",
            trigger: ["blur", "change"]
          }
        ],
        position_title: [
          {
            required: true,
            message: "请选择岗位名称",
            trigger: ["blur", "change"]
          }
        ],
        organ_id: [
          {
            required: true,
            message: "请选择机构",
            trigger: ["blur", "change"]
          }
        ]
      }
    };
  },
  inject: ["refresh"],
  watch: {
    isShow: {
      immediate: true, //初始化立即执行
      handler: function (newVal) {
        this.$nextTick(() => {
          newVal && this.$refs.form && this.$refs.form.clearValidate();
        });
        this.showEditDialog = newVal;
      }
    },
    options: {
      immediate: true, //初始化立即执行
      deep: true, //对象深度监测
      handler: function (newVal, oldVal) {
        const xxx = newVal;
        this.init(xxx);
      }
    }
  },
  methods: {
    handleChange(val) {
      console.log(CodeToText[val[1]]);
      if (CodeToText[val[1]] === "市辖区") {
        this.form.address = CodeToText[val[0]];
      } else {
        this.form.address = CodeToText[val[1]];
      }
    },
    toAddPosition() {
      this.$router.push({
        name: "POSITION"
      });
    },
    toAddOrgan() {
      this.$router.push({
        name: "ORGAN",
        params: {
          type: 2
        }
      });
    },
    organDataChange(data) {
      console.log(data);
      if (data) {
        let params = JSON.parse(JSON.stringify(this.configure.params));
        delete params.is_admin;
        this.configure.params = params;
      } else {
        let params = JSON.parse(JSON.stringify(this.configure.params));
        params.is_admin = 1;
        this.configure.params = params;
      }
    },
    tagsChange(tagDatas) {
      console.log("tagDatas:", tagDatas);
      this.tagsText = tagDatas
        .map((item) => {
          return item.title;
        })
        .join("、");
      this.selectedTags = tagDatas.deepClone();
      this.$refs.form.validateField("tags");
    },
    choiceDataChange(r) {
      this.form.organ_id = r.organ_id;
      if (r.type === 1) {
        this.form.organ_title = r.name;
      } else {
        this.form.organ_title = r.company_name;
      }
      this.form.mobile = r.mobile;
      this.form.email = r.email;
      this.$refs.form.validateField("organ_id");
    },
    choiceDataPositionChange(r) {
      this.form.position_title = r.title;
      this.form.position_id = r.position_id;
      this.$refs.form.validateField("position_title");
    },
    init(options) {
      this.selectedTags = [];
      if (options.jobs_id) {
        //编辑进来的会有id需要
        this.form = options;
        this.tagsText = this.form.tag_text.join("、");
        this.form.tags.forEach((item, index) => {
          this.selectedTags.push({
            jobs_tag_id: item,
            title: this.form.tag_text[index]
          });
        });
      } else {
        this.form = {
          organ_title: "",
          organ_id: null,
          mobile: "",
          email: "",
          position_title: "",
          position_id: "",
          tags: [],
          tag_text: [],
          address_arr: []
        };
        this.tagsText = "";
      }
    },
    submit() {
      this.$refs.form.validate((valid, obj) => {
        if (valid) {
          this.submitLoading = true;
          let postData = this.form.deepClone();
          postData.tags = this.selectedTags
            .map((item) => {
              return item.jobs_tag_id;
            })
            .join(",");
          postData.tag_text = this.selectedTags.map((item) => {
            return item.title;
          });
          postData.method = postData.jobs_id
            ? "set.jobs.item"
            : "add.jobs.item";
          console.log("postData:", postData);
          this.$api.jobs
            .jobs(postData)
            .then((res) => {
              this.submitLoading = false;
              this.refresh(1);
              this.$message.success("操作成功！");
            })
            .catch((err) => {
              this.submitLoading = false;
              console.log(err);
            });
        } else {
          this.scrollView(obj);
        }
      });
    }
  }
};
</script>
<style scoped lang="scss">
@import "@/styles/config.scss";
.img_box {
  display: inline-flex;
  justify-content: left;
  align-items: flex-end;
  img {
    width: 30px;
    height: 30px;
    border-radius: 100%;
    margin-right: 5px;
  }
}
</style>
