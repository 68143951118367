<template>
  <el-dialog class="editDialog" :append-to-body="true" width="600px" :visible.sync="showEditDialog"
    :close-on-click-modal="false" :destroy-on-close="true" @close="$emit('update:isShow', false)" :show-close="true">
    <div slot="title" class="title">
      评论记录
    </div>

    <div class="content" v-loading="loading">
      <div style="padding:0 20px;">
        <template v-if="data.length > 0">
          <div class="el-timeline-item__wrapper" style="padding:0;margin:35px 0;" v-for="(item, index) in data">
            <div class="el-timeline-item__content">
              <div>{{ item.content }}</div>
            </div>
            <div class="el-timeline-item__timestamp is-bottom" style="line-height:20px;">
              <img style="width:20px;height:20px;vertical-align: middle;margin-right:5px;" :src="item.head_pic" />{{
                item.nickname }}　{{ item.create_time }}
              <div style="float:right;" size="mini">
                <template v-if="item.status == 0">
                  <el-button type="text" @click="setStatus(item, 1)">审核通过</el-button>
                  <el-button type="text" @click="statusChange(item, 2)">驳回</el-button>
                </template>
                <el-button v-else type="text" disabled>{{ item.status == 2 ? '已驳回' : '' }}</el-button>
              </div>
            </div>

            <div v-if="item.get_son_log.length > 0" class="el-timeline-item__wrapper"
              style="margin:25px 0;padding:0 0 0 20px;">
              <div v-for="(n, index) in item.get_son_log" :key="index">
                <div class="el-timeline-item__content">
                  <div>{{ n.content }}</div>
                </div>
                <div class="el-timeline-item__timestamp is-bottom">
                  <img style="width:20px;height:20px;vertical-align: middle;margin-right:5px;" :src="n.head_pic" />{{
                    n.nickname }}　{{ n.create_time }}
                  <div style="float:right;" size="mini">
                    <template v-if="n.status == 0">
                      <el-button type="text" @click="setStatus(n, 1)">审核通过</el-button>
                      <el-button type="text" @click="statusChange(n, 2)">驳回</el-button>
                    </template>
                    <el-button v-else type="text" disabled>{{ n.status == 2 ? '已驳回' : '' }}</el-button>
                  </div>
                </div>
              </div>
            </div>

          </div>
        </template>
        <div v-else>暂无数据</div>

      </div>
    </div>

  </el-dialog>
</template>
<script>
import { mobile, email } from '@/regular'

export default {
  props: {
    isShow: {
      type: Boolean,
      default: false,
    },
    options: {
      type: Object,
      default: function () {
        return {}
      },
    },
  },
  data() {
    return {
      showEditDialog: false,
      submitLoading: false,
      loading: true,
      data: [],
    }
  },
  inject: ['refresh'],
  watch: {
    isShow: {
      immediate: true, //初始化立即执行
      handler: function (newVal) {
        this.showEditDialog = newVal
      },
    },
    options: {
      immediate: true, //初始化立即执行
      deep: true, //对象深度监测
      handler: function (newVal, oldVal) {
        this.init()
      },
    },
  },
  created() { },
  methods: {
    init() {
      if (this.options.exchange_id) {
        this.loading = true
        this.data = []
        this.$api.ask
          .exchangeLog({
            method: 'get.exchange_log.list',
            // exchange_id:process.env.NODE_ENV=='development'?24:this.options.exchange_id,
            exchange_id: this.options.exchange_id,
          })
          .then((res) => {
            if (res.data.items && Array.isArray(res.data.items)) {
              this.data = res.data.items
            }

            this.loading = false
          })
          .catch((err) => {
            console.log(err)
            this.loading = false
          })
      }
    },
    setStatus(item, status, remarks = '') {
      this.loading = true
      this.$api.ask
        .exchangeLog({
          method: 'set.exchange_log.status',
          exchange_log_id: [item.exchange_log_id],
          status,
          remarks,
        })
        .then((res) => {
          this.loading = false
          this.init()
        })
        .catch((err) => {
          console.log(err)
          this.loading = false
        })
    },
    statusChange(item, status) {
      this.$prompt('请输入', '请输入驳回原因', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
      }).then(async ({ value }) => {
        this.setStatus(item, status, value)
      })
    },
  },
}
</script>
