<template>
  <el-dialog
    class="editDialog"
    :append-to-body="true"
    width="1200px"
    :visible.sync="showEditDialog"
    :close-on-click-modal="false"
    :destroy-on-close="true"
    @close="$emit('update:isShow', false)"
    :show-close="true"
  >
    <div slot="title" class="title">
      {{ options.course_video_id ? "编辑" : "新增" }}章节
    </div>

    <div class="content">
      <el-form
        class="column2"
        label-width="6em"
        :model="form"
        :rules="rules"
        ref="form"
      >
        <el-form-item label="标题" prop="video_title" ref="video_title">
          <el-input
            v-model="form.video_title"
            placeholder="请输入标题"
          ></el-input>
        </el-form-item>

        <el-form-item label="封面" prop="video_url" ref="video_url">
          <UploadImage v-model="form.video_url" />
        </el-form-item>

        <el-form-item label="折扣价格" prop="price">
          <el-input
            v-model="form.price"
            placeholder="请输入折扣价格"
          ></el-input>
        </el-form-item>

        <el-form-item label="原价" prop="scribe_price">
          <el-input
            v-model="form.scribe_price"
            placeholder="请输入原价"
          ></el-input>
        </el-form-item>

        <el-form-item label="关联课程" prop="course_id" required>
          <el-select
            clearable
            filterable
            multiple
            v-model="form.course_id"
            placeholder="请选择课程"
          >
            <el-option
              v-for="item in courseData"
              :key="item.course_id"
              :label="item.title"
              :value="item.course_id + ''"
            >
            </el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="是否置顶" prop="is_top">
          <el-radio-group v-model="form.is_top" size="medium">
            <el-radio :label="1">是</el-radio>
            <el-radio :label="0">否</el-radio>
          </el-radio-group>
        </el-form-item>

        <el-form-item label="排序" prop="sort" ref="sort">
          <el-input v-model="form.sort" placeholder="请输入排序"></el-input>
        </el-form-item>

        <el-form-item label="课程描述" prop="description" ref="description">
          <Editor :value.sync="form.description" :offUploadImg="false"></Editor>
        </el-form-item>
      </el-form>
    </div>

    <div slot="footer">
      <el-button type="success" :loading="submitLoading" @click="submit"
        >保存</el-button
      >
      <el-button type="info" plain @click="$emit('update:isShow', false)"
        >取消</el-button
      >
    </div>
  </el-dialog>
</template>
<script>
export default {
  components: {
    UploadImage: () => import("@/components/upload/Image.vue"),
    Editor: () => import("@/components/editor/index.vue")
  },
  props: {
    isShow: {
      type: Boolean,
      default: false
    },
    options: {
      type: Object,
      default: function () {
        return {};
      }
    }
  },
  data() {
    return {
      showEditDialog: false,
      submitLoading: false,
      form: {
        is_top: 0
      },
      groupData: [],
      rules: {
        course_id: [
          {
            required: true,
            message: "请输入课程表ID",
            trigger: ["blur", "change"]
          }
        ],
        video_title: [
          {
            required: true,
            message: "请输入标题",
            trigger: ["blur", "change"]
          }
        ],
        video_url: [
          {
            required: true,
            message: "请输入封面",
            trigger: ["blur", "change"]
          }
        ],
        price: [
          {
            required: true,
            message: "请输入价格",
            trigger: ["blur", "change"]
          }
        ],
        scribe_price: [
          {
            required: true,
            message: "请输入原价",
            trigger: ["blur", "change"]
          }
        ]
      },
      isMp4Url: false,
      courseData: []
    };
  },
  inject: ["refresh"],
  watch: {
    isShow: {
      immediate: true, //初始化立即执行
      handler: function (newVal) {
        this.$nextTick(() => {
          newVal && this.$refs.form && this.$refs.form.clearValidate();
        });
        this.showEditDialog = newVal;
      }
    },
    options: {
      immediate: true, //初始化立即执行
      deep: true, //对象深度监测
      handler: function (newVal, oldVal) {
        this.init(newVal);
      }
    }
  },
  created() {
    this.getCourseData();
  },
  methods: {
    init(options) {
      if (options.course_video_id) {
        //编辑进来的会有id需要
        this.form = { ...options };
      } else {
        this.form = {
          is_top: 0,
          video_url: "",
          sort: "",
          price: 0,
          scribe_price: 0
        };
        this.isMp4Url = false;
      }
    },
    getCourseData() {
      this.$api.course
        .course({
          method: "get.course.select",
          type: 5
        })
        .then((res) => {
          this.courseData = res.data.items;
        })
        .catch((err) => {
          console.log(err);
        });
    },
    submit() {
      this.$refs.form.validate((valid, obj) => {
        if (valid) {
          this.submitLoading = true;
          this.$api.course
            .course_video(
              this.form.combination({
                module: "admin",
                method: this.form.course_video_id
                  ? "set.course_video.item"
                  : "add.course_video.item"
              })
            )
            .then((res) => {
              this.submitLoading = false;
              this.refresh(1);
              this.$message.success("操作成功！");
            })
            .catch((err) => {
              this.submitLoading = false;
              console.log(err);
            });
        } else {
          this.scrollView(obj);
        }
      });
    },
    async handleVideoUrl() {
      const fileData = await this.getFileFromUrl(this.form.video_url, "test");
      if (this.form.video_url && fileData) {
        this.isMp4Url = true;
        this.form.size = Math.ceil(fileData.file.size / 1024 / 1024);
        this.form.duration = Math.ceil(fileData.duration);
      } else {
        this.isMp4Url = false;
        this.form.size = "";
        this.form.duration = "";
      }
    },
    // 通过视频url获取视频时长和大小
    getFileFromUrl(url, fileName) {
      return new Promise((resolve, reject) => {
        var audioElement = new Audio(url);
        var blob = null;
        var xhr = new XMLHttpRequest();
        xhr.open("GET", url);
        xhr.setRequestHeader("Accept", "video/mp4");
        xhr.responseType = "blob";
        // 加载时处理
        xhr.onload = () => {
          if (xhr.readyState === 4 && xhr.status === 200) {
            // 获取返回结果
            blob = xhr.response;
            let file = new File([blob], fileName, { type: "video/mp4" });
            // 返回结果
            audioElement.addEventListener("loadedmetadata", function (_event) {
              resolve({ duration: audioElement.duration, file });
            });
          } else {
            resolve(null);
          }
        };
        xhr.onerror = (e) => {
          reject(e);
        };
        // 发送
        xhr.send();
      });
    }
  }
};
</script>
<style scoped lang='scss'>
.video {
  margin-top: 5px;
  width: 459px;
}
</style>
