<template>
  <div>
    <el-menu mode="horizontal" background-color="#ffffff00" :default-active="activeIndex">
      <template v-for="(item,index) in menus">
        <template v-if="item.position=='top'">

          <template v-if="item.children&&item.children.length>0">
            <HorizontalMenuEleItem :menuObj="item"></HorizontalMenuEleItem>
          </template>
          <template v-else>
            <el-menu-item v-if="item.linkTo||item.href" :index="item.marking" :key="item.marking" @click.native="jump(item)">{{  item.name }}</el-menu-item>
          </template>

        </template>
      </template>
    </el-menu>
  </div>
</template>

<script>
import {mapState} from "vuex";

export default {
  name: "HorizontalMenuEle",
  components:{
    HorizontalMenuEleItem: () => import("./HorizontalMenuEleItem.vue"),
  },
  props:{},
  data () {
    return {
      activeIndex:this.$route.name,
    }
  },
  computed: {
    ...mapState({
      menus: state => state.user.menus,
    })
  },
  watch: {
    $route(to,from){
      this.activeIndex = to.name;
    }
  },
  created(){},
  methods:{
    jump(item){
      var path = item.linkTo?item.linkTo:item.url;
      this.$router.push(path);
    }
  }
}
</script>
<style lang="scss" scoped>
  @import "@/styles/config.scss";
  /deep/.el-menu.el-menu--horizontal {
    border:none;
    >.el-menu-item {
      background:none!important;
      border:none;
      color:$blue;
      font-size:16px;
    }
    >.el-submenu {
      .el-submenu__title {
        background: none !important;
        border: none;
        color: $blue;
        font-size: 16px;
        .el-submenu__icon-arrow {
          color: inherit;
        }
      }
      &.is-active {
        .el-submenu__title {
          border-bottom:3px solid $yellow;
          color:$yellow;
        }
      }
      .el-menu {background:#fff!important;}
      .el-menu-item {
        background:none!important;
        &.is-active {
          color:$blue;
        }
        &:hover {
          color:#fff;
          background:$blue!important;
        }
      }
    }
  }
</style>
