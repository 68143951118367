<template>
  <div>
    <Crumbs></Crumbs>
    <div class="mainList">
      <div class="mainList_operation clearfloat">
        <div class="mainList_operation_search">
          <el-select class="item" v-model="parameter.type">
            <el-option label="请选择类型" value=""></el-option>
            <el-option label="行业交流" value="1"></el-option>
            <el-option label="考试交流" value="2"></el-option>
          </el-select>
          <el-select class="item" v-model="parameter.status">
            <el-option label="请选择审核状态" value=""></el-option>
            <!-- <el-option label="待审核" value="0"></el-option>-->
            <el-option label="审核通过" value="1"></el-option>
            <el-option label="驳回" value="2"></el-option>
          </el-select>
          <el-button type="primary" plain icon="el-icon-search" @click="pageChangeHandler(1)">搜索</el-button>
        </div>
        <div class="fr">
          <template>
            <el-button type="danger" v-if="selectRows.length > 0" @click="handleDelete">批量删除</el-button>
            <el-button v-if="selectRows.length > 0" @click="batchEdit">批量设置状态</el-button>
          </template>
        </div>
      </div>
      <div class="mainList_content">
        <div class="mainList_content_sub">
          <el-table ref="multipleTable" border :data="tableData" tooltip-effect="dark" height="string"
            v-loading="loading" @selection-change="setSelectRows">
            <el-table-column align="center" show-overflow-tooltip type="selection"></el-table-column>
            <el-table-column label="序号" type="index" align="center" width="70"></el-table-column>
            <el-table-column align="center" prop="content" label="内容"></el-table-column>
            <el-table-column align="center" prop="give_praise_num" label="点赞数"></el-table-column>
            <el-table-column align="center" prop="nickname" label="发布昵称"></el-table-column>

            <el-table-column label="类型" align="center" width="160" fixed="right">
              <template slot-scope="scope">
                {{ scope.row.type | showType }}
              </template>
            </el-table-column>
            <el-table-column label="状态" align="center" width="160" fixed="right">
              <template slot-scope="scope">
                {{ scope.row.status | showStatus }}
              </template>
            </el-table-column>

            <el-table-column align="center" prop="create_time" label="创建时间"></el-table-column>

            <el-table-column align="center" prop="update_time" label="更新时间"></el-table-column>

            <el-table-column label="操作" align="center" width="160" fixed="right">
              <template slot-scope="scope">

                <el-button v-if="permission(['examine'])" type="text" size="small"
                  @click="handleEdit(scope.row)">审核</el-button>
                <el-button type="text" size="small" @click="exchangeLog(scope.row)">评论记录</el-button>
                <el-button v-if="permission(['examine'])" type="text" size="small" class="danger"
                  @click="deleteRow(scope.row)">删除</el-button>
              </template>
            </el-table-column>
          </el-table>
          <el-pagination @size-change="handleSizeChange" @current-change="pageChangeHandler"
            :current-page.sync="parameter.page_no" :page-size="parameter.page_size" prev-text="上一页" next-text="下一页"
            :total="parameter.total_result">
          </el-pagination>
        </div>
      </div>
    </div>
    <Edit :isShow.sync="showEditDialog" :exchange_id="exchange_id"></Edit>
    <ExchangeLog :isShow.sync="exchangeLogDialog" :options="options"></ExchangeLog>
  </div>
</template>
<script>
export default {
  components: {
    Edit: () => import('./Edit.vue'),
    ExchangeLog: () => import('./ExchangeLog.vue'),
    Crumbs: () => import('@/components/Crumbs.vue'),
  },
  data() {
    return {
      parameter: {
        method: 'get.exchange.list',
        page_no: 1,
        page_size: 50,
        total_result: 0,
        title: '',
        type: '1',
      },
      selectRows: [],
      exchange_id: [],
      activeData: {},
      options: {},
      tableData: [],
      loading: false,
      showEditDialog: false,
      exchangeLogDialog: false,
    }
  },
  provide() {
    return {
      refresh: this.refresh,
    }
  },

  filters: {
    showType(val) {
      if (val == 1) {
        return '行业交流'
      } else if (val == 2) {
        return '考试交流'
      }
    },
    showStatus(val) {
      //0:待审核 1:审核通过 2:驳回
      if (val == 0) {
        return '待审核'
      } else if (val == 1) {
        return '审核通过'
      } else {
        return '驳回'
      }
    },
  },
  watch: {},
  created() {
    this.getData()
  },
  methods: {
    setSelectRows(val) {
      this.selectRows = val
    },
    refresh() {
      this.showEditDialog = false
      this.exchangeLogDialog = false
      this.pageChangeHandler(1)
    },
    pageChangeHandler(val) {
      this.parameter.page_no = val
      this.getData()
    },
    handleSizeChange(val) {
      this.parameter.page_size = val
      this.parameter.page_no = 1
      this.getData()
    },
    batchEdit() {
      var arr = []
      this.selectRows.some((r) => {
        arr.push(r.exchange_id)
      })
      this.exchange_id = arr
      this.showEditDialog = true
    },
    handleEdit(row) {
      this.exchange_id = [row.exchange_id]
      this.showEditDialog = true
    },
    add() {
      this.options = {}
      this.showEditDialog = true
    },
    exchangeLog(row) {
      this.options = row
      this.exchangeLogDialog = true
    },
    getData() {
      this.loading = true
      this.$api.ask
        .exchange(this.parameter)
        .then((res) => {
          this.loading = false
          console.log(res.data)
          if (res.data.items && Array.isArray(res.data.items)) {
            this.tableData = res.data.items
          } else {
            this.tableData = []
          }
          this.parameter.total_result = res.data.total_result
        })
        .catch((err) => {
          console.log(err)
          this.loading = false
        })
    },
    // 删除当前行
    deleteRow(row) {
      this.$confirm('此操作将永久删除该数据, 是否继续?', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning',
      })
        .then(() => {
          this.$api.ask
            .exchange({
              module: 'admin',
              method: 'del.exchange.list',
              exchange_id: [row.exchange_id],
            })
            .then((res) => {
              if (res.status == 200) {
                this.$message.success('删除成功！')
                var hasLastPage =
                  this.parameter.page_size * this.parameter.page_no >=
                  this.parameter.total_result
                var lastData = this.tableData.length < 2
                if (lastData && hasLastPage && this.parameter.page_no > 1) {
                  this.parameter.page_no--
                }
                this.getData()
              }
            })
            .catch((err) => {
              console.log(err)
            })
        })
        .catch(() => {
          //用户取消
        })
    },
    // 批量删除
    handleDelete(row) {
      const ids = []
      if (row.exchange_id) {
        ids.push(row.exchange_id)
      } else {
        if (this.selectRows.length > 0) {
          const itemIds = this.selectRows.map((item) => item.exchange_id)
          for (var i = 0; i < itemIds.length; i++) {
            ids.push(itemIds[i])
          }
        } else {
          this.$message.error('未选中任何行')
          return false
        }
      }

      console.log(ids)
      this.$confirm('此操作将永久删除该数据, 是否继续?', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning',
      })
        .then(() => {
          this.$api.ask
            .exchange({
              module: 'admin',
              method: 'del.exchange.list',
              exchange_id: ids,
            })
            .then((res) => {
              if (res.status == 200) {
                this.$message.success('删除成功！')
                var hasLastPage =
                  this.parameter.page_size * this.parameter.page_no >=
                  this.parameter.total_result
                var lastData = this.tableData.length < 2
                if (lastData && hasLastPage && this.parameter.page_no > 1) {
                  this.parameter.page_no--
                }
                this.getData()
              }
            })
            .catch((err) => {
              console.log(err)
            })
        })
        .catch(() => {
          //用户取消
        })
    },
  },
}
</script>
<style scoped lang="scss">
@import '@/styles/config.scss';
</style>
