<template>
  <div class="mainList">
    <div class="mainList_operation clearfloat">
      <div class="fl colorBlue">{{ data.name }}广告详情</div>
      <div class="fr" v-if="data.code">
        <el-button type="primary" icon="el-icon-plus" @click="add()"
          >新增</el-button
        >
      </div>
    </div>
    <div class="mainList_content">
      <div class="mainList_content_sub" v-if="data.code">
        <el-table
          ref="multipleTable"
          row-key="id"
          border
          :data="tableData"
          :tree-props="{ children: 'children', hasChildren: 'hasChildren' }"
          tooltip-effect="dark"
          height="string"
          :default-expand-all="false"
          v-loading="loading"
        >
          <el-table-column align="center" prop="content" label="图片链接">
            <template slot-scope="scope">
              <div class="img_box">
                <el-avatar
                  v-if="scope.row.content"
                  :src="scope.row.content"
                  size="small"
                  shape="square"
                ></el-avatar>
              </div>
            </template>
          </el-table-column>
          <el-table-column prop="platform" align="center" label="平台">
            <template slot-scope="scope">
              {{ scope.row.platform === 1 ? 'pc' : 'web' }}
            </template>
          </el-table-column>
          <el-table-column
            align="center"
            prop="url"
            label="跳转链接"
          ></el-table-column>
          <el-table-column prop="target" align="center" label="打开方式">
            <template slot-scope="scope">
              {{ scope.row.target | target }}
            </template>
          </el-table-column>
          <el-table-column
            label="操作"
            fixed="right"
            align="center"
            width="160"
          >
            <template slot-scope="scope">
              <el-button type="text" size="mini" @click="edit(scope.row)"
                >编辑</el-button
              >
              <el-button
                type="text"
                class="danger"
                size="mini"
                @click="del(scope)"
                >删除</el-button
              >
            </template>
          </el-table-column>
        </el-table>
      </div>
      <el-result
        v-else
        icon="info"
        title=""
        subTitle="点击左侧广告版位查看详情"
      ></el-result>
    </div>
    <SubEdit
      :isShow.sync="showEditDialog"
      :data="data"
      :options="options"
    ></SubEdit>
  </div>
</template>
<script>
export default {
  components: {
    SubEdit: () => import('./SubEdit.vue'),
  },
  props: {
    data: {
      type: Object,
      default: function () {
        return {}
      },
    },
  },
  data() {
    return {
      loading: false,
      showEditDialog: false,
      tableData: [],
      options: {},
    }
  },
  watch: {
    data: {
      immediate: true, //初始化立即执行
      deep: true, //对象深度监测
      handler: function (newVal) {
        if (newVal.code) {
          this.getData()
        }
      },
    },
  },
  provide() {
    return {
      refresh: this.refresh,
    }
  },
  methods: {
    refresh() {
      this.showEditDialog = false
      this.getData()
    },
    add() {
      this.options = {
        code: this.data.code,
      }
      this.showEditDialog = true
    },
    edit(row) {
      this.options = JSON.parse(JSON.stringify(row))
      console.log('this.options', this.options)
      this.showEditDialog = true
    },
    pageChangeHandler(val) {
      this.parameter.page_no = val
      this.getData()
    },
    handleSizeChange(val) {
      this.parameter.page_size = val
      this.parameter.page_no = 1
      this.getData()
    },
    getData() {
      this.loading = true
      this.$api.banner
        .ads({
          method: 'get.ads.list',
          code: this.data.code,
        })
        .then((res) => {
          this.loading = false
          if (res.data && Array.isArray(res.data)) {
            this.tableData = res.data
          } else {
            this.tableData = []
          }
        })
        .catch((err) => {
          console.log(err)
          this.loading = false
        })
    },
    del(scope) {
      this.$confirm('此操作将永久删除该数据, 是否继续?', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning',
      })
        .then(() => {
          this.$api.banner
            .ads({
              module: 'admin',
              method: 'del.ads.list',
              'ads_id[]': scope.row.ads_id,
            })
            .then((res) => {
              this.$message.success('删除成功！')
              this.getData()
            })
            .catch((err) => {
              console.log(err)
            })
        })
        .catch(() => {
          //用户取消
        })
    },
  },
}
</script>
<style scoped lang="scss">
@import '@/styles/config.scss';
.img_box {
  display: inline-flex;
  justify-content: left;
  align-items: flex-end;
  span {
    margin-left: 5px;
  }
}
.dictMain {
  display: flex;
  justify-content: space-between;
  height: calc(100% - 45px);
  padding: 20px;
  .mainList {
    background: #fff;
    .mainList_operation {
      padding: 0;
    }
    .mainList_content_sub {
      padding: 0;
    }
    &:first-child {
      width: 45%;
    }
    &:last-child {
      width: calc(55% - 20px);
    }
  }
}
</style>
