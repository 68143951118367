<template>
    <div>
        <el-cascader
            ref="elCascader"
            v-model="val"
            :clearable="clearable"
            :disabled="disabled"
            :placeholder="placeholder"
            :options="options"
            :filterable="filterable"
            :props="cascaderProps"
            @change="optionChange">
        </el-cascader>
    </div>
</template>

<script>
import { initTreeData } from '@/util/common'
import Axios from '@/axios'
export default {
    components: {},
    props: {
        filterable: {
            type: Boolean,
            default: false
        },
        disabled: {
            type: Boolean,
            default: false
        },
        clearable: {//是否显示清空按钮
            type: Boolean,
            default: true
        },
        checkStrictly: {//是否可以选择分支节点
            type: Boolean,
            default: false
        },
        placeholder: {
            type: String,
            default: '请选择'
        },
        valKey: {
            type: String,
            default: 'region_id'
        },
        expandTrigger: {
            type: String,
            default: 'hover'
        },
        multiple:{
            type: Boolean,
            default: false
        },
        label: {
            type: String,
            default: 'name'
        },
        value: {}
    },
    data() {
        return {
            that: this,
            val: '',
            options: [],
            cascaderProps:{
                multiple:this.multiple,
                expandTrigger: this.expandTrigger,
                checkStrictly:this.checkStrictly,
                value:'region_id',
                label:'region_name',
                emitPath:false,
            }
        }
    },
    model: {
        prop: 'value',
        event: 'change'
    },
    watch: {
        value:{
            immediate:true,//初始化立即执行
            handler: function (newVal){
                if(this.multiple){
                    if(newVal){
                        this.val = newVal.split(",");
                    };
                }else {
                    this.val = newVal;
                };
            }
        },
    },
    created() {
        this.$api.dict.area().then(res => {
            if(res.data&&Array.isArray(res.data)) {
                this.options = res.treeData;
            }

        }).catch(err => {
            console.log(err);
        });
    },
    mounted() {

    },
    methods: {

        optionChange(v) {
            var value = '';
            if(v){
                if(this.multiple){
                    value = v.join();
                }else {
                    value = v
                };
            };

            this.$emit('areaChange', this.$refs.elCascader.getCheckedNodes());
            this.$emit('change', value);
        }
    }
};
</script>
