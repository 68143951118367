<template>
  <div>
    <Crumbs></Crumbs>
    <div class="mainList">
      <div class="mainList_operation clearfloat">
        <div class="mainList_operation_search">
          <el-select clearable class="item" v-model="parameter.status" placeholder="请选择发票状态">
            <el-option v-for="(item,index) in statusData" :key="index" :label="item.name" :value="item.value"></el-option>
          </el-select>
          <el-button type="primary" plain icon="el-icon-search" @click="pageChangeHandler(1)">搜索</el-button>
        </div>
      </div>
      <div class="mainList_content">
        <div class="mainList_content_sub">
          <el-table ref="multipleTable" border :data="tableData" height="string" v-loading="loading">
            <el-table-column label="序号" type="index" align="center" width="70"></el-table-column>
            <el-table-column align="center" prop="types" label="发票类型">
              <template slot-scope="scope">
                {{scope.row.types===1?'纸质发票':scope.row.types===2?'电子发票':''}}
              </template>
            </el-table-column>
            <el-table-column align="left" prop="invoice_title" label="发票抬头"></el-table-column>
            <el-table-column align="left" prop="tax_number" label="纳税人识别号"></el-table-column>
            <el-table-column align="left" prop="invoice_amount" label="发票金额"></el-table-column>
            <el-table-column align="center" prop="address" label="收件地址"></el-table-column>
            <el-table-column align="center" prop="email" label="邮箱地址"></el-table-column>
            <el-table-column align="center" prop="bank" label="银行名称"></el-table-column>
            <el-table-column align="center" prop="bank_code" label="银行账号"></el-table-column>
            <el-table-column align="center" prop="order_no" label="关联订单号"></el-table-column>
            <el-table-column align="center" prop="s_mobile" label="联系电话"></el-table-column>
            <el-table-column align="center" prop="status" label="状态">
              <template slot-scope="scope">
                {{ scope.row.status===1?'开票中':scope.row.status===2?'已完成':scope.row.status===3?'已取消':''}}
              </template>
            </el-table-column>
            <el-table-column label="操作" align="center" width="120">
              <template slot-scope="scope">
                <template v-if="scope.row.status===1">
                  <el-button type="text" size="small" @click="edit(scope)">开票</el-button>
                </template>
                <template v-if="scope.row.status===2">
                  <el-button type="text" size="small" @click="edit(scope)">重新开票</el-button>
                  <el-button type="text" size="small" @click="look(scope)">查看</el-button>
                </template>
              </template>
            </el-table-column>
          </el-table>
          <el-pagination @size-change="handleSizeChange" @current-change="pageChangeHandler" :current-page.sync="parameter.page_no" :page-size="parameter.page_size" prev-text="上一页" next-text="下一页" :total="parameter.total_result">
          </el-pagination>
        </div>
      </div>
    </div>
    <el-dialog title="开发票" :visible.sync="showEditDialog" width="50%">
      <el-alert v-if="!isLook" title="发票信息可选择复制，发票开好后电子票请上传下载二维码，纸质票请填写快递公司名称和运单号" :closable="false" type="warning"></el-alert>
      <div class="invoice-info" style="margin-top:16px">
        <el-form ref="form" :model="options" label-width="100px">
          <el-form-item label="发票类型">
            <el-input :readonly="true" :value="options.types===1?'纸质发票':options.types===2?'电子发票':''"></el-input>
          </el-form-item>
          <el-form-item label="发票抬头">
            <el-input :readonly="true" :value="options.invoice_title"></el-input>
          </el-form-item>
          <el-form-item label="纳税人识别号">
            <el-input :readonly="true" :value="options.tax_number"></el-input>
          </el-form-item>
          <el-form-item label="收件地址" v-if="options.types===1">
            <el-input :readonly="true" :value="options.address"></el-input>
          </el-form-item>
          <el-form-item label="邮箱地址" v-if="options.types===2">
            <el-input :readonly="true" :value="options.email"></el-input>
          </el-form-item>
          <el-form-item label="银行名称">
            <el-input :readonly="true" :value="options.bank"></el-input>
          </el-form-item>
          <el-form-item label="银行账号">
            <el-input :readonly="true" :value="options.bank_code"></el-input>
          </el-form-item>
          <el-form-item label="联系电话">
            <el-input :readonly="true" :value="options.s_mobile"></el-input>
          </el-form-item>
          <el-form-item label="快递公司" v-if="options.types===1">
            <el-input :readonly="isLook" v-model="options.delivery_name" placeholder="请输入快递公司名称"></el-input>
          </el-form-item>
          <el-form-item label="运单号码" v-if="options.types===1">
            <el-input :readonly="isLook" v-model="options.delivery_no" placeholder="请输入运单号码"></el-input>
          </el-form-item>
          <el-form-item label="电子票二维码" v-if="options.types===2">
            <UploadImage v-model="options.invoice_img" />
          </el-form-item>
          <el-form-item label="当前状态">
            <el-select v-model="options.status" disabled>
              <el-option v-for="(item,index) in setStatusList" :key="index" :label="item.name" :value="item.value"></el-option>
            </el-select>
          </el-form-item>
        </el-form>
      </div>
      <div slot="footer" class="dialog-footer" v-if="!isLook">
        <el-button @click="showEditDialog = false">取 消</el-button>
        <el-button type="primary" @click="confirmSetInvoice">确 定</el-button>
      </div>
      <div slot="footer" class="dialog-footer" v-else>
        <el-button type="primary" @click="showEditDialog=false;isLook=false;">确 定</el-button>
      </div>
    </el-dialog>

  </div>
</template>
<script>
export default {
  components: {
    Crumbs: () => import('@/components/Crumbs.vue'),
    UploadImage: () => import('@/components/upload/Image.vue'),
  },
  data() {
    return {
      parameter: {
        method: 'get.order_invoice.list',
        status: '',
        page_no: 1,
        page_size: 50,
        total_result: 0,
      },
      options: {},
      isLook: false,
      tableData: [],
      loading: false,
      showEditDialog: false,
      statusData: [
        {
          value: '',
          name: '全部',
        },
        {
          value: 1,
          name: '开票中',
        },
        {
          value: 2,
          name: '已完成',
        },
        {
          value: 3,
          name: '已取消',
        },
      ],
      setStatusList: [
        {
          value: 1,
          name: '开票中',
        },
        {
          value: 2,
          name: '已完成',
        },
        {
          value: 3,
          name: '已取消',
        },
      ],
    }
  },
  provide() {
    return {
      refresh: this.refresh,
    }
  },
  created() {
    this.getData()
  },
  methods: {
    refresh() {
      this.showEditDialog = false
      this.pageChangeHandler(1)
    },
    edit(scope) {
      this.options = JSON.parse(JSON.stringify(scope.row))
      this.showEditDialog = true
    },
    look(scope) {
      this.isLook = true
      this.options = JSON.parse(JSON.stringify(scope.row))
      this.showEditDialog = true
    },
    pageChangeHandler(val) {
      this.parameter.page_no = val
      this.getData()
    },
    handleSizeChange(val) {
      this.parameter.page_size = val
      this.parameter.page_no = 1
      this.getData()
    },
    getData() {
      this.loading = true
      this.$api.orderInvoice
        .invoiceList(this.parameter)
        .then((res) => {
          this.loading = false
          this.tableData = res.data.items
          this.parameter.total_result = res.data.total_result
        })
        .catch((err) => {
          console.log(err)
          this.loading = false
        })
    },
    confirmSetInvoice() {
      this.$confirm('确认信息正确吗?', '警告', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning',
      }).then(() => {
        if (this.options.types === 1) {
          // 纸质票
          if (
            !this.options.delivery_name ||
            this.options.delivery_name === '' ||
            !this.options.delivery_no ||
            this.options.delivery_no === ''
          ) {
            this.$message({
              showClose: true,
              message: '快递公司名称或运单号不可为空',
              type: 'warning',
            })
          } else {
            this.$api.orderInvoice
              .setInvoice({
                method: 'set.order_invoice.item',
                order_invoice_id: this.options.order_invoice_id,
                status: 2,
                delivery_name: this.options.delivery_name,
                delivery_no: this.options.delivery_no,
              })
              .then((res) => {
                console.log(res)
                this.showEditDialog = false
                this.refresh()
              })
              .catch((err) => {
                console.log(err)
              })
          }
        } else if (this.options.types === 2) {
          // 电子票
          if (!this.options.invoice_img || this.options.invoice_img === '') {
            this.$message({
              showClose: true,
              message: '电子发票二维码不可为空',
              type: 'warning',
            })
          } else {
            this.$api.orderInvoice
              .setInvoice({
                method: 'set.order_invoice.item',
                order_invoice_id: this.options.order_invoice_id,
                status: 2,
                invoice_img: this.options.invoice_img,
              })
              .then((res) => {
                console.log(res)
                this.showEditDialog = false
                this.refresh()
              })
              .catch((err) => {
                console.log(err)
              })
          }
        }
      })
    },
  },
}
</script>