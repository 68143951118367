<template>
  <div>
    <Crumbs></Crumbs>
    <div class="mainList">
      <div class="mainList_operation clearfloat">
        <div class="fr">
          <el-button type="primary" icon="el-icon-plus" @click="add"
            >导入</el-button
          >
        </div>
      </div>
      <div class="mainList_content">
        <div class="mainList_content_sub">
          <el-table
            ref="multipleTable"
            border
            :data="tableData"
            tooltip-effect="dark"
            height="string"
            v-loading="loading"
            @selection-change="setSelectRows"
          >
            <el-table-column align="center" label="标题">
              学习中心-行业数据
            </el-table-column>
            <el-table-column align="center" label="状态">
              导入成功
            </el-table-column>
            <el-table-column
              align="center"
              prop="create_time"
              label="导入时间"
            ></el-table-column>
          </el-table>
          <el-pagination
            @size-change="handleSizeChange"
            @current-change="pageChangeHandler"
            :current-page.sync="parameter.page_no"
            :page-size="parameter.page_size"
            prev-text="上一页"
            next-text="下一页"
            :total="parameter.total_result"
          >
          </el-pagination>
        </div>
      </div>
    </div>
    <el-dialog title="请选择文件导入" :visible.sync="dialogVisible">
      <el-upload
        class="upload-demo"
        drag
        action="/adminapi/v1/patent"
        :show-file-list="false"
        :data="uploadData"
        :on-success="uploadSuccess"
        accept="application/vnd.ms-excel,application/vnd.openxmlformats-officedocument.spreadsheetml.sheet"
      >
        <i class="el-icon-upload"></i>
        <div class="el-upload__text">将文件拖到此处，或<em>点击上传</em></div>
        <div class="el-upload__tip" slot="tip">只能上传xxx类型文件</div>
      </el-upload>
      <!-- <span slot="footer" class="dialog-footer">
        <el-button @click="dialogVisible = false">取 消</el-button>
        <el-button type="primary" @click="affirmToLead">确 定</el-button>
      </span> -->
    </el-dialog>
  </div>
</template>
<script>
export default {
  components: {
    Crumbs: () => import('@/components/Crumbs.vue'),
  },
  data() {
    return {
      dialogVisible: false,
      uploadData: {
        method: 'add.patent.item',
        token: this.$store.state.user.userInfo.token.token,
      },
      parameter: {
        method: 'get.patent.list',
        page_no: 1,
        page_size: 50,
        total_result: 0,
        order_field: 'create_time',
        order_type: 'desc',
      },
      selectRows: [],
      activeData: {},
      options: {},
      tableData: [],
      loading: false,
    }
  },
  provide() {
    return {
      refresh: this.refresh,
    }
  },
  watch: {},
  created() {
    this.getData()
  },
  methods: {
    uploadSuccess() {
      this.$message({
        message: '上传成功',
        type: 'success',
      })
      this.refresh()
    },
    setSelectRows(val) {
      this.selectRows = val
    },
    refresh() {
      this.dialogVisible = false
      this.pageChangeHandler(1)
    },
    pageChangeHandler(val) {
      this.parameter.page_no = val
      this.getData()
    },
    handleSizeChange(val) {
      this.parameter.page_size = val
      this.parameter.page_no = 1
      this.getData()
    },
    affirmToLead(scope) {},
    add() {
      this.options = {}
      this.dialogVisible = true
    },
    getData() {
      this.loading = true
      this.$api.industryManage
        .patentList(this.parameter)
        .then((res) => {
          this.loading = false
          console.log(res.data)
          if (res.data.items && Array.isArray(res.data.items)) {
            this.tableData = res.data.items
          } else {
            this.tableData = []
          }
          this.parameter.total_result = res.data.total_result
        })
        .catch((err) => {
          console.log(err)
          this.loading = false
        })
    },
  },
}
</script>
<style scoped lang="scss">
@import '@/styles/config.scss';
/deep/.upload-demo {
  .el-upload,
  .el-upload-dragger {
    width: 100%;
  }
}
</style>
