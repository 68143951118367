<template>
  <div class="reply_card_container">
    <el-avatar :src="replyData.head_pic" size="small"></el-avatar>
    <div class="reply_card_content">
      <div class="nickname">
        {{ replyData.nickname }}
      </div>
      <div class="text">{{ replyData.content }}</div>
      <div class="bottom">
        <span>{{ replyData.create_time }}</span>
        <span
          ><i
            :class="replyData.is_give ? 'iconfavorites-fill' : 'iconfavorites'"
            class="iconfont"
          ></i
          >{{ replyData.give_num || replyData.give_praise_num || 0 }}</span
        >
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'ReplyCard',
  props: {
    replyData: {
      type: Object,
      default: function () {
        return {}
      },
    },
  },
  data() {
    return {}
  },
  methods: {
    delReply() {},
  },
}
</script>

<style scoped lang="scss">
.reply_card_container {
  display: flex;
  padding: 0 10px 10px;
  .reply_card_content {
    padding-left: 10px;
    flex-grow: 1;
    .nickname {
      font-weight: bold;
    }
    .text {
      color: #000;
    }
    .bottom {
      display: flex;
      justify-content: space-between;
      font-size: 12px;
    }
  }
}
</style>
