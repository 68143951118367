<template>
    <div>
        <Crumbs></Crumbs>
        <div class="mainList">
            <div class="mainList_operation clearfloat">
                <div class="mainList_operation_search">
                    <el-button v-if="selectRows.length" class="item" type="primary" plain icon="el-icon-delete"
                        @click="removeRows">删除</el-button>
                    <el-input class="item" placeholder="请输入关键字" clearable v-model="parameter.keywords"></el-input>
                    <el-button type="primary" plain icon="el-icon-search" @click="pageChangeHandler(1)">搜索</el-button>
                </div>
            </div>
            <div class="mainList_content">
                <div class="mainList_content_sub">
                    <el-table ref="multipleTable" border :data="tableData" v-loading="loading"
                        @selection-change="handleSelectionChange">
                        <el-table-column type="selection" width="55">
                        </el-table-column>
                        <el-table-column label="序号" type="index" align="center" width="70"></el-table-column>
                        <el-table-column width="100" align="center" prop="user_id" label="用户ID"></el-table-column>
                        <el-table-column width="120" align="center" label="用户昵称">
                            <template slot-scope="scope">
                                <div class="head_pic_box">
                                    <img v-if="scope.row.head_pic" class="head_pic" :src="scope.row.head_pic">
                                    <span class="nickname">{{ scope.row.nickname }}</span>
                                </div>
                            </template>
                        </el-table-column>
                        <el-table-column width="100" align="center" label="性别">
                            <template slot-scope="scope">
                                {{ scope.row.sex | sex }}
                            </template>
                        </el-table-column>
                        <el-table-column align="left" prop="title" label="标题"></el-table-column>
                        <el-table-column align="left" prop="content" :show-overflow-tooltip="false" label="内容">
                            <template slot-scope="scope">
                                <el-popover placement="top-start" title="反馈内容" width="300" trigger="hover"
                                    :content="scope.row.content">
                                    <el-button type="text" slot="reference">{{ scope.row.content }}</el-button>
                                </el-popover>
                            </template>
                        </el-table-column>
                        <el-table-column align="center" label="反馈类型">
                            <template slot-scope="scope">
                                {{ scope.row.type | dict(typeList, 'id') }}
                            </template>
                        </el-table-column>
                    </el-table>
                    <el-pagination @size-change="handleSizeChange" @current-change="pageChangeHandler"
                        :current-page.sync="parameter.page_no" :page-size="parameter.page_size" prev-text="上一页"
                        next-text="下一页" :total="parameter.total_result">
                    </el-pagination>
                </div>
            </div>
        </div>
    </div>
</template>
<script>
export default {
    components: {
        Crumbs: () => import("@/components/Crumbs.vue"),
    },
    data() {
        return {
            selectRows: [],
            statusData: [
                {
                    status_id: 0,
                    name: '失败'
                },
                {
                    status_id: 1,
                    name: '成功'
                }
            ],
            logDaterange: '',
            parameter: {
                method: 'get.user_idea.list',
                page_no: 1,
                page_size: 50,
                total_result: 0
            },
            tableData: [],
            loading: false,
            typeList: [
                {
                    id: 1,
                    name: '反馈'
                },
                {
                    id: 2,
                    name: '建议'
                },
                {
                    id: 3,
                    name: '投诉'
                }
            ]
        };
    },
    provide() {
        return {
            refresh: this.refresh,
        }
    },
    watch: {},
    created() {
        this.getData();

    },
    methods: {
        refresh() {
            this.showEditDialog = false;
            this.pageChangeHandler(1);
        },
        pageChangeHandler(val) {
            this.parameter.page_no = val;
            this.getData();
        },
        handleSizeChange(val) {
            this.parameter.page_size = val;
            this.parameter.page_no = 1;
            this.getData();
        },
        daterangeFc(val) {
            if (!val) return
            this.parameter.begin_time = val[0];
            this.parameter.end_time = val[1];
        },
        getData() {
            this.loading = true;
            this.$api.user.user_idea(this.parameter).then(res => {
                this.loading = false;
                this.tableData = res.data.items;
                this.parameter.total_result = res.data.total_result;
            }).catch(err => {
                console.log(err);
                this.loading = false;
            });
        },
        handleSelectionChange(rows) {
            this.selectRows = JSON.parse(JSON.stringify(rows)).map(item => {
                return item.user_idea_id
            })
        },
        removeRows() {
            this.$confirm('此操作将永久删除, 是否继续?', '提示', {
                confirmButtonText: '确定',
                cancelButtonText: '取消',
                type: 'warning'
            }).then(() => {
                this.$api.user.user_idea({
                    method: 'del.user_idea.list',
                    user_idea_id: this.selectRows
                }).then(res => {
                    this.loading = false;
                    this.tableData = res.data.items;
                    this.parameter.total_result = res.data.total_result;
                })
            }).catch(() => {
                console.log('已取消')
            })
        }
    },
};
</script>
<style scoped lang='scss'>
@import "@/styles/config.scss";

.head_pic_box {
    display: flex;
    justify-content: left;
    align-items: center;

    img {
        width: 30px;
        height: 30px;
        border-radius: 100%;
        margin-right: 5px;
    }
}
</style>
