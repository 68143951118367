<template>
  <div class="delivery" v-loading="loading">
    <div class="con">
      <el-form class="column2" label-width="8em" :model="form" :rules="rules" ref="form">
        <el-form-item prop="api_id" label="快递鸟商户ID">
          <el-input v-model="form.api_id" :placeholder="`请输入快递鸟商户ID`"></el-input>
        </el-form-item>
        <el-form-item prop="api_key" label="快递鸟ApiKey">
          <el-input v-model="form.api_key" :placeholder="`请输入快递鸟ApiKey`"></el-input>
        </el-form-item>
        <el-form-item prop="is_sub" label="是否启用订阅">
          <el-radio-group v-model="form.is_sub" size="medium">
            <el-radio :label="1">是</el-radio>
            <el-radio :label="0">否</el-radio>
          </el-radio-group>
        </el-form-item>
      </el-form>
    </div>
    <div class="operation">
      <el-button type="success" :loading="submitLoading" @click="submit">保存</el-button>
    </div>
  </div>
</template>
<script>
import * as setting from '../../../api/setting'

export default {
  components: {},
  data() {
    return {
      loading: true,
      submitLoading: false,
      form: {},
      rules: {},
    }
  },
  created() {
    this.loading = true
    this.$api.setting
      .setting({
        method: 'get.setting.list',
        module: 'delivery_dist',
      })
      .then((res) => {
        this.form = {
          api_id: res.data.api_id.value,
          api_key: res.data.api_key.value,
          is_sub: res.data.is_sub.value,
        }
        this.loading = false
        console.log(res)
      })
      .catch((err) => {
        console.log(err)
        this.loading = false
      })
  },
  methods: {
    submit() {
      this.$refs.form.validate((valid, obj) => {
        if (valid) {
          this.submitLoading = true
          this.$api.setting
            .setting({
              method: 'set.delivery.dist.list',
              'data[api_id]': this.form.api_id,
              'data[api_key]': this.form.api_key,
              'data[is_sub]': this.form.is_sub,
            })
            .then((res) => {
              this.submitLoading = false
              this.$message.success('操作成功！')
            })
            .catch((err) => {
              console.log(err)
              this.submitLoading = false
            })
        } else {
          this.submitLoading = false
          this.scrollView(obj)
        }
      })
    },
  },
}
</script>
<style scoped lang="scss">
@import '@/styles/config.scss';

.delivery {
  height: 100%;
  overflow: auto;

  .con {
    padding: 20px 0;
    min-height: calc(100% - 80px);
  }

  .operation {
    display: block;
    position: sticky;
    bottom: 0;
    background: #fff;
    padding: 20px;
    text-align: center;
    width: 100%;

    .el-button {
      min-width: 120px;
      margin: 0 25px;
    }
  }
}
</style>
