<template>
  <div>
    <Crumbs></Crumbs>
    <div class="mainList">
      <div v-if="multipleSelection.length" class="mainList_operation clearfloat">
        <div class="fr">
          <el-button type="danger" @click="delMore">批量删除</el-button>
          <el-button @click="changeStatusMore()">批量通过</el-button>
          <el-button @click="toggleSelection()">取消选择</el-button>
        </div>
      </div>
      <div class="mainList_content">
        <div class="mainList_content_sub">
          <el-table ref="multipleTable" border :data="tableData" tooltip-effect="dark" height="string"
            v-loading="loading" @selection-change="handleSelectionChange">
            <el-table-column align="center" type="selection" width="55"></el-table-column>
            <el-table-column label="序号" type="index" align="center" width="70"></el-table-column>
            <el-table-column align="center" prop="user_id" label="用户ID"></el-table-column>
            <el-table-column align="left" label="用户昵称">
              <template slot-scope="scope">
                <div class="img_box">
                  <el-avatar v-if="scope.row.head_pic" :src="scope.row.head_pic" size="small"></el-avatar>
                  <span> {{ scope.row.nickname }}</span>
                </div>
              </template>
            </el-table-column>
            <el-table-column align="center" prop="course_id" label="课程ID"></el-table-column>
            <el-table-column align="center" prop="title" label="标题"></el-table-column>
            <el-table-column align="center" prop="content" label="内容"></el-table-column>
            <el-table-column align="center" prop="note_time" label="视频节点"></el-table-column>
            <el-table-column align="center" prop="give_praise_num" label="点赞数量"></el-table-column>
            <el-table-column align="center" prop="status" label="备注">
              <template slot-scope="scope">
                {{ scope.row.status | dict(stausTextList, 'id') }}
              </template>
            </el-table-column>
            <el-table-column align="center" prop="remarks" label="备注"></el-table-column>
            <el-table-column align="center" prop="update_time" label="编辑时间"></el-table-column>
            <el-table-column label="操作" align="left" width="200">
              <template slot-scope="scope">
                <el-button type="text" size="small" @click="viewReply(scope)">查看评论</el-button>
                <template>
                  <el-button type="text" v-if="permission(['NOTE_REFUSE']) && scope.row.status != 2"
                    @click="changeStatus(scope, 2)">拒绝</el-button>
                  <el-button type="text" v-if="permission(['NOTE_ADOPT']) && scope.row.status != 1" size="small"
                    @click="changeStatus(scope)">通过</el-button>
                </template>
                <el-button type="text" class="danger" size="small" @click="del(scope)">
                  删除</el-button>
              </template>
            </el-table-column>
          </el-table>
          <el-pagination @size-change="handleSizeChange" @current-change="pageChangeHandler"
            :current-page.sync="parameter.page_no" :page-size="parameter.page_size" prev-text="上一页" next-text="下一页"
            :total="parameter.total_result">
          </el-pagination>
        </div>
      </div>
    </div>
    <Edit :isShow.sync="showEditDialog" :options="options" :catTreeData="tableData"></Edit>
    <ViewReply :isShow.sync="showViewReplyDialog" :options="options"></ViewReply>
  </div>
</template>
<script>
export default {
  components: {
    Edit: () => import('./Edit.vue'),
    ViewReply: () => import('./ViewReply.vue'),
    Crumbs: () => import('@/components/Crumbs.vue'),
  },
  data() {
    return {
      parameter: {
        method: 'get.course_note.list',
      },
      options: {},
      tableData: [],
      loading: false,
      showEditDialog: false,
      showViewReplyDialog: false,
      multipleSelection: [],
      stausTextList: [
        {
          id: 0,
          name: '未审核',
        },
        {
          id: 1,
          name: '审核通过',
        },
        {
          id: 2,
          name: '已驳回',
        },
      ],
    }
  },
  provide() {
    return {
      refresh: this.refresh,
    }
  },
  watch: {},
  created() {
    this.getData()
  },
  methods: {
    refresh() {
      this.showEditDialog = false
      this.pageChangeHandler(1)
    },
    pageChangeHandler(val) {
      this.parameter.page_no = val
      this.getData()
    },
    handleSizeChange(val) {
      this.parameter.page_size = val
      this.parameter.page_no = 1
      this.getData()
    },
    viewReply(scope) {
      this.options = scope.row
      this.showEditDialog = true
    },
    add() {
      this.options = {}
      this.showEditDialog = true
    },
    getData() {
      this.loading = true
      this.$api.course
        .course_note(this.parameter)
        .then((res) => {
          this.loading = false
          this.tableData = res.data.items
          this.parameter.total_result = res.data.total_result
        })
        .catch((err) => {
          console.log(err)
          this.loading = false
        })
    },
    edit(scope) {
      this.options = scope.row
      this.showEditDialog = true
    },
    del(scope) {
      this.delFc([scope.row.course_note_id])
    },
    delMore() {
      const delList = []
      this.multipleSelection.forEach((item) =>
        delList.push(item.course_note_id)
      )
      this.delFc(delList)
    },
    delFc(delData) {
      this.$confirm('此操作将永久删除该数据, 是否继续?', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning',
      })
        .then(() => {
          this.$api.course
            .course_note({
              method: 'del.course_note.list',
              course_note_id: delData,
            })
            .then((res) => {
              this.$message.success('删除成功！')
              this.getData()
            })
            .catch((err) => {
              console.log(err)
            })
        })
        .catch(() => {
          //用户取消
        })
    },
    changeStatus(scope, status = 1) {
      if (status != 1) {
        this.edit(scope)
      } else {
        this.changeStatusFc(status, [scope.row.course_note_id])
      }
    },
    changeStatusMore(status = 1) {
      const stausList = []
      this.multipleSelection.forEach((item) =>
        stausList.push(item.course_note_id)
      )
      this.changeStatusFc(status, stausList)
    },
    changeStatusFc(status, statusData) {
      this.$confirm(
        `此操作将${status == 1 ? '通过' : '拒绝'}该数据, 是否继续?`,
        '提示',
        {
          confirmButtonText: '确定',
          cancelButtonText: '取消',
          type: 'warning',
        }
      )
        .then(() => {
          this.$api.course
            .course_note({
              method: 'set.course_note.status',
              status,
              course_note_id: statusData,
            })
            .then((res) => {
              this.$message.success(`${status == 1 ? '通过' : '拒绝'}成功！`)
              this.getData()
            })
            .catch((err) => {
              console.log(err)
            })
        })
        .catch(() => {
          //用户取消
        })
    },
    toggleSelection(rows) {
      if (rows) {
        rows.forEach((row) => {
          this.$refs.multipleTable.toggleRowSelection(row)
        })
      } else {
        this.$refs.multipleTable.clearSelection()
      }
    },
    handleSelectionChange(val) {
      this.multipleSelection = val
    },
    viewReply(scope) {
      this.options = scope.row
      this.showViewReplyDialog = true
    },
  },
}
</script>
<style scoped lang="scss">
@import '@/styles/config.scss';

.img_box {
  display: inline-flex;
  justify-content: left;
  align-items: flex-end;

  span {
    margin-left: 5px;
  }
}
</style>
