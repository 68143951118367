<template>
  <div>
    <Crumbs></Crumbs>
    <div class="mainList">
      <div class="mainList_operation">
        <div class="">
          <el-row type="flex" justify="space-between" :gutter="5">
            <el-col :span="10">
              <el-row type="flex" justify="left" :gutter="5">
                <el-col :span="9">
                  <el-select
                    size="small"
                    style="width: 100%"
                    v-model="parameter.type"
                    clearable
                    placeholder="请选择课程类型"
                  >
                    <el-option
                      v-for="item in typeData"
                      :key="item.type_id"
                      :label="item.name"
                      :value="item.type_id"
                    >
                    </el-option>
                  </el-select>
                </el-col>
                <el-col :span="9">
                  <el-input
                    size="small"
                    style="width: 100%"
                    class="item"
                    placeholder="请输入标题/讲师用户名称"
                    clearable
                    v-model="parameter.keywords"
                  ></el-input>
                </el-col>
                <el-col :span="6">
                  <el-button
                    size="small"
                    style="width: 100%"
                    type="primary"
                    plain
                    icon="el-icon-search"
                    @click="getData()"
                    >搜索</el-button
                  >
                </el-col>
              </el-row>
            </el-col>
            <el-col :span="14">
              <el-row type="flex" justify="end">
                <el-button
                  size="small"
                  type="primary"
                  icon="el-icon-plus"
                  @click="add()"
                  >新增</el-button
                >
                <template v-if="multipleSelection.length">
                  <el-button size="small" @click="topMore()"
                    >批量置顶</el-button
                  >
                  <el-button size="small" @click="topMore(0)"
                    >批量取消置顶</el-button
                  >
                  <el-button size="small" @click="changeStatusMore()"
                    >批量启用</el-button
                  >
                  <el-button size="small" @click="changeStatusMore(2)"
                    >批量禁用</el-button
                  >
                  <el-button size="small" @click="toggleSelection()"
                    >取消选择</el-button
                  >
                </template>
              </el-row>
            </el-col>
          </el-row>
        </div>
      </div>
      <div class="mainList_content">
        <div class="mainList_content_sub">
          <el-table
            ref="multipleTable"
            row-key="course_id"
            border
            :data="tableData"
            :tree-props="{ children: 'children', hasChildren: 'hasChildren' }"
            tooltip-effect="dark"
            height="string"
            :default-expand-all="false"
            v-loading="loading"
            @selection-change="handleSelectionChange"
          >
            <el-table-column align="center" type="selection" width="55">
            </el-table-column>
            <el-table-column
              prop="title"
              width="200"
              align="left"
              label="标题"
              fixed
            ></el-table-column>
            <el-table-column align="center" label="封面">
              <template slot-scope="scope">
                <div class="cat_pic_box">
                  <img v-if="scope.row.image" :src="scope.row.image" />
                </div>
              </template>
            </el-table-column>
            <!-- <el-table-column prop="keywords" align="center" label="关键词"></el-table-column> -->
            <el-table-column
              prop="type_text"
              align="center"
              label="类型"
            ></el-table-column>
            <!-- <el-table-column prop="description" align="center" label="简介"></el-table-column> -->
            <el-table-column align="center" label="讲师">
              <template slot-scope="scope">
                {{
                  (scope.row.get_organ && scope.row.get_organ.name) ||
                  scope.row.tutor_name
                }}
              </template>
            </el-table-column>
            <el-table-column align="center" label="是否置顶">
              <template slot-scope="scope">
                {{ scope.row.is_top | yesOrNo }}
              </template>
            </el-table-column>
            <el-table-column
              prop="page_views"
              align="center"
              sortable
              label="观看量"
              width="90"
            ></el-table-column>
            <el-table-column
              prop="give_views"
              align="center"
              sortable
              label="点赞量"
              width="90"
            ></el-table-column>
            <el-table-column
              prop="fans_num"
              align="center"
              sortable
              label="粉丝数"
              width="90"
            >
              <template slot-scope="scope">
                {{ (scope.row.get_organ && scope.row.get_organ.fans_num) || 0 }}
              </template>
            </el-table-column>
            <el-table-column
              prop="like_num"
              align="center"
              sortable
              label="关注数"
              width="90"
            >
              <template slot-scope="scope">
                {{ (scope.row.get_organ && scope.row.get_organ.like_num) || 0 }}
              </template>
            </el-table-column>
            <el-table-column
              prop="price"
              align="center"
              sortable
              label="价格"
            ></el-table-column>
            <el-table-column
              prop="scribe_price"
              align="center"
              sortable
              label="原价"
            ></el-table-column>
            <el-table-column
              prop="total_score"
              align="center"
              sortable
              label="评分"
            ></el-table-column>
            <el-table-column
              prop="create_time"
              align="center"
              sortable
              label="发布时间"
              width="200"
            ></el-table-column>
            <!-- <el-table-column prop="type" align="center" label="类型"></el-table-column> -->
            <!-- <el-table-column prop="get_organ.name" align="center" label="机构"></el-table-column> -->
            <!-- <el-table-column prop="sort" align="center" label="排序"></el-table-column> -->
            <el-table-column prop="status" align="center" label="是否禁用">
              <template slot-scope="scope">
                {{ scope.row.status | status }}
              </template>
            </el-table-column>
            <el-table-column
              label="操作"
              fixed="right"
              align="center"
              width="240"
            >
              <template slot-scope="scope">
                <template>
                  <el-button type="text" size="small" @click="edit(scope)"
                    >编辑</el-button
                  >
                  <el-button
                    type="text"
                    v-if="scope.row.is_top == 1"
                    size="small"
                    @click="top(scope)"
                    >取消置顶</el-button
                  >
                  <el-button type="text" v-else size="small" @click="top(scope)"
                    >置顶</el-button
                  >
                  <el-button
                    type="text"
                    v-if="scope.row.status == 1"
                    size="small"
                    @click="changeStatus(scope)"
                    >禁用</el-button
                  >
                  <el-button
                    type="text"
                    v-else
                    size="small"
                    @click="changeStatus(scope)"
                    >启用</el-button
                  >
                </template>
                <el-button
                  type="text"
                  size="small"
                  class="danger"
                  @click="del(scope)"
                  >删除</el-button
                >
              </template>
            </el-table-column>
          </el-table>
          <el-pagination
            @size-change="handleSizeChange"
            @current-change="pageChangeHandler"
            :current-page.sync="parameter.page_no"
            :page-size="parameter.page_size"
            prev-text="上一页"
            next-text="下一页"
            :total="parameter.total_result"
          >
          </el-pagination>
        </div>
      </div>
    </div>
    <Edit
      :isShow.sync="showEditDialog"
      :options="options"
      :catTreeData="tableData"
    ></Edit>
    <el-drawer
      size="80%"
      title="课程章节"
      :visible.sync="showDrawer"
      direction="rtl"
    >
      <div style="height: 90vh">
        <videoStep></videoStep>
      </div>
    </el-drawer>
  </div>
</template>
<script>
import { initTreeData } from "@/util/common";
import videoStep from "@/views/course/video/Index.vue";
export default {
  components: {
    Edit: () => import("./Edit.vue"),
    Crumbs: () => import("@/components/Crumbs.vue"),
    videoStep
  },
  data() {
    return {
      showDrawer: false,
      parameter: {
        page_no: 1,
        page_size: 50,
        total_result: 0
      },
      form: {
        parent_id: 0,
        status: 1
      },
      tableData: [],
      options: {},
      showEditDialog: false,
      multipleSelection: [],
      typeData: [
        {
          type_id: 1,
          name: "公开课"
        },
        {
          type_id: 2,
          name: "精品课"
        },
        {
          type_id: 3,
          name: "员工专属"
        },
        {
          type_id: 4,
          name: "会员课程"
        },
        {
          type_id: 5,
          name: "系列课程"
        }
      ]
    };
  },
  provide() {
    return {
      refresh: this.refresh
    };
  },
  created() {
    this.getData();
  },
  methods: {
    refresh() {
      this.showEditDialog = false;
      this.getData();
    },
    add(parent_id = 0) {
      this.options = { parent_id };
      this.showEditDialog = true;
    },
    edit(scope) {
      this.options = scope.row;
      this.showEditDialog = true;
    },
    pageChangeHandler(val) {
      this.parameter.page_no = val;
      this.getData();
    },
    handleSizeChange(val) {
      this.parameter.page_size = val;
      this.parameter.page_no = 1;
      this.getData();
    },
    getData() {
      this.loading = true;
      this.$api.course
        .course({
          method: "get.course.list",
          ...this.parameter
        })
        .then((res) => {
          this.loading = false;
          if (res.data.items && Array.isArray(res.data.items)) {
            this.tableData = res.data.items;
          } else {
            this.tableData = [];
          }
          this.parameter.total_result = res.data.total_result;
        })
        .catch((err) => {
          console.log(err);
          this.loading = false;
        });
    },
    del(scope) {
      this.delFc(scope.row.course_id);
    },
    delFc(delData) {
      this.$confirm("此操作将永久删除该数据, 是否继续?", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning"
      })
        .then(() => {
          this.$api.course
            .course({
              method: "del.course.item",
              course_id: delData
            })
            .then((res) => {
              this.$message.success("删除成功！");
              this.getData();
            })
            .catch((err) => {
              console.log(err);
            });
        })
        .catch(() => {
          //用户取消
        });
    },
    top(scope) {
      const is_top = scope.row.is_top === 1 ? 0 : 1;
      this.topFc(is_top, [scope.row.course_id]);
    },
    topMore(is_top = 1) {
      const topList = [];
      this.multipleSelection.forEach((item) => topList.push(item.course_id));
      this.topFc(is_top, topList);
    },
    topFc(is_top, statusData) {
      this.$confirm(
        `此操作将${is_top == 1 ? "置顶" : "取消置顶"}该数据, 是否继续?`,
        "提示",
        {
          confirmButtonText: "确定",
          cancelButtonText: "取消",
          type: "warning"
        }
      )
        .then(() => {
          this.$api.course
            .course({
              method: "set.course.top",
              is_top,
              course_id: statusData
            })
            .then((res) => {
              this.$message.success(
                `${is_top == 1 ? "置顶" : "取消置顶"}成功！`
              );
              this.getData();
            })
            .catch((err) => {
              console.log(err);
            });
        })
        .catch(() => {
          //用户取消
        });
    },
    changeStatus(scope) {
      const status = scope.row.status == 1 ? 2 : 1;
      this.changeStatusFc(status, [scope.row.course_id]);
    },
    changeStatusMore(status = 1) {
      const stausList = [];
      this.multipleSelection.forEach((item) => stausList.push(item.course_id));
      this.changeStatusFc(status, stausList);
    },
    changeStatusFc(status, statusData) {
      this.$confirm(
        `此操作将${status == 1 ? "启用" : "禁用"}该数据, 是否继续?`,
        "提示",
        {
          confirmButtonText: "确定",
          cancelButtonText: "取消",
          type: "warning"
        }
      )
        .then(() => {
          this.$api.course
            .course({
              method: "set.course.status",
              status,
              course_id: statusData
            })
            .then((res) => {
              this.$message.success(`${status == 1 ? "启用" : "禁用"}成功！`);
              this.getData();
            })
            .catch((err) => {
              console.log(err);
            });
        })
        .catch(() => {
          //用户取消
        });
    },
    toggleSelection(rows) {
      if (rows) {
        rows.forEach((row) => {
          this.$refs.multipleTable.toggleRowSelection(row);
        });
      } else {
        this.$refs.multipleTable.clearSelection();
      }
    },
    handleSelectionChange(val) {
      this.multipleSelection = val;
    },
    showVideoStep() {
      this.showDrawer = true;
    }
  }
};
</script>
<style scoped lang="scss">
@import "@/styles/config.scss";

.cat_pic_box {
  display: inline-flex;
  justify-content: left;
  align-items: baseline;

  img {
    width: 30px;
    height: 30px;
    border-radius: 100%;
    margin-right: 5px;
  }
}

/deep/.mainList_operation_search .vue-treeselect__control {
  height: 40px;
}
</style>
