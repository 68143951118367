<template>
  <div>
    <Crumbs></Crumbs>
    <div class="mainList">
      <div class="mainList_operation clearfloat">
        <div class="mainList_operation_search">
          <el-radio-group
            v-model="parameter.type"
            class="mr10"
            @change="pageChangeHandler(1)"
          >
            <el-radio-button
              v-for="item in typeData"
              :key="item.type_id"
              :label="item.type_id"
              >{{ item.name }}</el-radio-button
            >
          </el-radio-group>
          <el-input
            class="item"
            placeholder="请输入关键字"
            clearable
            v-model="parameter.keywords"
          ></el-input>
          <el-select
            clearable
            class="item"
            v-model="parameter.status"
            placeholder="请选择状态"
          >
            <el-option
              v-for="item in statusData"
              :key="item.status_id"
              :label="item.name"
              :value="item.status_id"
            >
            </el-option>
          </el-select>
          <el-date-picker
            clearable
            style="margin-right: 15px"
            v-model="parameter.add_time"
            type="datetimerange"
            range-separator="至"
            start-placeholder="开始日期"
            end-placeholder="结束日期"
          >
          </el-date-picker>
          <el-button
            type="primary"
            plain
            icon="el-icon-search"
            @click="pageChangeHandler(1)"
            >搜索</el-button
          >
        </div>
        <div class="fr">
          <el-button type="primary" icon="el-icon-plus" @click="add"
            >新增</el-button
          >
          <template v-if="multipleSelection.length">
            <!-- <el-button @click="delMore">批量删除</el-button> -->
            <el-button @click="changeStatusMore()">批量启用</el-button>
            <el-button @click="changeStatusMore(2)">批量禁用</el-button>
            <el-button @click="toggleSelection()">取消选择</el-button>
          </template>
        </div>
      </div>
      <div class="mainList_content">
        <div class="mainList_content_sub">
          <el-table
            ref="multipleTable"
            border
            :data="tableData"
            height="string"
            v-loading="loading"
            @selection-change="handleSelectionChange"
          >
            <el-table-column
              align="center"
              type="selection"
              width="55"
            ></el-table-column>
            <el-table-column
              label="序号"
              type="index"
              align="center"
              width="70"
            ></el-table-column>
            <el-table-column align="left" prop="name" label="姓名">
              <template slot-scope="scope">
                <div class="img_box">
                  <img
                    v-if="scope.row.attachment"
                    :src="scope.row.attachment"
                  />
                  <span>{{ scope.row.name }}</span>
                </div>
              </template>
            </el-table-column>
            <el-table-column
              align="center"
              prop="user_id"
              label="用户ID"
            ></el-table-column>
            <el-table-column
              align="center"
              prop="nickname"
              label="用户昵称"
            ></el-table-column>
            <el-table-column align="center" prop="is_show" label="V栏显示">
              <template slot-scope="scope">
                {{ scope.row.is_show ? "是" : "否" }}
              </template>
            </el-table-column>
            <el-table-column
              align="center"
              prop="mobile"
              label="手机号"
            ></el-table-column>
            <el-table-column
              align="center"
              prop="email"
              label="邮箱"
            ></el-table-column>
            <!-- <el-table-column align="center" prop="type" label="类型"></el-table-column> -->
            <el-table-column
              align="center"
              prop="company_name"
              label="企业名称"
            ></el-table-column>
            <template v-if="parameter.type === 2">
              <el-table-column align="center" label="企业LOGO">
                <template slot-scope="scope">
                  <div class="img_box">
                    <img
                      v-if="scope.row.company_logo"
                      :src="scope.row.company_logo"
                    />
                  </div>
                </template>
              </el-table-column>
              <el-table-column align="center" label="企业头像">
                <template slot-scope="scope">
                  <div class="img_box">
                    <img
                      v-if="scope.row.company_head"
                      :src="scope.row.company_head"
                    />
                  </div>
                </template>
              </el-table-column>
            </template>
            <el-table-column
              align="center"
              prop="cultural_title"
              label="职称"
            ></el-table-column>
            <el-table-column align="center" prop="is_top" label="首页推荐">
              <template slot-scope="scope">
                {{ scope.row.is_top === 1 ? "是" : "否" }}
              </template>
            </el-table-column>
            <el-table-column align="center" prop="status" label="状态">
              <template slot-scope="scope">
                {{ scope.row.status | status }}
              </template>
            </el-table-column>
            <el-table-column
              prop="create_time"
              align="center"
              label="创建日期"
            ></el-table-column>
            <el-table-column label="操作" align="center" width="120">
              <template slot-scope="scope">
                <template>
                  <el-button type="text" size="small" @click="edit(scope)"
                    >编辑</el-button
                  >
                  <el-button
                    type="text"
                    v-if="scope.row.status == 1"
                    size="small"
                    @click="changeStatus(scope)"
                    >禁用</el-button
                  >
                  <el-button
                    type="text"
                    v-else
                    size="small"
                    @click="changeStatus(scope)"
                    >启用</el-button
                  >
                </template>
              </template>
            </el-table-column>
          </el-table>
          <el-pagination
            @size-change="handleSizeChange"
            @current-change="pageChangeHandler"
            :current-page.sync="parameter.page_no"
            :page-size="parameter.page_size"
            prev-text="上一页"
            next-text="下一页"
            :total="parameter.total_result"
          >
          </el-pagination>
        </div>
      </div>
    </div>
    <Edit :isShow.sync="showEditDialog" :options="options"></Edit>
  </div>
</template>
<script>
export default {
  components: {
    Edit: () => import("./Edit.vue"),
    Crumbs: () => import("@/components/Crumbs.vue")
  },
  data() {
    return {
      parameter: {
        method: "get.organ.list",
        type: 1,
        page_no: 1,
        page_size: 50,
        total_result: 0
      },
      options: {},
      tableData: [],
      loading: false,
      showEditDialog: false,
      multipleSelection: [],
      statusData: [
        {
          status_id: 2,
          name: "禁用"
        },
        {
          status_id: 1,
          name: "启用"
        }
      ],
      typeData: [
        {
          type_id: 1,
          name: "讲师"
        },
        {
          type_id: 2,
          name: "机构"
        }
      ]
    };
  },
  provide() {
    return {
      refresh: this.refresh
    };
  },
  watch: {},
  created() {
    if (this.$route.params.type) {
      this.parameter.type = this.$route.params.type;
    }
    this.getData();
  },
  methods: {
    refresh() {
      this.showEditDialog = false;
      this.pageChangeHandler(1);
    },
    edit(scope) {
      this.options = scope.row;
      this.showEditDialog = true;
    },
    add() {
      this.options = {
        type: this.parameter.type
      };
      this.showEditDialog = true;
    },
    pageChangeHandler(val) {
      this.parameter.page_no = val;
      this.getData();
    },
    handleSizeChange(val) {
      this.parameter.page_size = val;
      this.parameter.page_no = 1;
      this.getData();
    },
    getData() {
      this.loading = true;
      this.$api.organ
        .organ(this.parameter)
        .then((res) => {
          this.loading = false;
          this.tableData = res.data.items;
          this.parameter.total_result = res.data.total_result;
        })
        .catch((err) => {
          console.log(err);
          this.loading = false;
        });
    },
    del(scope) {
      this.delFc([scope.row.organ_id]);
    },
    delMore() {
      const delList = [];
      this.multipleSelection.forEach((item) => delList.push(item.organ_id));
      this.delFc(delList);
    },
    delFc(delData) {
      this.$confirm("此操作将永久删除该数据, 是否继续?", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning"
      })
        .then(() => {
          this.$api.organ
            .organ({
              method: "del.organ.list",
              organ_id: delData
            })
            .then((res) => {
              this.$message.success("删除成功！");

              var hasLastPage =
                this.parameter.page_size * this.parameter.page_no >=
                this.parameter.total_result;
              var lastData = this.tableData.length < 2;
              if (lastData && hasLastPage && this.parameter.page_no > 1) {
                this.parameter.page_no--;
              }
              this.getData();
            })
            .catch((err) => {
              console.log(err);
            });
        })
        .catch(() => {
          //用户取消
        });
    },
    changeStatus(scope) {
      const status = scope.row.status == 1 ? 2 : 1;
      this.changeStatusFc(status, [scope.row.organ_id]);
    },
    changeStatusMore(status = 1) {
      const stausList = [];
      this.multipleSelection.forEach((item) => stausList.push(item.organ_id));
      this.changeStatusFc(status, stausList);
    },
    changeStatusFc(status, statusData) {
      this.$confirm(
        `此操作将${status == 1 ? "启用" : "禁用"}该数据, 是否继续?`,
        "提示",
        {
          confirmButtonText: "确定",
          cancelButtonText: "取消",
          type: "warning"
        }
      )
        .then(() => {
          this.$api.organ
            .organ({
              method: "set.organ.status",
              status,
              organ_id: statusData
            })
            .then((res) => {
              this.$message.success(`${status == 1 ? "启用" : "禁用"}成功！`);
              this.getData();
            })
            .catch((err) => {
              console.log(err);
            });
        })
        .catch(() => {
          //用户取消
        });
    },
    toggleSelection(rows) {
      if (rows) {
        rows.forEach((row) => {
          this.$refs.multipleTable.toggleRowSelection(row);
        });
      } else {
        this.$refs.multipleTable.clearSelection();
      }
    },
    handleSelectionChange(val) {
      this.multipleSelection = val;
    }
  }
};
</script>
<style scoped lang="scss">
@import "@/styles/config.scss";

.img_box {
  display: inline-flex;
  justify-content: left;
  align-items: flex-end;

  img {
    width: 30px;
    height: 30px;
    border-radius: 100%;
    margin-right: 5px;
  }
}
</style>
