<template>
  <div>
    <Crumbs></Crumbs>
    <div class="mainList">
      <div class="mainList_operation clearfloat">
        <div class="mainList_operation_search">
          <el-input
            class="item"
            placeholder="请输入账号"
            clearable
            v-model="parameter.account"
          ></el-input>
          <el-select
            clearable
            class="item"
            v-model="parameter.group_id"
            placeholder="请选择角色"
          >
            <el-option
              v-for="item in groupData"
              :key="item.group_id"
              :label="item.name"
              :value="item.group_id"
            >
            </el-option>
          </el-select>
          <el-button
            type="primary"
            plain
            icon="el-icon-search"
            @click="pageChangeHandler(1)"
            >搜索</el-button
          >
        </div>
        <div class="fr">
          <el-button type="primary" icon="el-icon-plus" @click="add"
            >新增</el-button
          >
        </div>
      </div>
      <div class="mainList_content">
        <div class="mainList_content_sub">
          <el-table
            ref="multipleTable"
            border
            :data="tableData"
            tooltip-effect="dark"
            height="string"
            v-loading="loading"
          >
            <el-table-column
              label="序号"
              type="index"
              align="center"
              width="70"
            ></el-table-column>
            <el-table-column
              align="center"
              prop="username"
              label="账号"
            ></el-table-column>
            <el-table-column
              prop="nickname"
              align="center"
              label="昵称"
            ></el-table-column>
            <el-table-column
              prop="get_auth_group.name"
              align="center"
              label="角色"
            ></el-table-column>
            <el-table-column prop="status" align="center" label="状态">
              <template slot-scope="scope">
                {{ scope.row.status | status }}
              </template>
            </el-table-column>
            <el-table-column label="操作" align="center" width="120">
              <template slot-scope="scope">
                <el-button
                  class="danger"
                  type="text"
                  size="small"
                  @click="del(scope)"
                  >删除</el-button
                >
              </template>
            </el-table-column>
          </el-table>
          <el-pagination
            @current-change="pageChangeHandler"
            @size-change="handleSizeChange"
            :current-page="parameter.page_no"
            :page-size="parameter.page_size"
            prev-text="上一页"
            next-text="下一页"
            :total="parameter.total_result"
          >
          </el-pagination>
        </div>
      </div>
    </div>
    <Edit :isShow.sync="showEditDialog" :options="options"></Edit>
  </div>
</template>
<script>
export default {
  components: {
    Edit: () => import('./Edit.vue'),
    Crumbs: () => import('@/components/Crumbs.vue'),
  },
  data() {
    return {
      parameter: {
        method: 'get.admin.list',
        page_size: 50,
      },
      groupData: [],
      options: {},
      tableData: [],
      loading: false,
      showEditDialog: false,
    }
  },
  provide() {
    return {
      refresh: this.refresh,
    }
  },
  watch: {},
  created() {
    this.getData()
    this.$api.role
      .auth_group({
        method: 'get.auth.group.list',
        status: '1',
      })
      .then((res) => {
        this.groupData = res.data
      })
      .catch((err) => {
        console.log(err)
      })
  },
  methods: {
    refresh() {
      this.showEditDialog = false
      this.pageChangeHandler(1)
    },
    pageChangeHandler(val) {
      this.parameter.page_no = val
      this.getData()
    },
    handleSizeChange(val) {
      this.parameter.page_size = val
      this.parameter.page_no = 1
      this.getData()
    },
    add() {
      this.options = {}
      this.showEditDialog = true
    },
    getData() {
      this.loading = true

      this.$api.user
        .admin(this.parameter)
        .then((res) => {
          this.loading = false
          this.tableData = res.data.items
          this.parameter.total_result = res.data.total_result
        })
        .catch((err) => {
          console.log(err)
          this.loading = false
        })
    },
    del(scope) {
      this.$confirm('此操作将永久删除该数据, 是否继续?', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning',
      })
        .then(() => {
          this.$api.user
            .admin({
              module: 'admin',
              method: 'del.admin.list',
              client_id: [scope.row.admin_id],
            })
            .then((res) => {
              this.$message.success('删除成功！')
              var hasLastPage =
                this.parameter.page_size * this.parameter.page_no >=
                this.parameter.total_result
              var lastData = this.tableData.length < 2
              if (lastData && hasLastPage && this.parameter.page_no > 1) {
                this.parameter.page_no--
              }
              this.getData()
            })
            .catch((err) => {
              console.log(err)
            })
        })
        .catch(() => {
          //用户取消
        })
    },
  },
}
</script>
<style scoped lang="scss">
@import '@/styles/config.scss';
</style>
