<template>
  <div>
    <Crumbs></Crumbs>
    <div class="mainList">
      <div class="mainList_operation clearfloat">
        <div class="mainList_operation_search">
          <el-select
            v-model="parameter.status"
            style="margin-right: 15px"
            clearable
          >
            <el-option label="待审核" value="0"> </el-option>
            <el-option label="已通过" value="1"> </el-option>
            <el-option label="已驳回" value="2"> </el-option>
          </el-select>
          <el-date-picker
            clearable
            style="margin-right: 15px"
            v-model="parameter.add_time"
            type="datetimerange"
            range-separator="至"
            start-placeholder="开始日期"
            end-placeholder="结束日期"
          >
          </el-date-picker>
          <el-button
            type="primary"
            plain
            icon="el-icon-search"
            @click="pageChangeHandler(1)"
            >搜索</el-button
          >
        </div>
      </div>
      <div class="mainList_content">
        <div class="mainList_content_sub">
          <el-table
            ref="multipleTable"
            border
            :data="tableData"
            tooltip-effect="dark"
            height="string"
            v-loading="loading"
          >
            <el-table-column
              label="序号"
              type="index"
              align="center"
              width="70"
            ></el-table-column>
            <el-table-column
              align="center"
              prop="user_id"
              label="用户ID"
            ></el-table-column>
            <el-table-column align="center" label="用户名称">
              <template slot-scope="scope">
                <div class="head_pic_box">
                  <img
                    v-if="scope.row.head_pic"
                    class="head_pic"
                    :src="scope.row.head_pic"
                  />
                  <span class="nickname">{{ scope.row.true_name }}</span>
                </div>
              </template>
            </el-table-column>
            <el-table-column align="center" label="企业名称">
              <template slot-scope="scope">
                {{ scope.row.company_name }}
              </template>
            </el-table-column>

            <el-table-column align="center" label="职位">
              <template slot-scope="scope">
                {{ scope.row.cultural_title }}
              </template>
            </el-table-column>
            <el-table-column align="center" label="电话">
              <template slot-scope="scope">
                {{ scope.row.mobile }}
              </template>
            </el-table-column>
            <el-table-column align="center" label="邮箱">
              <template slot-scope="scope">
                {{ scope.row.email }}
              </template>
            </el-table-column>
            <el-table-column align="center" label="简介">
              <template slot-scope="scope">
                {{ scope.row.remarks }}
              </template>
            </el-table-column>
            <el-table-column align="center" label="状态">
              <template slot-scope="scope">
                {{ statusMap[scope.row.status] }}
              </template>
            </el-table-column>
            <el-table-column label="操作" align="center" width="200">
              <template slot-scope="scope">
                <template>
                  <el-button
                    v-if="scope.row.status == 0"
                    type="text"
                    size="small"
                    @click="changeStatus(scope.row, 2)"
                    >通过</el-button
                  >
                  <el-button
                    v-if="scope.row.status == 0"
                    type="text"
                    size="small"
                    @click="changeStatus(scope.row, 1)"
                    >拒绝</el-button
                  >
                </template>
              </template>
            </el-table-column>
          </el-table>
          <el-pagination
            @size-change="handleSizeChange"
            @current-change="pageChangeHandler"
            :current-page.sync="parameter.page_no"
            :page-size="parameter.page_size"
            prev-text="上一页"
            next-text="下一页"
            :total="parameter.total_result"
          >
          </el-pagination>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  components: {
    Crumbs: () => import("@/components/Crumbs.vue")
  },
  data() {
    return {
      statusData: [
        {
          status_id: 0,
          name: "失败"
        },
        {
          status_id: 1,
          name: "成功"
        }
      ],
      statusMap: {
        0: "审核中",
        1: "已通过",
        2: "已驳回"
      },
      logDaterange: "",
      parameter: {
        add_time: [],
        status: undefined,
        method: "get.user_real.list",
        page_no: 1,
        page_size: 100,
        total_result: 0
      },
      tableData: [],
      loading: false,
      typeList: [
        {
          id: 1,
          name: "个人认证"
        },
        {
          id: 2,
          name: "讲师认证"
        },
        {
          id: 3,
          name: "企业认证"
        }
      ]
    };
  },
  provide() {
    return {
      refresh: this.refresh
    };
  },
  watch: {},
  created() {
    this.getData();
  },
  methods: {
    refresh() {
      this.showEditDialog = false;
      this.pageChangeHandler(1);
    },
    pageChangeHandler(val) {
      this.parameter.page_no = val;
      this.getData();
    },
    handleSizeChange(val) {
      this.parameter.page_size = val;
      this.parameter.page_no = 1;
      this.getData();
    },
    daterangeFc(val) {
      if (!val) return;
      this.parameter.begin_time = val[0];
      this.parameter.end_time = val[1];
    },
    getData() {
      this.loading = true;
      this.$api.user
        .user_real_list(this.parameter)
        .then((res) => {
          this.loading = false;
          this.tableData = res.data.items;
          this.parameter.total_result = res.data.total_result;
        })
        .catch((err) => {
          console.log(err);
          this.loading = false;
        });
    },
    changeStatus(row, type) {
      console.log("row:", row);
      if (type === 2) {
        // 通过
        this.$confirm("确定要通过该认证申请吗？", "确认通过吗？", {
          callback: (action) => {
            if (action === "confirm") {
              this.$api.user
                .user_real_status({
                  method: "set.user_real.status",
                  status: 1,
                  user_real_id: row.user_real_id,
                  user_id: row.user_id
                })
                .then((res) => {
                  this.refresh();
                });
            }
          }
        });
      } else {
        // 拒绝
        this.$confirm("", "确认拒绝吗？", {
          showInput: true,
          inputPlaceholder: "请输入拒绝的原因",
          inputValue: "",
          callback: (action, instance) => {
            console.log(action);
            console.log(instance.inputValue);
            if (action === "confirm") {
              // if (!instance.inputValue) {
              //   return this.$message.error("拒绝原因不能为空");
              // }
              this.$api.user
                .user_real_status({
                  method: "set.user_real.status",
                  status: 2,
                  user_real_id: row.user_real_id,
                  user_id: row.user_id,
                  refusal_remarks: instance.inputValue
                })
                .then((res) => {
                  this.refresh();
                });
            }
          }
        });
      }
    }
  }
};
</script>
<style scoped lang="scss">
@import "@/styles/config.scss";
.head_pic_box {
  display: flex;
  justify-content: left;
  align-items: center;
  img {
    width: 30px;
    height: 30px;
    border-radius: 100%;
    margin-right: 5px;
  }
}
</style>
