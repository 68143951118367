<template>

  <el-submenu :index="menuObj.id" :popper-append-to-body="false">
    <template slot="title">{{  menuObj.name  }}</template>
    <template v-for="(item,index) in menuObj.children">

      <template v-if="item.isShow">
        <template v-if="item.children&&item.children.length>0">
          <HorizontalMenuEleItem :menuObj="item"></HorizontalMenuEleItem>
        </template>
        <template v-else>
          <el-menu-item v-if="item.linkTo||item.href" :index="item.marking" :key="item.marking" @click.native="jump(item)">{{  item.name }}</el-menu-item>
        </template>
      </template>

    </template>

  </el-submenu>

</template>

<script>
export default {
  name: "HorizontalMenuEleItem",
  components:{
  },
  props:{
    menuObj:{
      type:Object,
      default:function(){
        return {}
      }
    }
  },
  data () {
    return {

    }
  },
  methods:{
    jump(item){
      var path = item.linkTo?item.linkTo:item.url;
      this.$router.push(path);
    }
  }
}
</script>
