<template>
  <el-dialog class="editDialog" :append-to-body="true" width="600px" :visible.sync="showViewReplyDialog"
    :close-on-click-modal="false" :destroy-on-close="true" @close="$emit('update:isShow', false)" :show-close="true">
    <el-row slot="title" align="middle" justify="space-between" type="flex">
      <el-col>
        <div class="title">查看回复</div>
      </el-col>
      <el-col :span="7" v-if="checkList.length">
        <el-button type="text" @click="delMore">批量删除</el-button>
      </el-col>
    </el-row>
    <div class="content">
      <div slot="header" class="clearfix">
        <ReplyCard :reply-data="options"></ReplyCard>
      </div>
      <el-divider content-position="left">回复列表</el-divider>
      <div v-if="options.get_user_moment_log && options.get_user_moment_log.length" class="reply_list">
        <el-checkbox-group v-model="checkList">
          <div style="margin-bottom: 20px" v-for="moment in options.get_user_moment_log" :key="moment.user_moment_log_id"
            class="text item">
            <el-checkbox :label="moment.user_moment_log_id">
              <ReplyCard :reply-data="moment" :if-delete="true"></ReplyCard>
            </el-checkbox>
            <div>
              <el-button type="text" @click="delMomentLog([moment.user_moment_log_id])">删除</el-button>
              <template v-if="moment.status == 0">
                <el-button type="text" @click="setStatus(moment, 1)">审核通过</el-button>
                <el-button type="text" @click="statusChange(moment, 2)">驳回</el-button>
              </template>
              <el-button v-else type="text" disabled>{{
                moment.status == 2 ? '已驳回' : ''
              }}</el-button>
            </div>
          </div>
        </el-checkbox-group>
      </div>
      <template v-else>暂无回复</template>
    </div>
  </el-dialog>
</template>
<script>
export default {
  components: {
    ReplyCard: () => import('@/components/ReplyCard.vue'),
  },
  props: {
    isShow: {
      type: Boolean,
      default: false,
    },
    options: {
      type: Object,
      default: function () {
        return {}
      },
    },
  },
  data() {
    return {
      showViewReplyDialog: false,
      loading: true,
      checkList: [],
    }
  },
  inject: ['refresh'],
  watch: {
    isShow: {
      immediate: true, //初始化立即执行
      handler: function (newVal) {
        this.showViewReplyDialog = newVal
      },
    },
  },
  methods: {
    setStatus(item, status, remarks = '') {
      this.loading = true
      this.$api.user
        .set_user_moment_status({
          method: 'set.user_moment_log.status',
          user_moment_log_id: [item.user_moment_log_id],
          status,
          remarks,
        })
        .then((res) => {
          this.loading = false
          this.refresh()
        })
        .catch((err) => {
          console.log(err)
          this.loading = false
        })
    },
    statusChange(item, status) {
      this.$prompt('请输入', '请输入驳回原因', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
      }).then(async ({ value }) => {
        this.setStatus(item, status, value)
      })
    },
    delMore() {
      this.delMomentLog(this.checkList)
    },
    delMomentLog(user_moment_log_ids) {
      this.$confirm('此操作将永久删除该数据, 是否继续?', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning',
      })
        .then(() => {
          this.$api.user
            .del_user_moment_log({
              method: 'del.user_moment_log.list',
              user_moment_log_id: user_moment_log_ids,
            })
            .then((res) => {
              this.loading = false
              this.refresh()
            })
            .catch((err) => {
              console.log(err)
              this.loading = false
            })
        })
        .catch((error) => {
          console.log(error)
        })
    },
  },
}
</script>
<style scoped lang="scss">
.content {
  padding: 0 20px;

  .text {
    width: 100%;
  }

  .reply_list {
    max-height: 400px;
    overflow-y: auto;
  }
}
</style>
