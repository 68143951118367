<template>
    <el-dialog
        class="editDialog"
        :append-to-body="true"
        width="600px"
        :visible.sync="showEditDialog"
        :close-on-click-modal="false"
        :destroy-on-close="true"
        @close="$emit('update:isShow', false)"
        :show-close="true"

    >

        <div slot="title" class="title">角色授权</div>

        <div class="content" v-loading="loading">
            <el-alert title="勾选菜单需勾选到最末级" type="warning"></el-alert>
            <el-tree
                ref="tree"
                show-checkbox
                node-key="menu_id"
                :data="allTreeData"
                :default-checked-keys="selectedTreeData"
                :props="{children: 'children',label: 'name'}"
                :default-expand-all="true"
                :check-strictly="true">
                    <span slot-scope="{ node, data }">
                      {{ node.label }} <span v-if="data.nickname" style="opacity:.7;">({{ data.nickname }})</span>
                    </span>
            </el-tree>
        </div>

        <div slot="footer" v-show="!loading">
            <el-button type="success" :loading="submitLoading" @click="submit">保存</el-button>
            <el-button type="info" plain @click="$emit('update:isShow', false)">取消</el-button>
        </div>

    </el-dialog>

</template>
<script>

import {initTreeData} from '@/util/common'
export default {
    components: {
        Treeselect: () => import('@riophae/vue-treeselect'),
    },
    props: {
        isShow: {
            type: Boolean,
            default: false
        },
        options: {
            type: Object,
            default:function(){
                return {}
            }
        },
    },
    data() {
        return {
            showEditDialog: false,
            loading: true,
            submitLoading: false,
            rule_id:null,
            allTreeData: [],
            selectedTreeData: [],
        };
    },
    inject:['refresh'],
    watch: {
        isShow: {
            immediate: true,//初始化立即执行
            handler: function (newVal) {
                this.showEditDialog = newVal;
                this.init();
            }
        },
        options:{
            immediate:true,//初始化立即执行
            deep: true,//对象深度监测
            handler: function (newVal,oldVal){

            }
        }
    },
    created() {

    },
    methods: {
        init(){
            this.loading = true;
            this.selectedTreeData = [];

            console.log(0)
            Promise.all([
                this.$api.menu.menu({
                    method: 'get.menu.list',
                    module: 'admin',
                }),
                this.$api.role.auth_rule({
                    method: 'get.auth.rule.list',
                    module: 'admin',
                    group_id: this.options.group_id,
                }),
            ]).then((r) => {
                this.allTreeData = initTreeData(r[0].data,'parent_id','menu_id');


                if(r[1].data&&Array.isArray(r[1].data)&&r[1].data.length>0){
                    this.rule_id = r[1].data[0].rule_id;
                    r[1].data[0].menu_auth.some(r=>{
                        this.selectedTreeData.push(r);
                    })
                }
                this.loading = false;
            }).catch(err => {
                this.loading = false;
                console.log(err);
            })

        },
        submit(){
            this.submitLoading = true;

            var params = {
                name:`${this.options.name}权限规则`,
                menu_auth: [],
                group_id:this.options.group_id,
                method:'add.auth.rule.item',
                module:'admin',
            };
            if(this.rule_id){
                params.rule_id = this.rule_id;
                params.method = 'set.auth.rule.item';
            }
            this.$refs.tree.getCheckedNodes().forEach(item=>{
                params.menu_auth.push(item.menu_id);
            });
            this.$api.role.auth_rule(params).then( res => {
                this.submitLoading = false;
                this.refresh();
                this.$message.success("操作成功！");

            }).catch(err=>{
                this.submitLoading = false;
                console.log(err);
            })
        },

    },
};
</script>
