<template>
  <label @click="input.click()">
    <slot name="button">
      <i class="iconfont iconadd-circle"></i>
    </slot>
  </label>
</template>

<script>
import Axios from '@/axios'
export default {
  props: {
    accept: {
      type: String,
      default: 'image/*',
    },
    multiple: {
      type: Boolean,
      default: false,
    },
  },
  components: {},
  data() {
    return {}
  },
  computed: {},
  watch: {},
  mounted() {
    this.input = document.createElement('input')
    this.input.setAttribute('type', 'file')
    if (this.multiple) {
      this.input.setAttribute('multiple', 'multiple')
    }
    if (this.accept) {
      this.input.setAttribute('accept', this.accept)
    }
    this.input.addEventListener('change', (e) => {
      var files = e.target.files

      var arr = []

      for (let i = 0; i < files.length; i++) {
        var form = new FormData()
        form.append('file', files[i])
        form.append('method', 'set.upload.figure')
        Axios({
          url: '/adminapi/v1/upload',
          method: 'post',
          data: form,
          headers: {
            accept: '*/*',
          },
          transformRequest: [
            function (data) {
              return data
            },
          ],
        })
          .then((res) => {
            //setTimeout(()=>{
            arr[i].loading = false
            arr[i].fileUrl = res.data.url
            //},10000)
          })
          .catch((err) => {
            this.input.value = ''
          })
        arr.push({
          fileName: files[i].name,
          loading: true,
          fileUrl: '',
          type: this.type,
        })
      }

      this.$emit('upload', arr)
      this.input.value = ''
    })
  },
  methods: {},
}
</script>

<style lang="scss" scoped></style>
