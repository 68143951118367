<template>
  <div>
    <Crumbs></Crumbs>
    <div class="mainList">
      <div class="mainList_operation clearfloat">
        <div class="mainList_operation_search">
          <el-input
            class="item"
            placeholder="请输入名称"
            clearable
            v-model="parameter.keywords"
          ></el-input>
          <el-select class="item" v-model="parameter.is_hot">
            <el-option label="请选择" value=""></el-option>
            <el-option label="否" value="0"></el-option>
            <el-option label="是" value="1"></el-option>
          </el-select>
          <el-button
            type="primary"
            plain
            icon="el-icon-search"
            @click="pageChangeHandler(1)"
            >搜索</el-button
          >
        </div>
        <div class="fr">
          <el-button type="primary" icon="el-icon-plus" @click="add"
            >新增</el-button
          >

          <template v-if="selectRows.length > 0">
            <el-button type="danger" @click="handleDelete">批量删除</el-button>
          </template>
        </div>
      </div>
      <div class="mainList_content">
        <div class="mainList_content_sub">
          <el-table
            ref="multipleTable"
            border
            :data="tableData"
            tooltip-effect="dark"
            height="string"
            v-loading="loading"
            @selection-change="setSelectRows"
          >
            <el-table-column
              align="center"
              show-overflow-tooltip
              type="selection"
            ></el-table-column>
            <el-table-column
              label="序号"
              type="index"
              align="center"
              width="70"
            ></el-table-column>
            <el-table-column
              align="center"
              prop="title"
              label="岗位名称"
            ></el-table-column>
            <el-table-column
              align="center"
              prop="sort"
              label="排序"
            ></el-table-column>
            <el-table-column align="center" prop="is_hot" label="是否热门">
              <template slot-scope="scope">
                {{ scope.row.is_hot | isHot }}
              </template>
            </el-table-column>
            <el-table-column
              align="center"
              prop="create_time"
              label="创建时间"
            ></el-table-column>

            <el-table-column
              align="center"
              prop="update_time"
              label="更新时间"
            ></el-table-column>

            <el-table-column
              label="操作"
              align="center"
              width="160"
              fixed="right"
            >
              <template slot-scope="scope">
                <el-button type="text" size="small" @click="edit(scope)"
                  >编辑</el-button
                >
                <el-button
                  type="text"
                  size="small"
                  @click="handleDelete(scope.row)"
                  >删除</el-button
                >
              </template>
            </el-table-column>
          </el-table>
          <el-pagination
            @size-change="handleSizeChange"
            @current-change="pageChangeHandler"
            :current-page.sync="parameter.page_no"
            :page-size="parameter.page_size"
            prev-text="上一页"
            next-text="下一页"
            :total="parameter.total_result"
          >
          </el-pagination>
        </div>
      </div>
    </div>
    <Edit :isShow.sync="showEditDialog" :options="options"></Edit>
  </div>
</template>
<script>
export default {
  components: {
    Edit: () => import('./Edit.vue'),
    Crumbs: () => import('@/components/Crumbs.vue'),
  },
  data() {
    return {
      parameter: {
        method: 'get.position.list',
        page_no: 1,
        page_size: 50,
        total_result: 0,
        keywords: '',
        is_hot: '',
      },
      selectRows: [],
      activeData: {},
      options: {},
      tableData: [],
      loading: false,
      showEditDialog: false,
    }
  },
  provide() {
    return {
      refresh: this.refresh,
    }
  },
  watch: {},
  created() {
    this.getData()
  },
  filters: {
    isHot(val) {
      console.log(val)
      return val == 0 ? '否' : '是'
    },
  },
  methods: {
    setSelectRows(val) {
      this.selectRows = val
    },
    refresh() {
      this.showEditDialog = false
      this.pageChangeHandler(1)
    },
    pageChangeHandler(val) {
      this.parameter.page_no = val
      this.getData()
    },
    handleSizeChange(val) {
      this.parameter.page_size = val
      this.parameter.page_no = 1
      this.getData()
    },
    edit(scope) {
      this.options = scope.row
      this.showEditDialog = true
    },
    add() {
      this.options = {}
      this.showEditDialog = true
    },
    getData() {
      this.loading = true
      this.$api.position
        .position(this.parameter)
        .then((res) => {
          this.loading = false
          console.log(res.data)
          if (res.data.items && Array.isArray(res.data.items)) {
            this.tableData = res.data.items
          } else {
            this.tableData = []
          }
          this.parameter.total_result = res.data.total_result
        })
        .catch((err) => {
          console.log(err)
          this.loading = false
        })
    },

    handleDelete(row) {
      const ids = []
      if (row.position_id) {
        ids.push(row.position_id)
      } else {
        if (this.selectRows.length > 0) {
          const itemIds = this.selectRows.map((item) => item.position_id)
          for (var i = 0; i < itemIds.length; i++) {
            ids.push(itemIds[i])
          }
        } else {
          this.$message.error('未选中任何行')
          return false
        }
      }
      console.log(ids)
      this.$confirm('此操作将永久删除该数据, 是否继续?', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning',
      })
        .then(() => {
          this.$api.position
            .position({
              module: 'admin',
              method: 'del.position.list',
              position_id: ids,
            })
            .then((res) => {
              if (res.status == 200) {
                this.$message.success('删除成功！')
                var hasLastPage =
                  this.parameter.page_size * this.parameter.page_no >=
                  this.parameter.total_result
                var lastData = this.tableData.length < 2
                if (lastData && hasLastPage && this.parameter.page_no > 1) {
                  this.parameter.page_no--
                }
                this.getData()
              }
            })
            .catch((err) => {
              console.log(err)
            })
        })
        .catch(() => {
          //用户取消
        })
    },
  },
}
</script>
<style scoped lang="scss">
@import '@/styles/config.scss';
</style>
