<template>
    <el-dialog class="editDialog" :append-to-body="true" width="600px" :visible.sync="showEditDialog"
        :close-on-click-modal="false" :destroy-on-close="true" @close="$emit('update:isShow', false)"
        :show-close="true">

        <div slot="title" class="title">{{ options.admin_id ? '编辑' : '新增' }}管理员</div>

        <div class="content" v-loading="loading">
            <el-form label-width="6em" :model="form" :rules="rules" ref="form">

                <el-form-item label="昵称" prop="nickname" ref="nickname">
                    <el-input v-model="form.nickname" placeholder="请输入昵称"></el-input>
                </el-form-item>

                <el-form-item label="账号" prop="username" ref="username">
                    <el-input v-model="form.username" placeholder="请输入账号"></el-input>
                </el-form-item>

                <el-form-item label="角色" prop="group_id" ref="group_id">
                    <el-select v-model="form.group_id" placeholder="请选择角色">
                        <el-option v-for="item in groupData" :key="item.group_id" :label="item.name"
                            :value="item.group_id">
                        </el-option>
                    </el-select>

                </el-form-item>

                <el-form-item label="是否启用" prop="status">
                    <el-radio-group v-model="form.status" size="medium">
                        <el-radio :label="1">启用</el-radio>
                        <el-radio :label="0">禁用</el-radio>
                    </el-radio-group>
                </el-form-item>


                <el-form-item label="密码" prop="password" ref="password">
                    <el-input type="password" v-model="form.password" placeholder="请输入密码"></el-input>
                </el-form-item>

                <el-form-item label="确认密码" prop="password_confirm" ref="password_confirm">
                    <el-input type="password" v-model="form.password_confirm" placeholder="请再次输入密码"></el-input>
                </el-form-item>

            </el-form>
        </div>

        <div slot="footer" v-show="!loading">
            <el-button type="success" :loading="submitLoading" @click="submit">保存</el-button>
            <el-button type="info" plain @click="$emit('update:isShow', false)">取消</el-button>
        </div>

    </el-dialog>

</template>
<script>

export default {
    props: {
        isShow: {
            type: Boolean,
            default: false
        },
        options: {
            type: Object,
            default: function () {
                return {}
            }
        },
    },
    data() {
        return {
            showEditDialog: false,
            loading: true,
            submitLoading: false,
            form: {},
            groupData: [],
            rules: {
                nickname: [{ required: true, message: '请输入昵称', trigger: ['blur', 'change'] }],
                username: [{ required: true, message: '请输入账号', trigger: ['blur', 'change'] }],
                group_id: [{ required: true, message: '请选择角色', trigger: ['change'] }],
                password: [{ required: true, message: '请输入密码', trigger: ['blur', 'change'] }],
                password_confirm: [
                    { required: true, message: '请再次输入密码', trigger: ['blur', 'change'] },
                    {
                        trigger: ['change', 'blur'],
                        validator: (rule, value, callback) => {
                            if (value != this.form.password) {
                                callback(new Error('两次输入的密码不一致'));
                            };
                            callback();
                        }
                    }
                ],

            },
        };
    },
    inject: ['refresh'],
    watch: {
        isShow: {
            immediate: true,//初始化立即执行
            handler: function (newVal) {
                // this.$emit('update:loading', false);
                this.showEditDialog = newVal;
            }
        },
        options: {
            immediate: true,//初始化立即执行
            deep: true,//对象深度监测
            handler: function (newVal, oldVal) {
                this.init(newVal);
            }
        }
    },
    created() {
        this.$api.role.auth_group({
            method: 'get.auth.group.list',
            status: '1',
        }).then(res => {
            this.groupData = res.data
        }).catch(err => {
            console.log(err);
        });
    },
    methods: {
        init(options) {
            if (options.admin_id) {//编辑进来的会有id需要

            } else {
                this.form = {
                    status: 1
                };
                this.loading = false;
            };
        },
        submit() {
            this.$refs.form.validate((valid, obj) => {
                if (valid) {

                    this.submitLoading = true;
                    this.$api.user.admin(this.form.combination({
                        module: 'admin',
                        method: this.form.admin_id ? 'set.admin.item' : 'add.admin.item',
                    })).then(res => {
                        this.submitLoading = false;
                        this.refresh(1);
                        this.$message.success("操作成功！");

                    }).catch(err => {
                        this.submitLoading = false;
                        console.log(err);
                    })
                } else {
                    this.scrollView(obj)
                };
            });
        },
    },
};
</script>
