<template>
  <div class="delivery" v-loading="loading">
    <div class="con">
      <el-form class="" label-width="7em" :model="form" :rules="rules" ref="form">
        <el-form-item prop="oss" label="oss">
          <el-input v-model="form.oss" :placeholder="`请输入`"></el-input>
        </el-form-item>
      </el-form>
    </div>
    <div class="operation">
      <el-button type="success" :loading="submitLoading" @click="submit">保存</el-button>
    </div>
  </div>
</template>
<script>
import * as setting from '../../../api/setting'

export default {
  components: {},
  data() {
    return {
      loading: true,
      submitLoading: false,
      form: {},
      rules: {},
    }
  },
  created() {
    this.loading = true
    this.$api.setting
      .setting({
        method: 'get.setting.list',
        module: 'upload',
      })
      .then((res) => {
        this.form = {
          oss: res.data.oss.value,
        }
        this.loading = false
        console.log(res)
      })
      .catch((err) => {
        console.log(err)
        this.loading = false
      })
  },
  methods: {
    submit() {
      this.$refs.form.validate((valid, obj) => {
        if (valid) {
          this.submitLoading = true
          this.$api.setting
            .setting({
              method: 'set.upload.list',
              'data[oss]': this.form.oss,
            })
            .then((res) => {
              this.submitLoading = false
              this.$message.success('操作成功！')
            })
            .catch((err) => {
              console.log(err)
              this.submitLoading = false
            })
        } else {
          this.submitLoading = false
          this.scrollView(obj)
        }
      })
    },
  },
}
</script>
<style scoped lang="scss">
@import '@/styles/config.scss';

.delivery {
  height: 100%;
  overflow: auto;

  .con {
    padding: 20px 0;
    min-height: calc(100% - 80px);
  }

  .operation {
    display: block;
    position: sticky;
    bottom: 0;
    background: #fff;
    padding: 20px;
    text-align: center;
    width: 100%;

    .el-button {
      min-width: 120px;
      margin: 0 25px;
    }
  }
}
</style>
