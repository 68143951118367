<template>
  <div style="height: 100%">
    <!-- <Crumbs></Crumbs> -->
    <div class="mainList">
      <div class="mainList_operation clearfloat">
        <div class="mainList_operation_search">
          <el-input
            class="item"
            placeholder="请输入关键字"
            clearable
            v-model="parameter.keywords"
          ></el-input>
          <!-- <el-select
            class="mr10"
            clearable
            v-model="parameter.course_id"
            placeholder="请选择课程"
          >
            <el-option
              v-for="item in courseData"
              :key="item.course_id"
              :label="item.title"
              :value="item.course_id"
            >
            </el-option>
          </el-select> -->
          <el-button
            type="primary"
            plain
            icon="el-icon-search"
            @click="pageChangeHandler(1)"
            >搜索</el-button
          >
        </div>
        <div class="fr">
          <el-button type="primary" icon="el-icon-plus" @click="add"
            >新增</el-button
          >
          <template v-if="multipleSelection.length">
            <el-button type="danger" @click="delMore">批量删除</el-button>
            <el-button @click="toggleSelection()">取消选择</el-button>
          </template>
        </div>
      </div>
      <div class="mainList_content">
        <div class="mainList_content_sub">
          <el-table
            ref="multipleTable"
            border
            :data="tableData"
            height="string"
            v-loading="loading"
            @selection-change="handleSelectionChange"
          >
            <el-table-column
              align="center"
              type="selection"
              width="55"
            ></el-table-column>
            <el-table-column
              label="序号"
              type="index"
              align="center"
              width="70"
            ></el-table-column>
            <el-table-column align="center" prop="video_title" label="标题">
              <template slot-scope="scope">
                <a :href="scope.row.video_url" target="_blank">{{
                  scope.row.video_title
                }}</a>
              </template>
            </el-table-column>
            <el-table-column
              align="center"
              prop="video_url"
              label="封面"
              min-width="150px"
            >
              <template slot-scope="scope">
                <div class="cat_pic_box">
                  <img v-if="scope.row.video_url" :src="scope.row.video_url" />
                </div>
              </template>
            </el-table-column>
            <el-table-column
              align="center"
              prop="price"
              label="折扣价"
            ></el-table-column>
            <el-table-column
              align="center"
              prop="scribe_price"
              label="价格"
            ></el-table-column>
            <el-table-column align="center" prop="is_top" label="置顶">
              <template slot-scope="scope">
                {{ scope.row.is_top == 1 ? "是" : "否" }}
              </template></el-table-column
            >
            <el-table-column
              align="center"
              prop="sort"
              label="排序"
            ></el-table-column>
            <el-table-column
              align="center"
              prop="update_time"
              label="更新时间"
              width="180px"
            ></el-table-column>
            <el-table-column label="操作" align="center" width="120">
              <template slot-scope="scope">
                <el-button type="text" size="small" @click="edit(scope)"
                  >编辑</el-button
                >
                <el-button
                  class="danger"
                  type="text"
                  size="small"
                  @click="del(scope)"
                  >删除</el-button
                >
              </template>
            </el-table-column>
          </el-table>
          <el-pagination
            @size-change="handleSizeChange"
            @current-change="pageChangeHandler"
            :current-page.sync="parameter.page_no"
            :page-size="parameter.page_size"
            prev-text="上一页"
            next-text="下一页"
            :total="parameter.total_result"
          >
          </el-pagination>
        </div>
      </div>
    </div>
    <Edit :isShow.sync="showEditDialog" :options="options"></Edit>
  </div>
</template>
<script>
export default {
  components: {
    Edit: () => import("./Edit.vue"),
    Crumbs: () => import("@/components/Crumbs.vue")
  },
  data() {
    return {
      parameter: {
        method: "get.course_video.list",
        page_no: 1,
        page_size: 50,
        total_result: 0
      },
      options: {},
      tableData: [],
      loading: false,
      showEditDialog: false,
      multipleSelection: [],
      courseData: []
    };
  },
  provide() {
    return {
      refresh: this.refresh
    };
  },
  watch: {},
  created() {
    // this.getCourseData();
    this.getData();
  },
  methods: {
    refresh() {
      this.showEditDialog = false;
      this.pageChangeHandler(1);
    },
    pageChangeHandler(val) {
      this.parameter.page_no = val;
      this.getData();
    },
    handleSizeChange(val) {
      this.parameter.page_size = val;
      this.parameter.page_no = 1;
      this.getData();
    },
    edit(scope) {
      this.options = scope.row;
      this.showEditDialog = true;
    },
    add() {
      this.options = {};
      this.showEditDialog = true;
    },
    getData() {
      this.loading = true;
      this.$api.course
        .course_video(this.parameter)
        .then((res) => {
          this.loading = false;
          this.tableData = res.data.items;
          this.parameter.total_result = res.data.total_result;
        })
        .catch((err) => {
          console.log(err);
          this.loading = false;
        });
    },
    del(scope) {
      this.delFc([scope.row.course_video_id]);
    },
    delMore() {
      const delList = [];
      this.multipleSelection.forEach((item) =>
        delList.push(item.course_video_id)
      );
      this.delFc(delList);
    },
    delFc(delData) {
      this.$confirm("此操作将永久删除该数据, 是否继续?", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning"
      })
        .then(() => {
          this.$api.course
            .course_video({
              method: "del.course_video.list",
              course_video_id: delData
            })
            .then((res) => {
              this.$message.success("删除成功！");

              var hasLastPage =
                this.parameter.page_size * this.parameter.page_no >=
                this.parameter.total_result;
              var lastData = this.tableData.length < 2;
              if (lastData && hasLastPage && this.parameter.page_no > 1) {
                this.parameter.page_no--;
              }
              this.getData();
            })
            .catch((err) => {
              console.log(err);
            });
        })
        .catch(() => {
          //用户取消
        });
    },
    toggleSelection(rows) {
      if (rows) {
        rows.forEach((row) => {
          this.$refs.multipleTable.toggleRowSelection(row);
        });
      } else {
        this.$refs.multipleTable.clearSelection();
      }
    },
    handleSelectionChange(val) {
      this.multipleSelection = val;
    }
    // getCourseData() {
    //   this.$api.course
    //     .course({
    //       method: "get.course.select"
    //     })
    //     .then((res) => {
    //       this.courseData = res.data.items;
    //     })
    //     .catch((err) => {
    //       console.log(err);
    //     });
    // }
  }
};
</script>
<style scoped lang='scss'>
@import "@/styles/config.scss";
.video {
  width: 140px;
}

.cat_pic_box {
  display: inline-flex;
  justify-content: left;
  align-items: baseline;

  img {
    width: 90px;
    height: 60px;
    // border-radius: 100%;
    // margin-right: 5px;
  }
}
</style>
