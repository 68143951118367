<template>
    <div>
        <Crumbs></Crumbs>
        <div class="mainList">
            <div class="mainList_operation clearfloat">
                <div class="fr">
                    <el-button type="primary" icon="el-icon-plus" @click="add()">新增</el-button>
                    <template v-if="multipleSelection.length">
                        <el-button type="danger" @click="delMore">批量删除</el-button>
                        <el-button @click="changeStatusMore()">批量启用</el-button>
                        <el-button @click="changeStatusMore(2)">批量禁用</el-button>
                        <el-button @click="toggleSelection()">取消选择</el-button>
                    </template>
                </div>
            </div>
            <div class="mainList_content">
                <div class="mainList_content_sub">
                    <el-table ref="multipleTable" row-key="course_cat_id" border :data="tableData"
                        :tree-props="{ children: 'children', hasChildren: 'hasChildren' }" tooltip-effect="dark"
                        height="string" :default-expand-all="false" v-loading="loading"
                        @selection-change="handleSelectionChange">
                        <el-table-column align="center" type="selection" width="55">
                        </el-table-column>
                        <el-table-column prop="cat_name" align="left" label="分类名称">
                            <template slot-scope="scope">
                                <div class="cat_pic_box">
                                    <i v-if="scope.row.category_pic" :class="scope.row.category_pic"
                                        class="iconfont"></i>
                                    <span>{{ scope.row.cat_name }}</span>
                                </div>
                            </template>
                        </el-table-column>
                        <el-table-column prop="description" align="center" label="描述"></el-table-column>
                        <el-table-column prop="sort" align="center" label="排序"></el-table-column>
                        <el-table-column prop="status" align="center" label="是否禁用">
                            <template slot-scope="scope">
                                {{ scope.row.status | status }}
                            </template>
                        </el-table-column>
                        <el-table-column label="操作" align="center" width="180">
                            <template slot-scope="scope">
                                <el-button type="text" size="small" @click="add(scope.row.course_cat_id)">
                                    添加下级</el-button>
                                <el-button type="text" size="small" @click="edit(scope)">编辑</el-button>
                                <el-button type="text" v-if="scope.row.status == 1" size="small"
                                    @click="changeStatus(scope)">禁用</el-button>
                                <el-button type="text" v-else size="small" @click="changeStatus(scope)">启用</el-button>
                                <el-button type="text" size="small" class="danger" @click="del(scope)"> 删除</el-button>
                            </template>
                        </el-table-column>
                    </el-table>
                    <!-- <el-pagination
                        @size-change="handleSizeChange"
                        @current-change="pageChangeHandler"
                        :current-page.sync="parameter.page_no"
                        :page-size="parameter.page_size"
                        prev-text="上一页"
                        next-text="下一页"
                        :total="parameter.total_result">
                    </el-pagination> -->
                </div>
            </div>
        </div>
        <Edit :isShow.sync="showEditDialog" :options="options" :catTreeData="tableData"></Edit>

    </div>
</template>
<script>

import { initTreeData } from '@/util/common'
export default {
    components: {
        Edit: () => import("./Edit.vue"),
        Crumbs: () => import("@/components/Crumbs.vue"),
    },
    data() {
        return {
            parameter: {
                method: 'get.course_cat.list',
                // page_no:1,
                // page_size:50,
                // total_result:0,
            },
            form: {
                parent_id: 0,
                status: 1,
            },
            tableData: [],
            options: {},
            showEditDialog: false,
            multipleSelection: []
        };
    },
    provide() {
        return {
            refresh: this.refresh,
        }
    },
    created() {
        this.getData();
    },
    methods: {
        refresh() {
            this.showEditDialog = false;
            this.getData();
        },
        pageChangeHandler(val) {
            this.parameter.page_no = val;
            this.getData();
        },
        handleSizeChange(val) {
            this.parameter.page_size = val;
            this.parameter.page_no = 1;
            this.getData();
        },
        add(parent_id = 0) {
            this.options = { parent_id };
            this.showEditDialog = true;
        },
        edit(scope) {
            this.options = scope.row;
            this.showEditDialog = true;
        },
        getData() {
            this.loading = true;
            this.$api.course.course_cat(this.parameter).then(res => {
                this.loading = false;
                this.listData = res.data;
                this.tableData = initTreeData(res.data.items, 'parent_id', 'course_cat_id')
            }).catch(err => {
                console.log(err);
                this.loading = false;
            });
        },
        del(scope) {
            this.delFc([scope.row.course_cat_id])
        },
        delMore() {
            const delList = []
            this.multipleSelection.forEach(item => delList.push(item.course_cat_id))
            this.delFc(delList)
        },
        delFc(delData) {
            this.$confirm("此操作将永久删除该数据, 是否继续?", "提示", {
                confirmButtonText: "确定",
                cancelButtonText: "取消",
                type: "warning"
            }).then(() => {
                this.$api.course.course_cat({
                    method: 'del.course_cat.list',
                    course_cat_id: delData
                }).then(res => {
                    this.$message.success("删除成功！");
                    this.getData();
                }).catch(err => {
                    console.log(err);
                });
            }).catch(() => {//用户取消

            });
        },
        changeStatus(scope) {
            const status = scope.row.status == 1 ? 2 : 1
            this.changeStatusFc(status, [scope.row.course_cat_id])
        },
        changeStatusMore(status = 1) {
            const stausList = []
            this.multipleSelection.forEach(item => stausList.push(item.course_cat_id))
            this.changeStatusFc(status, stausList)
        },
        changeStatusFc(status, statusData) {
            this.$confirm(`此操作将${status == 1 ? '启用' : '禁用'}该数据, 是否继续?`, "提示", {
                confirmButtonText: "确定",
                cancelButtonText: "取消",
                type: "warning"
            }).then(() => {
                this.$api.course.course_cat({
                    method: 'set.course_cat.status',
                    status,
                    course_cat_id: statusData
                }).then(res => {
                    this.$message.success(`${status == 1 ? '启用' : '禁用'}成功！`);
                    this.getData();
                }).catch(err => {
                    console.log(err);
                });
            }).catch(() => {//用户取消

            });
        },
        toggleSelection(rows) {
            if (rows) {
                rows.forEach(row => {
                    this.$refs.multipleTable.toggleRowSelection(row);
                });
            } else {
                this.$refs.multipleTable.clearSelection();
            }
        },
        handleSelectionChange(val) {
            this.multipleSelection = val;
        }
    },
};
</script>
<style scoped lang='scss'>
@import "@/styles/config.scss";

.cat_pic_box {
    display: inline-flex;
    justify-content: left;
    align-items: baseline;

    img {
        width: 30px;
        height: 30px;
        border-radius: 100%;
        margin-right: 5px;
    }
}
</style>
