<template>
    <div>
        <Crumbs></Crumbs>
        <div class="mainList">
            <div class="mainList_operation clearfloat">
                <div class="mainList_operation_search">
                    <el-input class="item" placeholder="请输入分类名称" clearable v-model="parameter.keywords"></el-input>
                    <el-select  placeholder="请选择状态" class="item" v-model="parameter.status">
                        <el-option label="开启" value="1"></el-option>
                        <el-option label="禁用"  value="2"></el-option>
                    </el-select>
                    <el-button type="primary" plain icon="el-icon-search" @click="pageChangeHandler(1)">搜索</el-button>
                </div>
                <div class="fr">
                    <el-button type="primary" icon="el-icon-plus"  @click="add">新增</el-button>
                    <el-button v-if="selectRows.length>0" type="danger" @click="handleDelete">批量删除</el-button>
                </div>

            </div>
            <div class="mainList_content">
                <div class="mainList_content_sub">
                    <el-table ref="multipleTable" border :data="tableData" tooltip-effect="dark" height="string" v-loading="loading" @selection-change="setSelectRows">
                        <el-table-column align="center" show-overflow-tooltip type="selection"></el-table-column>
                        <el-table-column label="序号" type="index" align="center" width="70"></el-table-column>
                        <el-table-column align="center" prop="name" label="名称"></el-table-column>
                        <el-table-column align="center" prop="create_time" label="创建时间"></el-table-column>
                        <el-table-column align="center" prop="status" label="状态">
                            <template slot-scope="scope">
                                {{  scope.row.status | showStatus  }}
                            </template>
                        </el-table-column>
                        <el-table-column align="center" prop="update_time" label="更新时间"></el-table-column>

                        <el-table-column label="操作"  align="center" width="160" fixed="right" >
                            <template slot-scope="scope">
                                <el-button type="text" size="small" @click="edit(scope)" >编辑</el-button>
                                <el-button class="danger" type="text" size="small" @click="handleDelete(scope)" >删除</el-button>
                            </template>
                        </el-table-column>
                    </el-table>
                    <el-pagination
                        @size-change="handleSizeChange"
                        @current-change="pageChangeHandler"
                        :current-page.sync="parameter.page_no"
                        :page-size="parameter.page_size"
                        prev-text="上一页"
                        next-text="下一页"
                        :total="parameter.total_result">
                    </el-pagination>
                </div>
            </div>
        </div>
      <Edit :isShow.sync="showEditDialog" :options="options"></Edit>
    </div>
</template>
<script>


export default {
    components: {
       Edit: () => import("./Edit.vue"),
        Crumbs: () => import("@/components/Crumbs.vue"),
    },
    data() {
        return {
            parameter:{
                method:'get.goods_category.list',
                page_no:1,
                page_size:50,
                total_result:0,
                keywords:'',
                status:''
            },
            selectRows:[],
            activeData:{},
            options: {},
            tableData: [],
            loading: false,
            showEditDialog: false,
        };
    },
    provide () {
        return {
            refresh:this.refresh,
        }
    },
    watch: {},
    created() {
        this.getData();

    },
    methods: {
        setSelectRows(val) {
            this.selectRows = val
        },
        refresh(){
            this.showEditDialog = false;
            this.pageChangeHandler(1);
        },
        pageChangeHandler(val) {
            this.parameter.page_no = val;
            this.getData();
        },
        handleSizeChange(val){
            this.parameter.page_size = val;
            this.parameter.page_no = 1;
            this.getData();
        },
        edit(scope) {
            this.options = scope.row;
            this.showEditDialog = true

        },
        add() {
            this.options = {};
            this.showEditDialog = true

        },
        getData() {
            this.loading = true;
            this.$api.goodsCategory.goodsCategory(this.parameter).then(res => {
                this.loading = false;
                console.log(res.data);
                if(res.data.items&&Array.isArray(res.data.items)){
                    this.tableData = res.data.items;
                }else{
                    this.tableData =[];
                };
                this.parameter.total_result = res.data.total_result;
            }).catch(err => {
                console.log(err);
                this.loading = false;
            });
        },
        handleDelete(row) {
            const ids=[];
            if (row.goods_category_id) {
                ids.push(id);
            } else {
                if (this.selectRows.length > 0) {
                    const itemIds = this.selectRows.map((item) => item.goods_category_id);
                    for (var i = 0; i < itemIds.length; i++) {
                        ids.push(itemIds[i]);
                    }
                } else {
                    this.$message.error('未选中任何行');
                    return false
                }
            }

            console.log(ids);
            this.$confirm("此操作将永久删除该数据, 是否继续?", "提示", {
                confirmButtonText: "确定",
                cancelButtonText: "取消",
                type: "warning"
            }).then(() => {
                this.$api.goodsCategory.goodsCategory({
                    module:'admin',
                    method:'del.goods_category.list',
                    'goods_category_id':ids,
                }).then(res => {
                    if(res.status==200){
                        this.$message.success("删除成功！");
                        var hasLastPage = this.parameter.page_size*this.parameter.page_no>=this.parameter.total_result;
                        var lastData = this.tableData.length<2;
                        if (lastData&&hasLastPage&&this.parameter.page_no>1) {
                            this.parameter.page_no --;
                        };
                        this.getData();
                    }

                }).catch(err => {
                    console.log(err);
                });
            }).catch(() => {//用户取消

            });
        },
    },
};
</script>
<style scoped lang='scss'>
@import "@/styles/config.scss";

</style>
