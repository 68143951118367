import { render, staticRenderFns } from "./ReceivingAddress.vue?vue&type=template&id=bec8b3c0&scoped=true&"
import script from "./ReceivingAddress.vue?vue&type=script&lang=js&"
export * from "./ReceivingAddress.vue?vue&type=script&lang=js&"
import style0 from "./ReceivingAddress.vue?vue&type=style&index=0&id=bec8b3c0&prod&scoped=true&lang=scss&"


/* normalize component */
import normalizer from "!../../../../node_modules/_vue-loader@15.10.1@vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "bec8b3c0",
  null
  
)

export default component.exports