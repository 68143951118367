<template>

  <div class="UploadImage" v-loading="file.loading">
    <Upload @upload="upload">
      <div slot="button" class="UploadImageBtn">
        <el-image v-if="file.fileUrl" :src="file.fileUrl" class="image">
        </el-image>
        <i v-else class="el-icon-plus avatar-uploader-icon"></i>
      </div>

    </Upload>

  </div>
</template>

<script>
export default {
  props: {
    disabled: {
      type: Boolean,
      default: false,
    },
    value: {},
  },
  components: {
    Upload: () => import('./Upload.vue'),
  },
  model: {
    prop: 'value',
    event: 'change',
  },
  data() {
    return {
      file: {
        loading: false,
        fileUrl: '',
      },
    }
  },
  computed: {},
  watch: {
    value: {
      immediate: true, //初始化立即执行
      handler: function (newVal) {
        this.file.fileUrl = newVal
      },
    },
    file: {
      deep: true,
      handler: function (newVal) {
        if (!newVal.loading) {
          this.$emit('change', newVal.fileUrl)
        }
      },
    },
  },
  mounted() {},
  methods: {
    upload(r) {
      console.log(r)
      console.log(r[0].fileUrl)
      this.file = r[0]
    },
  },
}
</script>

<style lang="scss" scoped>
.UploadImage {
  display: inline-block;
  text-align: center;
  .el-image {
    display: block;
    margin-bottom: 10px;
  }
  /deep/.UploadImageBtn {
    font-size: 28px;
    color: #8c939d;
    width: 100px;
    height: 100px;
    line-height: 100px;
    text-align: center;
    border: 1px dashed #d9d9d9;
    border-radius: 6px;
    cursor: pointer;
    .image {
      max-width: 100%;
      max-height: 100%;
    }
  }
  .UploadImageBtn {
  }
}
</style>
