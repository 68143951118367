<template>
    <div>
        <Crumbs></Crumbs>
        <div class="mainList">
            <div class="mainList_operation clearfloat">
                <div class="fr">
                    <el-button type="primary" icon="el-icon-plus" @click="add()">新增</el-button>
                </div>
            </div>
            <div class="mainList_content">
                <div class="mainList_content_sub">
                    <el-table ref="multipleTable" row-key="menu_id" border :data="tableData"
                        :tree-props="{ children: 'children', hasChildren: 'hasChildren' }" tooltip-effect="dark"
                        height="string" :default-expand-all="false" v-loading="loading">
                        <el-table-column prop="name" align="left" label="名称"></el-table-column>
                        <el-table-column prop="typeId" align="center" label="类型">
                            <template slot-scope="scope">
                                {{ scope.row.is_navi | is_navi }}
                            </template>
                        </el-table-column>
                        <el-table-column prop="href" align="center" label="链接地址"></el-table-column>
                        <el-table-column prop="component_url" align="center" label="组件路劲"></el-table-column>
                        <el-table-column prop="marking" align="center" label="权限标识"></el-table-column>
                        <el-table-column prop="sort" align="center" label="排序"></el-table-column>
                        <el-table-column prop="status" align="center" label="是否禁用">
                            <template slot-scope="scope">
                                {{ scope.row.status | status }}
                            </template>
                        </el-table-column>
                        <el-table-column label="操作" align="center" width="120">
                            <template slot-scope="scope">
                                <el-button type="text" size="small" @click="edit(scope.row)">编辑</el-button>
                                <el-button class="danger" type="text" size="small" @click="del(scope)">删除</el-button>
                            </template>
                        </el-table-column>
                    </el-table>
                </div>
            </div>
        </div>
        <Edit :isShow.sync="showEditDialog" :listData="listData" :treeselectOptions="treeselectOptions"
            :options="options"></Edit>

    </div>
</template>
<script>
import { mapState } from 'vuex'
import { initTreeData } from '@/util/common'
export default {
    components: {
        Edit: () => import("./Edit.vue"),
        Crumbs: () => import("@/components/Crumbs.vue"),
    },
    data() {
        return {
            tableData: [],
            listData: [],
            treeselectOptions: [],
            options: {},
            showEditDialog: false,
        };
    },
    provide() {
        return {
            refresh: this.refresh,
        }
    },
    created() {
        this.getData();
    },
    filters: {
        is_navi: function (v) {
            return v == 1 ? "菜单" : "按钮";
        }
    },
    computed: {
        ...mapState({
            menus: (state) => state.user.menus,
        }),
    },
    methods: {
        refresh() {
            this.showEditDialog = false;
            this.getData();
        },
        add() {
            this.options = {};
            this.showEditDialog = true;
        },
        edit(row) {
            this.options = row;
            this.showEditDialog = true;
        },
        getData() {
            this.loading = true;
            this.$api.menu.menu({
                method: 'get.menu.list',
                module: 'admin',
            }).then(res => {
                this.loading = false;
                this.listData = res.data;
                this.tableData = initTreeData(res.data, 'parent_id', 'menu_id');
                this.treeselectOptions = [
                    {
                        menu_id: 0,
                        name: '顶级菜单',
                        children: this.tableData
                    }
                ];
            }).catch(err => {
                console.log(err);
                this.loading = false;
            });
        },
        del(scope) {
            this.$confirm("此操作将永久删除该数据, 是否继续?", "提示", {
                confirmButtonText: "确定",
                cancelButtonText: "取消",
                type: "warning"
            }).then(() => {
                this.$api.menu.menu({
                    module: 'admin',
                    method: 'del.menu.item',
                    menu_id: scope.row.menu_id,
                }).then(res => {

                    this.$message.success("删除成功！");
                    this.getData();

                }).catch(err => {
                    console.log(err);
                });
            }).catch(() => {//用户取消

            });
        },
    },
};
</script>
<style scoped lang='scss'>
@import "@/styles/config.scss";
</style>
