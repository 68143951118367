<template>
    <div class="login">
        <div class="login-main box-shadow">
            <div class="txt colorBlue">欢迎登录incoPat后台管理</div>
            <el-form :model="form" ref="form" :rules="rules">
                <el-form-item prop="username">
                    <el-input
                        prefix-icon="el-icon-user"
                        type="text"
                        v-model="form.username"
                        placeholder="请输入用户名"
                        @keyup.enter.native="login"
                    ></el-input>
                </el-form-item>
                <el-form-item prop="password">
                    <el-input
                        prefix-icon="el-icon-lock"
                        type="password"
                        v-model="form.password"
                        placeholder="请输入密码"
                        @keyup.enter.native="login"
                    ></el-input>
                </el-form-item>
                <el-form-item>
                    <el-checkbox v-model="rememberThePassword">记住密码</el-checkbox>
                </el-form-item>
                <el-form-item>
                    <el-button type="primary" :loading="loading" @click="login">登录</el-button>
                </el-form-item>
            </el-form>
        </div>

        <div class="login-footer">技术支持：xxxx公司</div>
    </div>
</template>
<script>



export default {
    data() {
        return {
            logo: require("@/assets/images/logo.png"),
            form: {
                method:'login.admin.user',
                platform:'admin',
                username: localStorage.getItem('username') ? localStorage.getItem('username') : '',
                password: localStorage.getItem('password') ? localStorage.getItem('password') : '',
            },
            loading: false,
            rememberThePassword: true,
            rules: {
                username: [
                    {required: true, message: '请输入用户姓名', trigger: 'blur'}
                ],
                password: [
                    {required: true, message: '请输入密码', trigger: 'blur'}
                ],
            },
        };
    },
    created() {
    },
    methods: {
        login() {

            this.$refs.form.validate((valid) => {
                if (valid) {
                    this.loading = true;
                    this.$api.login.login(this.form).then(res => {


                        this.$store.dispatch('user/setUserInfo', res.data).then(() => {
                            this.loading = false;
                            this.$router.push({path: this.$route.query.redirect ? this.$route.query.redirect : "/"});
                        }).catch(() => {
                            this.loading = false;
                        });

                        if (this.rememberThePassword) {
                            localStorage.setItem('username', this.form.username);
                            localStorage.setItem('password', this.form.password);
                        } else {
                            localStorage.removeItem('username');
                            localStorage.removeItem('password');
                        }

                    }).catch(err => {
                        console.log(err)
                        this.loading = false;
                    });
                }
                ;
            });
        }
    },
};
</script>
<style scoped lang='scss'>
@import "@/styles/config.scss";

.login {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background: url("~@/assets/images/loginBG.jpg");
    background-size: cover;
    overflow: hidden;

    .login-main {
        position: absolute;
        left: 50%;
        top: 50%;
        width: 400px;
        background: #fff;
        transform: translate(-50%, -50%);
        padding:30px 20px;
        border-radius:5px;
        .txt {
            font-size:23px;
            text-align:center;
        }
        /deep/.el-form {
            margin-top:30px;
            .el-button {
                width:100%;
            }
        }


    }

    .login-footer {
        position: absolute;
        left: 20px;
        right: 20px;
        bottom: 15px;
        text-align: center;
        font-size: 13px;
        color: #fff;
        text-shadow: 0 0 3px #000;
    }
}
</style>
