<template>
  <el-dialog class="editDialog" :append-to-body="true" width="600px" :visible.sync="showViewReplyDialog" :close-on-click-modal="false" :destroy-on-close="true" @close="$emit('update:isShow', false)" :show-close="true">
    <div slot="title" class="title">查看评论</div>
    <div class="content">
      <div slot="header" class="clearfix">
        <ReplyCard :reply-data="options"></ReplyCard>
      </div>
      <el-divider content-position="left">回复列表</el-divider>
      <div v-if="replyData&&replyData.length" class="reply_list" v-loading="loading">
        <div v-for="reply in replyData" :key="reply.course_note_reply_id" class="text item">
          <ReplyCard :reply-data="reply"></ReplyCard>
        </div>
      </div>
      <template v-else>暂无回复</template>
    </div>
  </el-dialog>
</template>
<script>
export default {
  components: {
    ReplyCard: () => import('@/components/ReplyCard.vue'),
  },
  props: {
    isShow: {
      type: Boolean,
      default: false,
    },
    options: {
      type: Object,
      default: function () {
        return {}
      },
    },
  },
  data() {
    return {
      showViewReplyDialog: false,
      loading: true,
      submitLoading: false,
      form: {},
      replyData: [],
      loading: false,
    }
  },
  inject: ['refresh'],
  watch: {
    isShow: {
      immediate: true, //初始化立即执行
      handler: function (newVal) {
        this.showViewReplyDialog = newVal
      },
    },
    options: {
      immediate: true, //初始化立即执行
      deep: true, //对象深度监测
      handler: function (newVal, oldVal) {
        this.init(newVal)
      },
    },
  },
  created() {},
  methods: {
    init(options) {
      if (options.course_note_id) {
        //编辑进来的会有id需要
        this.loading = true

        this.$api.course
          .course_note_reply({
            module: 'admin',
            course_note_id: options.course_note_id,
            method: 'get.course_note_reply.list',
          })
          .then((res) => {
            this.replyData = res.data.items
            this.loading = false
          })
          .catch((err) => {
            this.loading = false
            console.log(err)
          })
      } else {
        this.replyData = []
        this.loading = false
      }
    },
  },
}
</script>
<style scoped lang='scss'>
.content {
  padding: 0 20px;
  .text {
    width: 100%;
  }
  .reply_list {
    max-height: 400px;
    overflow-y: auto;
  }
}
</style>