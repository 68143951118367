<template>
  <div>
    <Crumbs></Crumbs>
    <div class="mainList">
      <div class="mainList_content">
        <div class="mainList_content_sub">
          <el-button-group style="margin-bottom: 10px">
            <el-button
              type="primary"
              :disabled="selectList.length ? false : true"
              @click="batchProcessed">
              已处理
            </el-button>
            <el-button
              type="primary"
              :disabled="selectList.length ? false : true"
              @click="batchReject">
              驳回
            </el-button>
            <el-button
              type="primary"
              :disabled="selectList.length ? false : true"
              @click="batchDelete">
              删除
            </el-button>
          </el-button-group>
          <el-table
            ref="multipleTable"
            border
            :data="tableData"
            tooltip-effect="dark"
            height="string"
            v-loading="loading"
            @selection-change="handleSelectionChange">
            <el-table-column
              type="selection"
              width="55">
            </el-table-column>
            <el-table-column
              label="序号"
              type="index"
              align="center"
              width="70"></el-table-column>
            <el-table-column
              align="center"
              prop="nickname"
              label="举报人昵称"></el-table-column>
            <el-table-column
              align="center"
              prop="comment"
              label="举报说明"></el-table-column>
            <el-table-column
              align="center"
              prop="get_comment.content"
              label="被举报内容"></el-table-column>
            <el-table-column
              align="center"
              prop="get_comment.nickname"
              label="被举报人昵称"></el-table-column>
            <el-table-column
              prop="type"
              align="center"
              label="类型">
              <template slot-scope="scope">
                {{ scope.row.type | type }}
              </template>
            </el-table-column>

            <el-table-column
              prop="status"
              align="center"
              label="当前状态">
              <template slot-scope="scope">
                {{ scope.row.status | status }}
              </template>
            </el-table-column>

            <el-table-column
              label="操作"
              align="center"
              width="150">
              <template slot-scope="scope">
                <el-button
                  type="text"
                  size="small"
                  :disabled="scope.row.status === 1 ? true : false"
                  @click="statusChange(1, [scope.row.report_id])">
                  已处理
                </el-button>
                <el-button
                  type="text"
                  size="small"
                  :disabled="scope.row.status === 2 ? true : false"
                  @click="statusChange(2, [scope.row.report_id])">
                  驳回
                </el-button>
                <el-button
                  class="danger"
                  type="text"
                  size="small"
                  @click="del([scope.row.report_id])">
                  删除
                </el-button>
              </template>
            </el-table-column>
          </el-table>
          <el-pagination
            @current-change="pageChangeHandler"
            @size-change="handleSizeChange"
            :current-page="parameter.page_no"
            :page-size="parameter.page_size"
            prev-text="上一页"
            next-text="下一页"
            :total="parameter.total_result">
          </el-pagination>
        </div>
      </div>
    </div>
    <Edit
      :isShow.sync="showEditDialog"
      :options="options"></Edit>
  </div>
</template>
<script>
export default {
  components: {
    Edit: () => import('./Edit.vue'),
    Crumbs: () => import('@/components/Crumbs.vue'),
  },
  data() {
    return {
      parameter: {
        method: 'get.report.list',
        page_size: 50,
      },
      groupData: [],
      options: {},
      tableData: [],
      selectList: [],
      loading: false,
      showEditDialog: false,
    }
  },
  provide() {
    return {
      refresh: this.refresh,
    }
  },
  watch: {},
  filters: {
    status(v) {
      if (v == '0') {
        return '待处理'
      } else if (v == '1') {
        return '已处理'
      } else if (v == '2') {
        return '驳回'
      } else {
        return ''
      }
    },
    type(v) {
      if (v == '1') {
        return '评论'
      } else if (v == '2') {
        return '评论回复'
      } else if (v == '3') {
        return '笔记'
      } else if (v == '4') {
        return '笔记回复'
      } else if (v == '5') {
        return '行业交流'
      } else if (v == '6') {
        return '考试交流'
      } else {
        return ''
      }
    },
  },
  created() {
    this.getData()
  },
  methods: {
    refresh() {
      this.showEditDialog = false
      this.pageChangeHandler(1)
    },
    pageChangeHandler(val) {
      this.parameter.page_no = val
      this.getData()
    },
    handleSizeChange(val) {
      this.parameter.page_size = val
      this.parameter.page_no = 1
      this.getData()
    },
    add() {
      this.options = {}
      this.showEditDialog = true
    },
    statusChange(status, report_id) {
      this.$api.report
        .coupon({
          module: 'admin',
          method: 'set.report.status',
          status,
          report_id,
        })
        .then((res) => {
          this.getData()
        })
        .catch((err) => {
          console.log(err)
        })
    },
    getData() {
      this.loading = true
      this.$api.report
        .coupon(this.parameter)
        .then((res) => {
          this.loading = false
          this.tableData = res.data.items
          this.parameter.total_result = res.data.total_result
        })
        .catch((err) => {
          console.log(err)
          this.loading = false
        })
    },
    handleSelectionChange(rows) {
      this.selectList = rows
    },
    del(report_id) {
      this.$confirm('此操作将永久删除该数据, 是否继续?', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning',
      })
        .then(() => {
          this.$api.report
            .coupon({
              module: 'admin',
              method: 'del.report.list',
              report_id,
            })
            .then((res) => {
              this.$message.success('删除成功！')

              var hasLastPage =
                this.parameter.page_size * this.parameter.page_no >=
                this.parameter.total_result
              var lastData = this.tableData.length < 2
              if (lastData && hasLastPage && this.parameter.page_no > 1) {
                this.parameter.page_no--
              }
              this.getData()
            })
            .catch((err) => {
              console.log(err)
            })
        })
        .catch(() => {
          //用户取消
        })
    },
    batchProcessed() {
      const idList = this.selectList.map((item) => {
        return item.report_id
      })
      this.statusChange(1, idList)
    },
    batchReject() {
      const idList = this.selectList.map((item) => {
        return item.report_id
      })
      this.statusChange(2, idList)
    },
    batchDelete() {
      const idList = this.selectList.map((item) => {
        return item.report_id
      })
      this.del(idList)
    },
  },
}
</script>
<style scoped lang="scss">
@import '@/styles/config.scss';
</style>
