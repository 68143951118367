<template>
    <el-table
            border
            :data="tableData"
            tooltip-effect="dark"
            empty-text="请上传文件"
            height="string">
        <template  name="tableColumn">
            <el-table-column prop="fileName" label="文件名称" align="center" />
            <el-table-column prop="fileUrl" label="文件路径" align="center">
                <template slot-scope="scope">
                    <template v-if="scope.row.loading">
                        <i class="el-icon-loading" style="color:#f00;"></i>正在上传
                    </template>
                    <template v-else>
                        <a target="_blank" style="text-decoration:revert;color:#409eff" :href="`${filepath}${scope.row.fileUrl}`">{{  scope.row.fileUrl  }}</a>
                    </template>
                </template>
            </el-table-column>
        </template>
        <el-table-column align="center" width="50" v-if="!disabled">
            <template slot="header" slot-scope="scope">
                <Upload :type="type" @upload="upload"></Upload>
            </template>
            <template slot-scope="scope">
                <i @click="del(scope)" class="iconfont iconerror"></i>
            </template>
        </el-table-column>
    </el-table>
</template>

<script>

    export default {
        components: {
            Upload: () => import("./Upload.vue"),
        },
        props: {
            disabled: {
                type: Boolean,
                default: false
            },
            value: {
                type: Array,
                default: function () {
                    return []
                }
            },
            type:{
                type:String,
                default:'default',
            },
        },
        data() {
            return {
                filepath:process.env.VUE_APP_FILEPATH,
                tableData:[],
            }
        },
        watch: {
            value:{
                immediate:true,//初始化立即执行
                handler: function (newVal){
                    this.tableData = newVal;
                }
            },
        },
        model: {
            prop: 'value',
            event: 'change'
        },
        mounted() {

        },
        methods: {
            upload(r){
                r.some(res=>{
                    this.tableData.push(res.combination({
                        fileCode:this.fileCode,
                        ywDataId:this.ywDataId,
                    }));
                });
                this.$emit('change', this.tableData);
            },
            del(scope){
                this.tableData.splice(scope.$index,1);
                this.$emit('change', this.tableData);
            },
        }
    };
</script>

<style lang="scss" scoped>

</style>
