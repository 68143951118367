<template>
    <div class="header">
        <div v-if="isCollapse" class="isCollapse el-icon-s-unfold" @click="$emit('update:isCollapse', false)"></div>
        <div v-else class="isCollapse el-icon-s-fold" @click="$emit('update:isCollapse', true)"></div>
        <HorizontalMenuEle class="nav"></HorizontalMenuEle>
        <el-dropdown class="user">
            <div style="cursor:pointer;">
                <img :src="userInfo.url | portrait" class="portrait">
                {{ userInfo.admin.username }} ({{ userInfo.admin.nickname }})
            </div>
            <el-dropdown-menu slot="dropdown">
               <!-- <el-dropdown-item>-->
<!--                    <i class="el-icon-setting"></i>修改密码-->
<!--                </el-dropdown-item> -->
                <el-dropdown-item @click.native="signOut">
                    <i class="el-icon-circle-close"></i>退出登录
                </el-dropdown-item>
            </el-dropdown-menu>
        </el-dropdown>
    </div>
</template>
<script>
import {mapState} from "vuex";

export default {
    components: {
        HorizontalMenuEle: () => import("@/views/common/HorizontalMenuEle.vue"),
    },
    props: {
        isCollapse: {
            type: Boolean,
            default: true
        },
    },
    name: "Header",
    data() {
        return {

        };
    },
    computed: {
        ...mapState({
            userInfo: state => state.user.userInfo,
        })
    },
    created() {},
    methods: {
        signOut() {

            this.$store.commit({
                type: 'user/SIGN_OUT'
            });
            this.$router.push('/login');
        }
    },
};
</script>
<style scoped lang='scss'>
@import "@/styles/config.scss";

.header {
    .isCollapse {
        float:left;
        line-height:40px;
        width:40px;
        margin:15px 0 0 5px;
        font-size:30px;
        text-align:center;
        cursor:pointer;
    }
    .nav {
        float: left;
        margin-left: 40px;
        padding-top: 5px;
    }
    .user {
        float: right;
        margin: 15px 15px 0 0;
        line-height:40px;
        .portrait {
            width:40px;
            height:40px;
            border-radius:50%;
            background:#F6F9FD;
            margin-right: 10px;
            border:#ddd solid 1px;
        }
    }
}
</style>
