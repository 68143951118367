<template>
  <el-dialog
    class="editDialog"
    :append-to-body="true"
    width="800px"
    :visible.sync="showEditDialog"
    :close-on-click-modal="false"
    :destroy-on-close="true"
    @close="$emit('update:isShow', false)"
    :show-close="true"
  >
    <div slot="title" class="title">
      {{ options.organ_id ? '编辑' : '新增'
      }}{{ form.type == 1 ? '讲师' : '机构' }}
    </div>

    <div class="content">
      <el-form
        class="column"
        label-width="120px"
        :model="form"
        :rules="rules"
        ref="form"
      >
        <el-form-item label="类型" prop="type" ref="type">
          <el-select
            clearable
            class="item"
            v-model="form.type"
            placeholder="请选择类型"
          >
            <el-option
              v-for="item in typeData"
              :key="item.type_id"
              :label="item.name"
              :value="item.type_id"
            >
            </el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="用户" prop="user_id" ref="user_id">
          <ChoiceData
            :selectedData="[{ user_id: form.user_id }]"
            :configure="configure"
            :showClear="true"
            @clear="clearBindUser"
            @choiceDataChange="choiceDataChange"
          >
            <el-input
              slot="button"
              v-model="form.nickname"
              placeholder="请选择用户"
            ></el-input>
            <div
              class="mainList_operation clearfloat"
              slot="search"
              slot-scope="{ params, search }"
            >
              <div class="mainList_operation_search">
                <el-input
                  class="item"
                  placeholder="请输入昵称"
                  clearable
                  v-model="params.nickname"
                ></el-input>
                <el-input
                  class="item"
                  placeholder="请输入手机号"
                  clearable
                  v-model="params.mobile"
                ></el-input>
                <el-button
                  type="primary"
                  plain
                  icon="el-icon-search"
                  @click="search(1)"
                  >搜索</el-button
                >
              </div>
            </div>
            <template slot="tableColumn">
              <el-table-column
                align="center"
                prop="nickname"
                label="昵称"
              ></el-table-column>
              <el-table-column
                align="center"
                prop="mobile"
                label="手机号"
              ></el-table-column>
              <el-table-column
                align="center"
                prop="email"
                label="邮箱"
              ></el-table-column>
              <el-table-column
                align="center"
                prop="birthday"
                label="出生日期"
              ></el-table-column>
            </template>
          </ChoiceData>
        </el-form-item>

        <template v-if="form.type === 1">
          <el-form-item label="姓名" prop="name" ref="name">
            <el-input
              v-model="form.name"
              placeholder="请输入讲师姓名"
            ></el-input>
          </el-form-item>
          <el-form-item label="职称" prop="cultural_title" ref="cultural_title">
            <el-input
              v-model="form.cultural_title"
              placeholder="请输入职称"
            ></el-input>
          </el-form-item>
          <el-form-item label="手机号" prop="mobile" ref="mobile">
            <el-input
              v-model="form.mobile"
              placeholder="请输入手机号"
            ></el-input>
          </el-form-item>
        </template>
        <el-form-item label="邮箱" prop="email" ref="email">
          <el-input v-model="form.email" placeholder="请输入邮箱"></el-input>
        </el-form-item>
        <el-form-item label="是否带V" prop="is_vip" ref="is_vip">
          <el-select
            class="item"
            v-model="form.is_vip"
            placeholder="请选择是否带V"
          >
            <el-option label="是" :value="1"> </el-option>
            <el-option label="否" :value="2"> </el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="企业名称" prop="company_name" ref="company_name">
          <el-input
            v-model="form.company_name"
            placeholder="请输入企业名称"
          ></el-input>
        </el-form-item>
        <template v-if="form.type === 2">
          <el-form-item label="企业logo" prop="company_logo" ref="company_logo">
            <UploadImage v-model="form.company_logo" />
          </el-form-item>
          <el-form-item label="企业头像" prop="company_head" ref="company_head">
            <UploadImage v-model="form.company_head" />
          </el-form-item>
        </template>
        <template v-if="form.type === 1">
          <el-form-item label="讲师头像" prop="attachment" ref="attachment">
            <UploadImage v-model="form.attachment" />
          </el-form-item>
          <el-form-item
            label="讲师半身照"
            prop="company_logo"
            ref="company_logo"
          >
            <UploadImage v-model="form.company_logo" />
          </el-form-item>
        </template>
        <el-form-item label="简介" prop="description" ref="description">
          <Editor :value.sync="form.description" :offUploadImg="false"></Editor>
        </el-form-item>
        <el-form-item label="V栏显示" prop="is_show" ref="is_show">
          <el-switch
            v-model="form.is_show"
            :active-value="1"
            :inactive-value="0"
            @change="isShowChange"
            active-color="#13ce66"
            inactive-color="#ff4949"
          >
          </el-switch>
        </el-form-item>
        <el-form-item
          v-if="form.is_show === 1"
          label="首页推荐"
          prop="is_top"
          ref="is_top"
        >
          <el-switch
            v-model="form.is_top"
            :active-value="1"
            :inactive-value="0"
            active-color="#13ce66"
            inactive-color="#ff4949"
          >
          </el-switch>
        </el-form-item>
      </el-form>
    </div>

    <div slot="footer">
      <el-button type="success" :loading="submitLoading" @click="submit"
        >保存</el-button
      >
      <el-button type="info" plain @click="$emit('update:isShow', false)"
        >取消</el-button
      >
    </div>
  </el-dialog>
</template>
<script>
import { mobile, email } from '@/regular'
export default {
  components: {
    Editor: () => import('@/components/editor/index.vue'),
    ChoiceData: () => import('@/components/ChoiceData.vue'),
    UploadImage: () => import('@/components/upload/Image.vue'),
  },
  props: {
    isShow: {
      type: Boolean,
      default: false,
    },
    options: {
      type: Object,
      default: function () {
        return {}
      },
    },
  },
  data() {
    return {
      configure: {
        request: this.$api.user.user,
        idKey: 'user_id',
        params: {
          method: 'get.user.list',
        },
        title: '请选择用户',
      },
      showEditDialog: false,
      submitLoading: false,
      form: {
        user_id: null,
      },
      groupData: [],
      userData: [],
      typeData: [
        {
          type_id: 1,
          name: '讲师',
        },
        {
          type_id: 2,
          name: '机构',
        },
      ],
      isMp4Url: false,
      isRequired: true,
    }
  },
  inject: ['refresh'],
  watch: {
    isShow: {
      immediate: true, //初始化立即执行
      handler: function (newVal) {
        this.$nextTick(() => {
          newVal && this.$refs.form && this.$refs.form.clearValidate()
        })
        this.showEditDialog = newVal
      },
    },
    options: {
      immediate: true, //初始化立即执行
      deep: true, //对象深度监测
      handler: function (newVal, oldVal) {
        this.init(newVal)
      },
    },
    'form.type'(newVal) {
      this.isRequired = newVal == 1
      this.$nextTick(() => {
        newVal && this.$refs.form && this.$refs.form.clearValidate()
      })
    },
  },
  computed: {
    rules() {
      return {
        user_id: [
          {
            required: false,
            message: '请选择用户',
            trigger: ['blur', 'change'],
          },
        ],
        type: [
          {
            required: true,
            message: '请选择类型',
            trigger: ['blur', 'change'],
          },
        ],
        cultural_title: [
          {
            required: this.isRequired,
            message: '请输入职称',
            trigger: ['blur', 'change'],
          },
        ],
        attachment: [
          {
            required: true,
            message: '请上传头像',
            trigger: ['blur', 'change'],
          },
        ],
        name: [
          {
            required: true,
            message: '请输入姓名',
            trigger: ['blur', 'change'],
          },
        ],
        mobile: [
          {
            required: false,
            message: '请输入手机号',
            trigger: ['blur', 'change'],
          },
          {
            pattern: mobile,
            message: '请输入正确的手机号',
            trigger: ['blur', 'change'],
          },
        ],
        email: [
          {
            required: false,
            message: '请输入邮箱',
            trigger: ['blur', 'change'],
          },
          {
            pattern: email,
            message: '请输入正确的邮箱',
            trigger: ['blur', 'change'],
          },
        ],
        company_name: [
          {
            required: true,
            message: '请输入企业名称',
            trigger: ['blur', 'change'],
          },
        ],
        company_logo: [
          {
            required: true,
            message: '请输入企业logo',
            trigger: ['blur', 'change'],
          },
        ],
        company_head: [
          {
            required: true,
            message: '请输入企业头像',
            trigger: ['blur', 'change'],
          },
        ],
      }
    },
  },
  created() {
    this.getUserData()
  },
  methods: {
    isShowChange(e) {
      if (e === 0) {
        this.form.is_top = 0
      }
    },
    choiceDataChange(r) {
      this.form.user_id = r.user_id
      this.form.nickname = r.nickname
      this.form.mobile = r.mobile
      this.$refs.form.validateField('user_id')
    },
    init(options) {
      if (options.organ_id) {
        //编辑进来的会有id需要
        this.form = { ...options }
        console.log('this.form:', this.form)
        if (options.user_id) {
          this.$api.user
            .user({
              method: 'get.user.item',
              client_id: options.user_id,
            })
            .then((res) => {})
            .catch((err) => {})
        }
      } else {
        this.form = {
          user_id: null,
          type: options.type || 1,
          is_vip: 2,
          is_show: 1,
        }
        this.isMp4Url = false
      }
    },
    submit() {
      if (!this.form.user_id) {
        delete this.form.user_id
      }
      this.$refs.form.validate((valid, obj) => {
        if (valid) {
          this.submitLoading = true
          this.$api.organ
            .organ(
              this.form.combination({
                is_admin: this.form.organ_id ? this.form.is_admin : 1,
                method: this.form.organ_id
                  ? 'set.organ.item'
                  : 'add.organ.item',
              })
            )
            .then((res) => {
              this.submitLoading = false
              this.refresh(1)
              this.$message.success('操作成功！')
            })
            .catch((err) => {
              this.submitLoading = false
              console.log(err)
            })
        } else {
          this.scrollView(obj)
        }
      })
    },
    getUserData() {
      this.$api.user
        .user({
          method: 'get.user.list',
        })
        .then((res) => {
          this.userData = res.data.items
        })
        .catch((err) => {
          console.log(err)
        })
    },
    clearBindUser() {
      this.form.user_id = null
      this.form.nickname = null
      console.log(this.form.user_id)
    },
  },
}
</script>
<style scoped lang="scss">
.video {
  margin-top: 5px;
  width: 459px;
}
</style>
