<template>
  <el-dialog class="editDialog" :append-to-body="true" width="600px" :visible.sync="showEditDialog"
    :close-on-click-modal="false" :destroy-on-close="true" @close="$emit('update:isShow', false)" :show-close="true">
    <div slot="title" class="title">
      {{ options.task_attr_id ? "编辑" : "新增" }}成就任务详情
    </div>

    <div class="content">
      <el-form label-width="6em" :model="form" :rules="rules" ref="form">

        <el-form-item label="任务id" prop="task_id" ref="task_id">
          <el-input v-model="form.task_id" placeholder="请输入任务id"></el-input>
        </el-form-item>


        <el-form-item label="积分" prop="point" ref="point">
          <el-input-number v-model="form.point" controls-position="right" :min="1"></el-input-number>
        </el-form-item>

        <el-form-item label="周期" prop="rule" ref="rule">
          <el-input-number v-model="form.rule" controls-position="right" :min="1"></el-input-number>
        </el-form-item>

        <el-form-item label="排序" prop="sort" ref="sort">
          <el-input-number v-model="form.sort" controls-position="right" :min="1"></el-input-number>
        </el-form-item>


      </el-form>
    </div>

    <div slot="footer">
      <el-button type="success" :loading="submitLoading" @click="submit">保存</el-button>
      <el-button type="info" plain @click="$emit('update:isShow', false)">取消</el-button>
    </div>
  </el-dialog>
</template>
<script>
import { mobile, email } from "@/regular";

export default {
  props: {
    isShow: {
      type: Boolean,
      default: false,
    },
    options: {
      type: Object,
      default: function () {
        return {};
      },
    },
  },
  data() {
    return {
      showEditDialog: false,
      submitLoading: false,
      form: {},
      groupData: [],
      rules: {
        task_id: [
          {
            required: true,
            message: "请输入任务id",
            trigger: ["blur", "change"],
          },
        ],

      },
    };
  },
  inject: ["refresh"],
  watch: {
    isShow: {
      immediate: true, //初始化立即执行
      handler: function (newVal) {
        this.$refs.form && this.$refs.form.resetFields();
        this.showEditDialog = newVal;
      },
    },
    options: {
      immediate: true, //初始化立即执行
      deep: true, //对象深度监测
      handler: function (newVal, oldVal) {
        const xxx = newVal;
        this.init(xxx);
      },
    },
  },
  created() { },
  methods: {
    init(options) {
      console.log(options)
      if (options.task_attr_id) {
        //编辑进来的会有id需要
        this.form = options;
        console.log(this.form)
      } else {
        this.form = { type: 1, status: 1 }
      }
    },
    submit() {
      this.$refs.form.validate((valid, obj) => {
        if (valid) {
          this.submitLoading = true;
          this.$api.taskAttr.taskAttr(
            this.form.combination({
              module: "admin",
              method: this.form.task_attr_id ? "set.task_attr.item" : "add.task_attr.item",
            })
          )
            .then((res) => {
              this.submitLoading = false;
              this.refresh(1);
              this.$message.success("操作成功！");
            })
            .catch((err) => {
              this.submitLoading = false;
              console.log(err);
            });
        } else {
          this.scrollView(obj);
        }
      });
    },
  },
};
</script>
