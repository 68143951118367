<template>
  <div class="delivery" v-loading="loading">
    <div class="con">
      <el-form class="" label-width="140px" :model="form" :rules="rules" ref="form">
        <el-form-item label="地址">
          <el-input v-model="form.address" placeholder="请输入地址"></el-input>
        </el-form-item>
        <el-form-item label="评论/笔记审核">
          <el-switch v-model="form.check_common" :active-value="1" :inactive-value="2" active-color="#13ce66"
            inactive-color="#ff4949" active-text="开启" inactive-text="关闭">
          </el-switch>
        </el-form-item>
        <el-form-item label="平台ICON">
          <UploadImage v-model="form.icon" />
        </el-form-item>
      </el-form>
    </div>
    <div class="operation">
      <el-button type="success" :loading="submitLoading" @click="submit">保存</el-button>
    </div>
  </div>
</template>
<script>
export default {
  components: {
    UploadImage: () => import('@/components/upload/Image.vue'),
  },
  data() {
    return {
      loading: true,
      submitLoading: false,
      form: {},
      rules: {},
    }
  },
  created() {
    this.loading = true
    this.$api.setting
      .setting({
        method: 'get.setting.list',
        module: 'system_info',
      })
      .then((res) => {
        this.form = {
          address: res.data.address.value,
          check_common: res.data.check_common.value,
          icon: res.data.icon.value,
        }
        this.loading = false
        console.log(res)
      })
      .catch((err) => {
        console.log(err)
        this.loading = false
      })
  },
  methods: {
    submit() {
      this.$refs.form.validate((valid, obj) => {
        if (valid) {
          this.submitLoading = true
          this.$api.setting
            .setting({
              method: 'set.system.list',
              'data[address]': this.form.address,
              'data[check_common]': this.form.check_common,
              'data[icon]': this.form.icon,
            })
            .then((res) => {
              this.submitLoading = false
              this.$message.success('操作成功！')
            })
            .catch((err) => {
              console.log(err)
              this.submitLoading = false
            })
        } else {
          this.submitLoading = false
          this.scrollView(obj)
        }
      })
    },
  },
}
</script>
<style scoped lang="scss">
@import '@/styles/config.scss';

.delivery {
  height: 100%;
  overflow: auto;

  .con {
    padding: 20px 0;
    min-height: calc(100% - 80px);
  }

  .operation {
    display: block;
    position: sticky;
    bottom: 0;
    background: #fff;
    padding: 20px;
    text-align: center;
    width: 100%;

    .el-button {
      min-width: 120px;
      margin: 0 25px;
    }
  }
}
</style>
