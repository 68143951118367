<template>
    <div>
        <Crumbs></Crumbs>
        <div class="mainList">
            <div class="mainList_operation clearfloat">
                <div class="mainList_operation_search">
                    <el-input class="item" placeholder="请输入简历标题" clearable v-model="parameter.keywords"></el-input>
                    <el-select class="item" v-model="parameter.status">
                        <el-option label="请选择" value=""></el-option>
                        <el-option label="否" value="0"></el-option>
                        <el-option label="是" value="1"></el-option>
                    </el-select>
                    <el-input class="item" placeholder="请输入岗位" clearable v-model="parameter.position_title"></el-input>
                    <el-input class="item" placeholder="请输入年限" clearable v-model="parameter.year"></el-input>
                    <el-input class="item" placeholder="请输入学历" clearable v-model="parameter.education"></el-input>
                    <el-input class="item" placeholder="请输入期望薪资" clearable v-model="parameter.salary"></el-input>
                    <el-button type="primary" plain icon="el-icon-search" @click="pageChangeHandler(1)">搜索</el-button>
                </div>
                <div class="fr">
                    <el-button type="primary" icon="el-icon-plus" @click="add">新增</el-button>
                    <template v-if="selectRows.length > 0">
                        <el-button type="danger" @click="handleDelete">批量删除</el-button>
                    </template>
                </div>
            </div>
            <div class="mainList_content">
                <div class="mainList_content_sub">
                    <el-table ref="multipleTable" border :data="tableData" tooltip-effect="dark" height="string"
                        v-loading="loading" @selection-change="setSelectRows">
                        <el-table-column align="center" show-overflow-tooltip type="selection"></el-table-column>
                        <el-table-column label="序号" type="index" align="center" width="70"></el-table-column>
                        <el-table-column align="center" prop="title" label="姓名"></el-table-column>
                        <el-table-column align="center" prop="mobile" label="手机号码"></el-table-column>
                        <el-table-column align="center" prop="email" label="邮箱"></el-table-column>
                        <el-table-column align="center" prop="address" label="位置"></el-table-column>
                        <el-table-column align="center" prop="year" label="工作年限"></el-table-column>
                        <el-table-column align="center" prop="education" label="学历"></el-table-column>
                        <el-table-column align="center" prop="age" label="年龄"></el-table-column>
                        <el-table-column align="center" prop="create_time" label="创建时间"></el-table-column>

                        <el-table-column label="操作" align="center" width="160" fixed="right">
                            <template slot-scope="scope">
                                <el-button type="text" size="small" @click="edit(scope)">编辑</el-button>
                                <el-button type="text" size="small" class="danger"
                                    @click="handleDelete(scope.row)">删除</el-button>
                            </template>
                        </el-table-column>
                    </el-table>
                    <el-pagination @size-change="handleSizeChange" @current-change="pageChangeHandler"
                        :current-page.sync="parameter.page_no" :page-size="parameter.page_size" prev-text="上一页"
                        next-text="下一页" :total="parameter.total_result">
                    </el-pagination>
                </div>
            </div>
        </div>
        <Edit :isShow.sync="showEditDialog" :options="options"></Edit>
    </div>
</template>
<script>


export default {
    components: {
        Edit: () => import("./Edit.vue"),
        Crumbs: () => import("@/components/Crumbs.vue"),
    },
    data() {
        return {
            parameter: {
                method: 'get.resume.list',
                page_no: 1,
                page_size: 50,
                total_result: 0,
                keywords: '',
                position_title: '',
            },
            selectRows: [],
            activeData: {},
            options: {},
            tableData: [],
            loading: false,
            showEditDialog: false,
        };
    },
    provide() {
        return {
            refresh: this.refresh,
        }
    },
    watch: {},
    created() {
        this.getData();

    },
    methods: {
        setSelectRows(val) {
            this.selectRows = val
        },
        refresh() {
            this.showEditDialog = false;
            this.pageChangeHandler(1);
        },
        pageChangeHandler(val) {
            this.parameter.page_no = val;
            this.getData();
        },
        handleSizeChange(val) {
            this.parameter.page_size = val;
            this.parameter.page_no = 1;
            this.getData();
        },
        edit(scope) {
            this.options = scope.row;
            this.showEditDialog = true

        },
        add() {
            this.options = {};
            this.showEditDialog = true

        },
        getData() {
            this.loading = true;
            this.$api.resume.resume(this.parameter).then(res => {
                this.loading = false;
                console.log(res.data);
                if (res.data.items && Array.isArray(res.data.items)) {
                    this.tableData = res.data.items;
                } else {
                    this.tableData = [];
                };
                this.parameter.total_result = res.data.total_result;
            }).catch(err => {
                console.log(err);
                this.loading = false;
            });
        },

        handleDelete(row) {
            const ids = [];
            if (row.resume_id) {
                ids.push(row.resume_id);
            } else {
                if (this.selectRows.length > 0) {
                    const itemIds = this.selectRows.map((item) => item.resume_id);
                    for (var i = 0; i < itemIds.length; i++) {
                        ids.push(itemIds[i]);
                    }
                } else {
                    this.$message.error('未选中任何行');
                    return false
                }
            }

            console.log(ids);
            this.$confirm("此操作将永久删除该数据, 是否继续?", "提示", {
                confirmButtonText: "确定",
                cancelButtonText: "取消",
                type: "warning"
            }).then(() => {
                this.$api.resume.resume({
                    module: 'admin',
                    method: 'del.resume.list',
                    'resume_id': ids,
                }).then(res => {
                    if (res.status == 200) {
                        this.$message.success("删除成功！");
                        var hasLastPage = this.parameter.page_size * this.parameter.page_no >= this.parameter.total_result;
                        var lastData = this.tableData.length < 2;
                        if (lastData && hasLastPage && this.parameter.page_no > 1) {
                            this.parameter.page_no--;
                        };
                        this.getData();
                    }

                }).catch(err => {
                    console.log(err);
                });
            }).catch(() => {//用户取消

            });
        },
    },
};
</script>
<style scoped lang='scss'>
@import "@/styles/config.scss";
</style>
