<template>
  <div class="quill-editor">
    <!-- 图片上传组件辅助-->
    <el-upload style="width: 0; height: 0; overflow: hidden" class="avatar-uploader" action="#" name="img" :show-file-list="false" :before-upload="beforeUpload"></el-upload>
    <!--富文本编辑器组件-->
    <quill-editor v-model="content" :content="content" :options="editorOption" @blur="onEditorBlur($event)" @focus="onEditorFocus($event)" @ready="onEditorReady($event)" @change="onEditorChange" ref="QuillEditor"></quill-editor>
    <div v-if="maxLength!==0" style="text-align:right;margin-right:10px;margin-bottom:-5px">
      {{TiLength}}/{{maxLength}}
    </div>
  </div>
</template>

<script>
import { quillEditor } from 'vue-quill-editor'
import 'quill/dist/quill.core.css'
import 'quill/dist/quill.snow.css'
import 'quill/dist/quill.bubble.css'
import Axios from '@/axios'

const toolbarOptions = [
  ['bold', 'italic', 'underline', 'strike'], // 加粗，斜体，下划线，删除线
  ['blockquote', 'code-block'], //引用，代码块
  [{ header: 1 }, { header: 2 }], // 几级标题
  [{ list: 'ordered' }, { list: 'bullet' }], // 有序列表，无序列表
  [{ script: 'sub' }, { script: 'super' }], // 下角标，上角标
  [{ indent: '-1' }, { indent: '+1' }], // 缩进
  [{ direction: 'rtl' }], // 文字输入方向
  [{ size: ['small', false, 'large', 'huge'] }], // 字体大小
  [{ header: [1, 2, 3, 4, 5, 6, false] }], // 标题
  [{ color: [] }, { background: [] }], // 颜色选择
  [
    {
      font: [
        'SimSun',
        'SimHei',
        'Microsoft-YaHei',
        'KaiTi',
        'FangSong',
        'Arial',
      ],
    },
  ], // 字体
  [{ align: [] }], // 居中
  ['clean'], // 清除样式,
]
export default {
  components: {
    quillEditor,
  },
  props: {
    value: {
      type: String,
      default: '',
    },
    offUploadImg: {
      type: Boolean,
      default: () => {
        return true
      },
    },
    maxLength: {
      type: Number,
      default: 0,
    },
  },
  data() {
    return {
      name: 'register-modules-example',
      content: '',
      TiLength: 0,
      editorOption: {
        placeholder: '请在这里输入',
        theme: 'snow', //主题 snow/bubble
        modules: {
          history: {
            delay: 1000,
            maxStack: 50,
            userOnly: false,
          },
          toolbar: {
            container: toolbarOptions,
            handlers: {
              image: function (value) {
                if (value) {
                  // 调用element的图片上传组件
                  document.querySelector('.avatar-uploader input').click()
                } else {
                  this.quill.format('image', false)
                }
              },
            },
          },
        },
      },
      imageResultdUrl: '', // 服务器上返回的图片地址
    }
  },
  watch: {
    value: {
      immediate: true,
      deep: true,
      handler(newVal) {
        this.content = newVal
        // this.$emit('update:value', newVal)
      },
    },
  },
  created() {
    if (this.offUploadImg) {
      let container = JSON.parse(JSON.stringify(toolbarOptions))
      container.push(['image'])
      this.editorOption.modules.toolbar.container = container
    }
  },
  mounted() {
    this.content = this.value
  },
  methods: {
    // 失去焦点
    onEditorBlur(editor) {},
    // 获得焦点
    onEditorFocus(editor) {},
    // 开始
    onEditorReady(editor) {},
    // 值发生变化
    onEditorChange(editor) {
      // console.log(editor)
      if (this.maxLength === 0) {
        this.content = editor.html
        this.$emit('update:value', this.content)
      } else {
        if (editor.quill.getLength() - 1 <= this.maxLength) {
          this.content = editor.html
          this.TiLength = editor.quill.getLength() - 1
          this.$emit('update:value', this.content)
        } else {
          setTimeout(() => {
            this.content = this.value
            this.$emit('update:value', this.content)
          }, 100)
        }
      }
    },
    beforeUpload(file) {
      var form = new FormData()
      form.append('file', file)
      form.append('method', 'set.upload.figure')
      Axios({
        url: '/adminapi/v1/upload',
        method: 'post',
        data: form,
        headers: {
          accept: '*/*',
        },
        transformRequest: [
          function (data) {
            return data
          },
        ],
      })
        .then((res) => {
          this.uploadSuccess(res.data.url)
          //setTimeout(()=>{
          // arr[i].loading = false;
          // arr[i].fileUrl = res.data.url;
          //},10000)
        })
        .catch((err) => {})
    },
    uploadSuccess(url) {
      // 获取富文本组件实例
      let quill = this.$refs.QuillEditor.quill
      // 如果上传成功
      if (url) {
        // 获取光标所在位置
        let length = quill.getSelection().index
        // 插入图片，res为服务器返回的图片链接地址
        quill.insertEmbed(length, 'image', url)
        // 调整光标到最后
        quill.setSelection(length + 1)
      } else {
        // 提示信息，需引入Message
        this.$message.error('图片插入失败！')
      }
    },
  },
}
</script>
<style lang="scss" scoped>
/deep/.ql-container {
  height: 300px;
}
</style>
