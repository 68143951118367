<template>
  <el-dialog class="editDialog" :append-to-body="true" width="600px" :visible.sync="showEditDialog"
    :close-on-click-modal="false" :destroy-on-close="true" @close="$emit('update:isShow', false)" :show-close="true">
    <div slot="title" class="title">
      {{ options.position_id ? '编辑' : '新增' }}招聘岗位
    </div>

    <div class="content">
      <el-form label-width="6em" :model="form" :rules="rules" ref="form">
        <el-form-item label="岗位名称" prop="title" ref="title">
          <el-input v-model="form.title" placeholder="请输入岗位名称"></el-input>
        </el-form-item>

        <el-form-item label="是否热门" prop="is_hot" ref="is_hot">
          <el-select v-model="form.is_hot">
            <el-option :key="0" label="否" :value="0"></el-option>
            <el-option :key="1" label="是" :value="1"></el-option>
          </el-select>
        </el-form-item>

        <el-form-item label="排序" prop="sort" ref="sort">
          <el-input-number v-model="form.sort" controls-position="right" :min="1"></el-input-number>
        </el-form-item>
      </el-form>
    </div>

    <div slot="footer">
      <el-button type="success" :loading="submitLoading" @click="submit">保存</el-button>
      <el-button type="info" plain @click="$emit('update:isShow', false)">取消</el-button>
    </div>
  </el-dialog>
</template>
<script>
import { mobile, email } from '@/regular'

export default {
  props: {
    isShow: {
      type: Boolean,
      default: false,
    },
    options: {
      type: Object,
      default: function () {
        return {}
      },
    },
  },
  data() {
    return {
      showEditDialog: false,
      submitLoading: false,
      form: {},
      groupData: [],
      rules: {
        title: [
          {
            required: true,
            message: '请输入岗位名称',
            trigger: ['blur', 'change'],
          },
        ],
      },
    }
  },
  inject: ['refresh'],
  watch: {
    isShow: {
      immediate: true, //初始化立即执行
      handler: function (newVal) {
        this.$refs.form && this.$refs.form.resetFields()
        this.showEditDialog = newVal
      },
    },
    options: {
      immediate: true, //初始化立即执行
      deep: true, //对象深度监测
      handler: function (newVal, oldVal) {
        const xxx = newVal
        this.init(xxx)
      },
    },
  },
  created() { },
  methods: {
    init(options) {
      console.log(options)
      if (options.position_id) {
        //编辑进来的会有id需要
        this.form = options
        console.log(this.form)
      } else {
        this.form = {}
      }
    },
    submit() {
      this.$refs.form.validate((valid, obj) => {
        if (valid) {
          this.submitLoading = true
          this.$api.position
            .position(
              this.form.combination({
                module: 'admin',
                method: this.form.position_id
                  ? 'set.position.item'
                  : 'add.position.item',
              })
            )
            .then((res) => {
              this.submitLoading = false
              this.refresh(1)
              this.$message.success('操作成功！')
            })
            .catch((err) => {
              this.submitLoading = false
              console.log(err)
            })
        } else {
          this.scrollView(obj)
        }
      })
    },
  },
}
</script>
