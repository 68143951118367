<template>
    <div>
        <Crumbs></Crumbs>
        <div class="mainList">
            <div class="mainList_operation clearfloat">
                <div class="fr">
                    <el-button type="primary" icon="el-icon-plus" @click="add">新增</el-button>
                </div>
            </div>
            <div class="mainList_content">
                <div class="mainList_content_sub">
                    <el-table ref="multipleTable" border :data="tableData" tooltip-effect="dark" height="string" v-loading="loading">
                        <el-table-column label="序号" type="index" align="center" width="70"></el-table-column>
                        <el-table-column align="center" prop="name" label="等级名称">
                            <template slot-scope="scope">
                               <div class="icon_box">
                                   <i v-if="scope.row.icon" :class="scope.row.icon" class="iconfont"></i>
                                    <span>{{ scope.row.name }}</span>
                               </div>
                            </template>
                        </el-table-column>
                        <el-table-column align="center" prop="icon" label="等级图标"></el-table-column>
                        <el-table-column align="center" prop="discount" label="提现利率"></el-table-column>
                        <el-table-column align="center" prop="validaty" label="有效期（天）"></el-table-column>
                        <el-table-column align="center" prop="original_price" label="原价"></el-table-column>
                        <el-table-column align="center" prop="amount" label="首次优惠价"></el-table-column>
                        <el-table-column align="center" prop="point" label="赠送积分"></el-table-column>
                        <el-table-column prop="description" align="center" label="描述"></el-table-column>
                        <el-table-column label="操作" align="center" width="120">
                            <template slot-scope="scope">
                                <el-button type="text" size="small" @click="edit(scope)">编辑</el-button>
                                <el-button type="text" size="small" class="danger" @click="del(scope)">删除</el-button>
                            </template>
                        </el-table-column>
                    </el-table>
                    <el-pagination
                        @size-change="handleSizeChange"
                        @current-change="pageChangeHandler"
                        :current-page.sync="parameter.page_no"
                        :page-size="parameter.page_size"
                        prev-text="上一页"
                        next-text="下一页"
                        :total="parameter.total_result">
                    </el-pagination>
                </div>
            </div>
        </div>
        <Edit :isShow.sync="showEditDialog" :options="options"></Edit>

    </div>
</template>
<script>


export default {
    components: {
        Edit: () => import("./Edit.vue"),
        Crumbs: () => import("@/components/Crumbs.vue"),
    },
    data() {
        return {
            parameter:{
                method:'get.user.level.list',
                page_no:1,
                page_size:50,
                total_result:0,
            },
            options: {},
            tableData: [],
            loading: false,
            showEditDialog: false,
        };
    },
    provide () {
        return {
            refresh:this.refresh,
        }
    },
    watch: {},
    created() {
        this.getData();

    },
    methods: {
        refresh(){
            this.showEditDialog = false;
            this.pageChangeHandler(1);
        },
        pageChangeHandler(val) {
            this.parameter.page_no = val;
            this.getData();
        },
        handleSizeChange(val){
            this.parameter.page_size = val;
            this.parameter.page_no = 1;
            this.getData();
        },
        edit(scope) {
            this.options = scope.row;
            this.showEditDialog = true
        },
        add() {
            this.options = {};
            this.showEditDialog = true
        },
        getData() {
            this.loading = true;
            this.$api.user.user_level(this.parameter).then(res => {
                this.loading = false;
                this.tableData = res.data;
            }).catch(err => {
                console.log(err);
                this.loading = false;
            });
        },
        del(scope) {
            this.$confirm("此操作将永久删除该数据, 是否继续?", "提示", {
                confirmButtonText: "确定",
                cancelButtonText: "取消",
                type: "warning"
            }).then(() => {
                this.$api.user.user_level({
                    module:'admin',
                    method:'del.user.level.list',
                    'group_id':scope.row.group_id,
                }).then(res => {

                    this.$message.success("删除成功！");

                    var hasLastPage = this.parameter.page_size*this.parameter.page_no>=this.parameter.total_result;
                    var lastData = this.tableData.length<2;
                    if (lastData&&hasLastPage&&this.parameter.page_no>1) {
                        this.parameter.page_no --;
                    };
                    this.getData();


                }).catch(err => {
                    console.log(err);
                });
            }).catch(() => {//用户取消

            });
        },
    },
};
</script>
<style scoped lang='scss'>
@import "@/styles/config.scss";
.icon_box {
    display: inline-flex;
    justify-content: left;
    align-items: baseline;
    img {
        width: 30px;
        height: 30px;
        border-radius: 100%;
        margin-right: 5px;
    }
}
</style>
