<template>
  <el-dialog class="editDialog" :append-to-body="true" width="600px" :visible.sync="showEditDialog"
    :close-on-click-modal="false" :destroy-on-close="true" @close="$emit('update:isShow', false)" :show-close="true">
    <div slot="title" class="title">
      {{ options.ads_id ? '编辑' : '新增' }}广告版位
    </div>

    <div class="content" v-loading="loading">
      <el-form label-width="6em" :model="form" :rules="rules" ref="form">
        <el-form-item label="名称" prop="name">
          <el-input v-model="form.name" placeholder="请输入名称"></el-input>
        </el-form-item>

        <el-form-item label="平台" prop="platform">
          <el-radio-group v-model="form.platform" size="medium">
            <el-radio :label="1">pc</el-radio>
            <el-radio :label="2">web</el-radio>
          </el-radio-group>
        </el-form-item>

        <el-form-item label="跳转链接" prop="url">
          <el-input v-model="form.url" placeholder="请输入名称"></el-input>
        </el-form-item>

        <el-form-item label="打开方式" prop="target">
          <el-radio-group v-model="form.target" size="medium">
            <el-radio label="_self">本页面</el-radio>
            <el-radio label="_blank">新页面</el-radio>
          </el-radio-group>
        </el-form-item>

        <el-form-item label="描述" prop="description">
          <el-input type="textarea" v-model="form.description" placeholder="请输入标识"></el-input>
        </el-form-item>
        <el-form-item label="图片" prop="content" ref="content">
          <UploadImage v-model="form.content" />
        </el-form-item>
      </el-form>
    </div>

    <div slot="footer" v-show="!loading">
      <el-button type="success" :loading="submitLoading" @click="submit">保存</el-button>
      <el-button type="info" plain @click="$emit('update:isShow', false)">取消</el-button>
    </div>
  </el-dialog>
</template>
<script>
export default {
  components: {
    UploadImage: () => import('@/components/upload/Image.vue'),
  },
  props: {
    isShow: {
      type: Boolean,
      default: false,
    },
    options: {
      type: Object,
      default: function () {
        return {}
      },
    },
    data: {
      type: Object,
      default: function () {
        return {}
      },
    },
  },
  data() {
    console.log(0)
    return {
      showEditDialog: false,
      loading: true,
      submitLoading: false,
      form: {},
      rules: {
        name: [
          {
            required: true,
            message: '请输入名称',
            trigger: ['blur', 'change'],
          },
        ],
      },
    }
  },
  inject: ['refresh'],
  watch: {
    isShow: {
      immediate: true, //初始化立即执行
      handler: function (newVal) {
        console.log(1)
        this.showEditDialog = newVal
      },
    },
    options: {
      immediate: true, //初始化立即执行
      deep: true, //对象深度监测
      handler: function (newVal, oldVal) {
        // this.form = newVal.deepClone();
        // this.loading = false;
        this.init(newVal)
      },
    },
  },
  created() { },
  methods: {
    init(options) {
      this.form = options.deepClone()
      this.loading = false
      // if (options.ads_position_code_id) {
      //     this.$api.banner.banner({
      //         module: "admin",
      //         method: "get.ads.position.item",
      //         ads_position_id:options.ads_position_code_id,
      //     }).then( res => {
      //         this.loading = false;
      //     }).catch(err=>{
      //         this.loading = false;
      //         console.log(err);
      //     })
      //
      // }else {
      //     this.loading = false;
      // }
    },
    submit() {
      this.$refs.form.validate((valid, obj) => {
        if (valid) {
          this.submitLoading = true

          this.$api.banner
            .ads(
              this.form.combination({
                module: 'admin',
                method: this.form.ads_id ? 'set.ads.item' : 'add.ads.item',
                ads_position_id: this.data.ads_position_id,
                code: this.data.code,
              })
            )
            .then((res) => {
              this.submitLoading = false
              this.refresh(1)
              this.$message.success('操作成功！')
            })
            .catch((err) => {
              this.submitLoading = false
              console.log(err)
            })
        } else {
          this.scrollView(obj)
        }
      })
    },
  },
}
</script>
