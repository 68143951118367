<template>
    <div>
        <Crumbs></Crumbs>
        <div class="mainList">
            <div class="mainList_operation clearfloat">
                <div class="mainList_operation_search">
                    <el-input class="item" placeholder="请输入分类名称" clearable v-model="parameter.keywords"></el-input>
                    <el-select class="item" v-model="parameter.status">
                        <el-option label="请选择" value=""></el-option>
                        <el-option label="开启" value="1"></el-option>
                        <el-option label="禁用" value="2"></el-option>
                    </el-select>
                    <el-button type="primary" plain icon="el-icon-search" @click="pageChangeHandler(1)">搜索</el-button>
                </div>
                <div class="fr">
                    <el-button type="primary" icon="el-icon-plus" @click="add">新增</el-button>
                    <el-button v-if="selectRows.length > 0" type="danger" @click="handleDelete">批量删除</el-button>
                </div>

            </div>
            <div class="mainList_content">
                <div class="mainList_content_sub">
                    <el-table ref="multipleTable" border :data="tableDataTree" row-key="article_cat_id"
                        :tree-props="{ children: 'next_info' }" tooltip-effect="dark" height="string"
                        v-loading="loading" @selection-change="setSelectRows">
                        <!-- <el-table-column align="center" show-overflow-tooltip type="selection"></el-table-column> -->
                        <el-table-column align="center" prop="cat_name" label="分类名称"></el-table-column>
                        <el-table-column align="center" prop="status" label="状态">
                            <template slot-scope="scope">
                                {{ scope.row.status | showStatus }}
                            </template>
                        </el-table-column>
                        <el-table-column label="操作" align="center" width="160" fixed="right">
                            <template slot-scope="scope">
                                <el-button type="text" size="small" @click="edit(scope)">编辑</el-button>
                                <el-button type="text" size="small" class="danger"
                                    @click="handleDelete(scope)">删除</el-button>
                            </template>
                        </el-table-column>
                    </el-table>
                    <el-pagination @size-change="handleSizeChange" @current-change="pageChangeHandler"
                        :current-page.sync="parameter.page_no" :page-size="parameter.page_size" prev-text="上一页"
                        next-text="下一页" :total="parameter.total_result">
                    </el-pagination>
                </div>
            </div>
        </div>
        <Edit :isShow.sync="showEditDialog" :options="options"
            :list="tableData.filter(item => { return item.parent_id === 0 })"></Edit>
    </div>
</template>
<script>


export default {
    components: {
        Edit: () => import("./Edit.vue"),
        Crumbs: () => import("@/components/Crumbs.vue"),
    },
    data() {
        return {
            parameter: {
                method: 'get.article.cat.list',
                page_no: 1,
                page_size: 50,
                total_result: 0,
                keywords: '',
                status: ''
            },
            selectRows: [],
            activeData: {},
            options: {},
            tableData: [],
            tableDataTree: [],
            loading: false,
            showEditDialog: false,
            multipleSelection: [],
        };
    },
    provide() {
        return {
            refresh: this.refresh,
        }
    },
    watch: {},
    created() {
        this.getData()
        this.getDataTree()
    },
    methods: {
        setSelectRows(val) {
            this.selectRows = val
        },
        refresh() {
            this.showEditDialog = false;
            this.pageChangeHandler(1);
        },
        pageChangeHandler(val) {
            this.parameter.page_no = val;
            this.getData();
        },
        handleSizeChange(val) {
            this.parameter.page_size = val;
            this.parameter.page_no = 1;
            this.getData();
        },
        edit(scope) {
            this.options = JSON.parse(JSON.stringify(scope.row));
            this.showEditDialog = true
        },
        add() {
            this.options = {};
            this.showEditDialog = true

        },
        getDataTree() {
            this.$api.articleCat.articleCategory({
                method: 'get.article.cat.tree'
            }).then(res => {
                console.log(res);
                this.tableDataTree = res.data
            })
        },
        getData() {
            this.loading = true;
            this.$api.articleCat.articleCategory(this.parameter).then(res => {
                this.loading = false;
                if (res.data.items && Array.isArray(res.data.items)) {
                    this.tableData = res.data.items;
                } else {
                    this.tableData = [];
                };
                this.parameter.total_result = res.data.total_result;
            }).catch(err => {
                console.log(err);
                this.loading = false;
            });
        },
        handleDelete(scope) {
            console.log('row:', scope.row);
            const ids = [];
            if (scope.row.article_cat_id) {
                ids.push(scope.row.article_cat_id);
            } else {
                if (this.selectRows.length > 0) {
                    const itemIds = this.selectRows.map((item) => item.article_cat_id);
                    for (var i = 0; i < itemIds.length; i++) {
                        ids.push(itemIds[i]);
                    }
                } else {
                    this.$message.error('未选中任何行');
                    return false
                }
            }
            this.$confirm("此操作将永久删除该数据, 是否继续?", "提示", {
                confirmButtonText: "确定",
                cancelButtonText: "取消",
                type: "warning"
            }).then(() => {
                this.$api.articleCat.articleCategory({
                    module: 'admin',
                    method: 'del.article.cat.list',
                    'article_cat_id': ids,
                }).then(res => {
                    if (res.status == 200) {
                        this.$message.success("删除成功！");
                        var hasLastPage = this.parameter.page_size * this.parameter.page_no >= this.parameter.total_result;
                        var lastData = this.tableData.length < 2;
                        if (lastData && hasLastPage && this.parameter.page_no > 1) {
                            this.parameter.page_no--;
                        };
                        this.getData();
                    }

                }).catch(err => {
                    console.log(err);
                });
            }).catch(() => {//用户取消

            });
        },
    },
};
</script>
<style scoped lang='scss'>
@import "@/styles/config.scss";
</style>
