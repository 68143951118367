<template>
  <el-dialog class="editDialog" :append-to-body="true" width="600px" :visible.sync="showEditDialog"
    :close-on-click-modal="false" :destroy-on-close="true" @close="$emit('update:isShow', false)" :show-close="true">
    <div slot="title" class="title">
      {{ options.resume_id ? "编辑" : "新增" }}简历
    </div>

    <div class="content">
      <el-form label-width="6em" :model="form" :rules="rules" ref="form">
        <el-form-item label="简历名称" prop="title" ref="title">
          <el-input v-model="form.title" placeholder="请输入简历名称"></el-input>
        </el-form-item>
        <el-form-item label="用户昵称">
          {{ form.nickname }}
        </el-form-item>
        <el-form-item label="用户头像" prop="head_pic" ref="head_pic">
          <UploadImage v-model="form.head_pic" />
        </el-form-item>
        <el-form-item label="手机号码" prop="mobile" ref="mobile">
          <el-input v-model="form.mobile"></el-input>
        </el-form-item>
        <el-form-item label="邮箱" prop="email" ref="email">
          <el-input v-model="form.email"></el-input>
        </el-form-item>
        <el-form-item label="意向岗位" prop="position" ref="position">
          <el-select v-model="form.position" multiple collapse-tags placeholder="请选择">
            <el-option v-for="item in positionOptions" :key="item.position_id" :label="item.title"
              :value="item.position_id">
            </el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="地址" prop="address" ref="address">
          <el-input v-model="form.address"></el-input>
        </el-form-item>
        <el-form-item label="工作年限" prop="year" ref="year">
          <el-input v-model="form.year"></el-input>
        </el-form-item>
        <el-form-item label="学历" prop="education" ref="education">
          <el-select v-model="form.education" placeholder="请选择学历">
            <el-option label="不限" value="不限"></el-option>
            <el-option label="初中及以下" value="初中及以下"></el-option>
            <el-option label="中专/技校" value="中专/技校"></el-option>
            <el-option label="高中" value="高中"></el-option>
            <el-option label="大专" value="大专"></el-option>
            <el-option label="本科" value="本科"></el-option>
            <el-option label="硕士" value="硕士"></el-option>
            <el-option label="博士" value="博士"></el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="年龄" prop="age" ref="age">
          <el-input v-model="form.age"></el-input>
        </el-form-item>
        <el-form-item label="期望薪资" prop="salary" ref="salary">
          <el-input v-model="form.salary"></el-input>
        </el-form-item>
        <el-form-item label="到岗时间" prop="position_time" ref="position_time">
          <el-select v-model="form.position_time" placeholder="请选择到岗时间">
            <el-option label="在职，1个月可到岗" value="在职，1个月可到岗"></el-option>
            <el-option label="离职，可随时到岗" value="离职，可随时到岗"></el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="简介" prop="introduction" ref="introduction">
          <el-input type="textarea" :rows="2" placeholder="请输入简介" v-model="form.introduction">
          </el-input>
        </el-form-item>
        <el-form-item label="工作经历" prop="experience" ref="experience">
          <div class="item-view m-b-10" v-for="(item, index) in form.experience" :key="index">
            <el-card class="box-card">
              <el-row type="flex" align="middle" justify="space-between">
                <el-col :span="20">
                  <div class="m-b-10">
                    <el-input v-model="item.companyName" placeholder="请输入公司名称"></el-input>
                  </div>
                  <div class="m-b-10">
                    <el-row type="flex">
                      <el-col :span="10">
                        <el-date-picker v-model="item.start_time" type="date" placeholder="选择入职日期"></el-date-picker>
                      </el-col>
                      <el-col :span="4" style="text-align: center;">至</el-col>
                      <el-col :span="10">
                        <el-date-picker v-model="item.end_time" type="date" placeholder="选择离职日期"></el-date-picker>
                      </el-col>
                    </el-row>
                  </div>
                  <div class="m-b-10">
                    <el-select v-model="item.jobId" placeholder="请选择岗位">
                      <el-option v-for="item in positionOptions" :key="item.position_id" :label="item.title"
                        :value="item.position_id">
                      </el-option>
                    </el-select>
                  </div>
                  <div>
                    <el-input v-model="item.desc" placeholder="岗位描述"></el-input>
                  </div>
                </el-col>
                <el-col :span="3" style="text-align: center;">
                  <el-button type="danger" icon="el-icon-delete" circle
                    @click="removeExperienceItem(index)"></el-button>
                </el-col>
              </el-row>
            </el-card>
          </div>
          <el-button @click="addExperienceItem">新增工作经历</el-button>
        </el-form-item>

        <el-form-item label="教育经历" prop="education_experience" ref="education_experience">
          <div class="item-view m-b-10" v-for="(item, index) in form.education_experience" :key="index">
            <el-card class="box-card">
              <el-row type="flex" align="middle" justify="space-between">
                <el-col :span="20">
                  <div class="m-b-10">
                    <el-input v-model="item.school" placeholder="请输入学校名称"></el-input>
                  </div>
                  <div class="m-b-10">
                    <el-row type="flex">
                      <el-col :span="10">
                        <el-date-picker v-model="item.start_time" type="date" placeholder="选择入学日期"></el-date-picker>
                      </el-col>
                      <el-col :span="4" style="text-align: center;">至</el-col>
                      <el-col :span="10">
                        <el-date-picker v-model="item.end_time" type="date" placeholder="选择毕业日期"></el-date-picker>
                      </el-col>
                    </el-row>
                  </div>
                  <div class="m-b-10">
                    <el-input v-model="item.qualification" placeholder="学历/学位"></el-input>
                  </div>
                  <div>
                    <el-input v-model="item.major" placeholder="专业"></el-input>
                  </div>
                </el-col>
                <el-col :span="3" style="text-align: center;">
                  <el-button type="danger" icon="el-icon-delete" circle
                    @click="removeEducationExperienceItem(index)"></el-button>
                </el-col>
              </el-row>
            </el-card>
          </div>
          <el-button @click="addEducationExperience">新增教育经历</el-button>
        </el-form-item>

        <el-form-item label="技能特长" prop="speciality" ref="speciality">
          <div class="m-b-10">
            <el-tag v-for="(tag, index) in form.speciality" :key="index" closable @close="handleClose(tag)">
              {{ tag.text }}
            </el-tag>
            <el-input class="input-new-tag" v-if="inputVisible" v-model="inputValue" ref="saveTagInput" size="small"
              @keyup.enter.native="handleInputConfirm" @blur="handleInputConfirm">
            </el-input>
            <el-button v-else class="button-new-tag" size="small" @click="showInput">+ 新增技能特长</el-button>
          </div>
        </el-form-item>
      </el-form>
    </div>

    <div slot="footer">
      <el-button type="success" :loading="submitLoading" @click="submit">保存</el-button>
      <el-button type="info" plain @click="$emit('update:isShow', false)">取消</el-button>
    </div>
  </el-dialog>
</template>
<script>
import { mobile, email } from "@/regular";

export default {
  props: {
    isShow: {
      type: Boolean,
      default: false,
    },
    options: {
      type: Object,
      default: function () {
        return {};
      },
    },
  },
  components: {
    UploadImage: () => import('@/components/upload/Image.vue'),
  },
  data() {
    return {
      showEditDialog: false,
      submitLoading: false,
      form: {},
      groupData: [],
      positionOptions: [],
      inputVisible: false,
      inputValue: '',
      rules: {
        title: [
          {
            required: true,
            message: "请输入标题",
            trigger: ["blur", "change"],
          },
        ],
      },
    };
  },
  inject: ["refresh"],
  watch: {
    isShow: {
      immediate: true, //初始化立即执行
      handler: function (newVal) {
        this.$refs.form && this.$refs.form.resetFields();
        this.showEditDialog = newVal;
      },
    },
    options: {
      immediate: true, //初始化立即执行
      deep: true, //对象深度监测
      handler: function (newVal, oldVal) {
        const xxx = newVal;
        this.init(xxx);
      },
    },
  },
  created() { },
  methods: {
    initPositionOptions() {
      this.$api.position.position(
        this.form.combination({
          module: "admin",
          method: "get.position.select",
        })
      ).then((res) => {
        if (res.data && Array.isArray(res.data)) {
          this.positionOptions = res.data;
        }
      })
        .catch((err) => {
          console.log(err);
        });
    },
    init(options) {
      console.log(options)
      this.initPositionOptions()
      if (options.resume_id) {
        //编辑进来的会有id需要
        this.form = JSON.parse(JSON.stringify(options))
        console.log(this.form)
      } else {
        this.form = {}
      }
    },
    addExperienceItem() {
      this.form.experience.push({})
    },
    removeExperienceItem(index) {
      this.form.experience.splice(index, 1)
    },
    addEducationExperience() {
      this.form.education_experience.push({})
    },
    removeEducationExperienceItem(index) {
      this.form.education_experience.splice(index, 1)
    },
    handleClose(tag) {
      const index = this.form.speciality.findIndex(item => {
        return item.text === tag.text
      })
      this.form.speciality.splice(index, 1);
    },
    showInput() {
      this.inputVisible = true;
      this.$nextTick(_ => {
        this.$refs.saveTagInput.$refs.input.focus();
      });
    },

    handleInputConfirm() {
      let inputValue = this.inputValue;
      if (inputValue) {
        this.form.speciality.push({ text: inputValue });
      }
      this.inputVisible = false;
      this.inputValue = '';
    },
    submit() {
      this.$refs.form.validate((valid, obj) => {
        if (valid) {
          this.submitLoading = true;
          this.$api.resume.resume(
            this.form.combination({
              module: "admin",
              method: this.form.resume_id ? "set.resume.item" : "add.resume.item",
            })
          )
            .then((res) => {
              this.submitLoading = false;
              this.refresh(1);
              this.$message.success("操作成功！");
            })
            .catch((err) => {
              this.submitLoading = false;
              console.log(err);
            });
        } else {
          this.scrollView(obj);
        }
      });
    },
  },
};
</script>
<style lang="scss" scoped>
.m-b-10 {
  margin-bottom: 10px;
}

.el-tag+.el-tag {
  margin-left: 10px;
}

.button-new-tag {
  margin-left: 10px;
  height: 32px;
  line-height: 30px;
  padding-top: 0;
  padding-bottom: 0;
}

.input-new-tag {
  width: 90px;
  margin-left: 10px;
  vertical-align: bottom;
}
</style>