<template>
    <div>
        <Crumbs></Crumbs>
        <div class="mainList">
            <div class="mainList_operation clearfloat">
                <div class="mainList_operation_search">
                    <el-input class="item" placeholder="请输入用户名" clearable v-model="parameter.username"></el-input>
                    <el-input class="item" placeholder="请输入访问路径" clearable v-model="parameter.path"></el-input>
                    <el-select clearable class="item"  v-model="parameter.status" placeholder="请选择状态">
                        <el-option
                            v-for="item in statusData"
                            :key="item.status_id"
                            :label="item.name"
                            :value="item.status_id">
                        </el-option>
                    </el-select>
                    <el-date-picker
                    class="mr10"
                    format="yyyy 年 MM 月 dd 日"
                    v-model="logDaterange"
                    @change="daterangeFc"
                    value-format="yyyy-MM-dd HH:mm:ss"
                    :default-time="['00:00:00', '23:59:59']"
                    type="daterange"
                    align="right"
                    unlink-panels
                    range-separator="-"
                    start-placeholder="开始日期"
                    end-placeholder="结束日期"
                    ></el-date-picker>
                    <el-button type="primary" plain icon="el-icon-search" @click="pageChangeHandler(1)">搜索</el-button>
                </div>
            </div>
            <div class="mainList_content">
                <div class="mainList_content_sub">
                    <el-table ref="multipleTable" border :data="tableData" tooltip-effect="dark" height="string" v-loading="loading">
                        <el-table-column label="序号" type="index" align="center" width="70"></el-table-column>
                        <el-table-column align="center" prop="username" label="用户名"></el-table-column>
                        <el-table-column align="center" prop="path" label="访问路径"></el-table-column>
                        <el-table-column align="center" prop="create_time" label="操作时间"></el-table-column>
                        <el-table-column align="center" prop="result" label="处理结果"></el-table-column>
                        <el-table-column align="center" prop="status" label="状态">
                            <template slot-scope="scope">
                                {{  scope.row.status | status  }}
                            </template>
                        </el-table-column>
                    </el-table>
                    <el-pagination
                        @size-change="handleSizeChange"
                        @current-change="pageChangeHandler"
                        :current-page.sync="parameter.page_no"
                        :page-size="parameter.page_size"
                        prev-text="上一页"
                        next-text="下一页"
                        :total="parameter.total_result">
                    </el-pagination>
                </div>
            </div>
        </div>
    </div>
</template>
<script>


export default {
    components: {
        Crumbs: () => import("@/components/Crumbs.vue"),
    },
    data() {
        return {
            statusData: [
                {
                    status_id: 0,
                    name: '失败'
                },
                {
                    status_id: 1,
                    name: '成功'
                }
            ],
            logDaterange: '',
            parameter:{
                method:'get.action.log.list',
                page_no:1,
                page_size:50,
                total_result:0
            },
            tableData: [],
            loading: false
        };
    },
    filters: {
        status(value) {
            return value==1?'成功':'失败';
        }
    },
    provide () {
        return {
            refresh:this.refresh,
        }
    },
    watch: {},
    created() {
        this.getData();

    },
    methods: {
        refresh(){
            this.showEditDialog = false;
            this.pageChangeHandler(1);
        },
        pageChangeHandler(val) {
            this.parameter.page_no = val;
            this.getData();
        },
        handleSizeChange(val){
            this.parameter.page_size = val;
            this.parameter.page_no = 1;
            this.getData();
        },
        daterangeFc(val) {
            if (!val) return
            this.parameter.begin_time = val[0];
            this.parameter.end_time = val[1];
        },
        getData() {
            this.loading = true;
            console.log(this.logDaterange)
            this.$api.log.action_log(this.parameter).then(res => {
                this.loading = false;
                this.tableData = res.data.items;
                this.parameter.total_result = res.data.total_result;
            }).catch(err => {
                console.log(err);
                this.loading = false;
            });
        }
    },
};
</script>
<style scoped lang='scss'>
@import "@/styles/config.scss";
</style>
