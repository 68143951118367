<template>
  <div>
    <Crumbs></Crumbs>
    <div class="mainList">
      <div class="mainList_content">
        <div class="mainList_content_sub">
          <el-table ref="multipleTable" border stripe :data="tableData" tooltip-effect="dark" height="string"
            v-loading="loading">
            <el-table-column align="left" prop="get_user.nickname" label="用户昵称"></el-table-column>
            <el-table-column align="left" prop="type_text" label="收藏类型"></el-table-column>
            <el-table-column align="left" prop="update_time" label="收藏时间"></el-table-column>
            <el-table-column align="left" prop="title" label="收藏内容标题"></el-table-column>
          </el-table>
          <el-pagination @size-change="handleSizeChange" @current-change="pageChangeHandler"
            :current-page.sync="parameter.page_no" :page-size="parameter.page_size" prev-text="上一页" next-text="下一页"
            :total="parameter.total_result">
          </el-pagination>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  components: {
    Crumbs: () => import('@/components/Crumbs.vue'),
  },
  data() {
    return {
      statusData: [
        {
          status_id: 0,
          name: '失败',
        },
        {
          status_id: 1,
          name: '成功',
        },
      ],
      logDaterange: '',
      parameter: {
        method: 'get.user_collection.list',
        page_no: 1,
        page_size: 50,
        total_result: 0,
      },
      tableData: [],
      loading: false,
    }
  },
  filters: {
    status(value) {
      return value == 1 ? '成功' : '失败'
    },
  },
  provide() {
    return {
      refresh: this.refresh,
    }
  },
  watch: {},
  created() {
    this.getData()
  },
  methods: {
    refresh() {
      this.showEditDialog = false
      this.pageChangeHandler(1)
    },
    pageChangeHandler(val) {
      this.parameter.page_no = val
      this.getData()
    },
    handleSizeChange(val) {
      this.parameter.page_size = val
      this.parameter.page_no = 1
      this.getData()
    },
    daterangeFc(val) {
      if (!val) return
      this.parameter.begin_time = val[0]
      this.parameter.end_time = val[1]
    },
    getData() {
      this.loading = true
      this.$api.user
        .user_collection(this.parameter)
        .then((res) => {
          this.loading = false
          this.tableData = res.data.items
          this.parameter.total_result = res.data.total_result
        })
        .catch((err) => {
          console.log(err)
          this.loading = false
        })
    },
  },
}
</script>
<style scoped lang="scss">
@import '@/styles/config.scss';
</style>
