<template>
  <div>
    <Crumbs></Crumbs>
    <div class="mainList">
      <div class="mainList_operation clearfloat">
        <div class="mainList_operation_search">
          <el-select clearable class="item" v-model="parameter.status" placeholder="请选择状态">
            <el-option v-for="item in statusData" :key="item.status_id" :label="item.name" :value="item.status_id">
            </el-option>
          </el-select>
          <el-button type="primary" plain icon="el-icon-search" @click="getData()">搜索</el-button>
        </div>
        <div v-if="multipleSelection.length" class="fr">
          <el-button @click="changeStatusMore()">批量通过</el-button>
          <el-button @click="toggleSelection()">取消选择</el-button>
        </div>
      </div>
      <div class="mainList_content">
        <div class="mainList_content_sub">
          <el-table ref="multipleTable" border :data="tableData" tooltip-effect="dark" v-loading="loading"
            @selection-change="handleSelectionChange">
            <el-table-column type="selection" width="55" :selectable="selectable"></el-table-column>
            <el-table-column align="left" label="用户昵称">
              <template slot-scope="scope">
                <div class="img_box">
                  <el-avatar v-if="scope.row.head_pic" :src="scope.row.head_pic" size="small"></el-avatar>
                  <span> {{ scope.row.nickname }}</span>
                </div>
              </template>
            </el-table-column>
            <el-table-column align="center" prop="parent_name" label="笔记作者"></el-table-column>
            <el-table-column align="center" prop="parent_content" label="笔记内容"></el-table-column>
            <el-table-column align="center" prop="content" label="回复内容"></el-table-column>
            <el-table-column align="center" prop="give_num" label="点赞数量"></el-table-column>
            <el-table-column align="center" prop="create_time" label="回复时间"></el-table-column>
            <el-table-column align="center" prop="status" label="审核状态">
              <template slot-scope="scope">
                {{ scope.row.status + '' | dict(stausTextList, 'id') }}
              </template>
            </el-table-column>
            <el-table-column align="center" prop="remarks" label="驳回理由"></el-table-column>
            <el-table-column align="center" label="驳回时间">
              <template slot-scope="scope">
                {{ scope.row.delete_time || '' }}
              </template>
            </el-table-column>
            <el-table-column label="操作" align="center" width="200"
              v-if="permission(['NOTEREPLY_REFUSE', 'NOTE_ADOPT'])">
              <template slot-scope="scope">
                <el-button v-if="
                  permission(['NOTEREPLY_REFUSE']) &&
                  ![1, 2].includes(+scope.row.status)
                " type="text" @click="changeStatus(scope, 2)">拒绝</el-button>
                <el-button v-if="
                  permission(['NOTE_ADOPT']) &&
                  ![1, 2].includes(+scope.row.status)
                " type="text" size="small" @click="changeStatus(scope)">通过</el-button>
              </template>
            </el-table-column>
          </el-table>
          <el-pagination @size-change="handleSizeChange" @current-change="pageChangeHandler"
            :current-page.sync="parameter.page_no" :page-size="parameter.page_size" prev-text="上一页" next-text="下一页"
            :total="parameter.total_result">
          </el-pagination>
        </div>
      </div>
    </div>
    <Edit :isShow.sync="showEditDialog" :options="options" :catTreeData="tableData"></Edit>
  </div>
</template>
<script>
export default {
  components: {
    Edit: () => import('./Edit.vue'),
    Crumbs: () => import('@/components/Crumbs.vue'),
  },
  data() {
    return {
      parameter: {
        method: 'get.course_note_reply.list',
      },
      options: {},
      tableData: [],
      loading: false,
      showEditDialog: false,
      showViewReplyDialog: false,
      multipleSelection: [],
      stausTextList: [
        {
          id: '0',
          name: '待审核',
        },
        {
          id: '1',
          name: '审核通过',
        },
        {
          id: '2',
          name: '已驳回',
        },
      ],
      statusData: [
        {
          status_id: 2,
          name: '驳回',
        },
        {
          status_id: 1,
          name: '通过',
        },
        {
          status_id: 0,
          name: '待审核',
        },
      ],
    }
  },
  provide() {
    return {
      refresh: this.refresh,
    }
  },
  watch: {},
  created() {
    this.getData()
  },
  methods: {
    refresh() {
      this.showEditDialog = false
      this.pageChangeHandler(1)
    },
    pageChangeHandler(val) {
      this.parameter.page_no = val
      this.getData()
    },
    handleSizeChange(val) {
      this.parameter.page_size = val
      this.parameter.page_no = 1
      this.getData()
    },
    getData() {
      this.loading = true
      this.$api.course
        .course_note_reply(this.parameter)
        .then((res) => {
          this.loading = false
          this.tableData = res.data.items
          this.parameter.total_result = res.data.total_result
        })
        .catch((err) => {
          console.log(err)
          this.loading = false
        })
    },
    edit(scope) {
      this.options = JSON.parse(JSON.stringify(scope.row))
      this.showEditDialog = true
    },
    changeStatus(scope, status = 1) {
      if (status != 1) {
        this.edit(scope)
      } else {
        this.changeStatusFc(status, [scope.row.course_note_reply_id])
      }
    },
    changeStatusMore(status = 1) {
      const stausList = []
      this.multipleSelection.forEach((item) =>
        stausList.push(item.course_note_reply_id)
      )
      this.changeStatusFc(status, stausList)
    },
    changeStatusFc(status, statusData) {
      this.$confirm(
        `此操作将${status == 1 ? '通过' : '拒绝'}该数据, 是否继续?`,
        '提示',
        {
          confirmButtonText: '确定',
          cancelButtonText: '取消',
          type: 'warning',
        }
      )
        .then(() => {
          this.$api.course
            .course_note_reply({
              method: 'set.course_note_reply.status',
              status,
              course_note_reply_id: statusData,
            })
            .then((res) => {
              this.$message.success(`${status == 1 ? '通过' : '拒绝'}成功！`)
              this.getData()
            })
            .catch((err) => {
              console.log(err)
            })
        })
        .catch(() => {
          //用户取消
        })
    },
    toggleSelection(rows) {
      if (rows) {
        rows.forEach((row) => {
          this.$refs.multipleTable.toggleRowSelection(row)
        })
      } else {
        this.$refs.multipleTable.clearSelection()
      }
    },
    handleSelectionChange(val) {
      this.multipleSelection = val
    },
    selectable(row, index) {
      if (row.status) {
        return false
      } else {
        return true
      }
    },
  },
}
</script>
<style scoped lang="scss">
@import '@/styles/config.scss';

.img_box {
  display: inline-flex;
  justify-content: left;
  align-items: flex-end;

  span {
    margin-left: 5px;
  }
}
</style>
