<template>
  <el-dialog class="editDialog" :append-to-body="true" width="600px" :visible.sync="showEditDialog"
    :close-on-click-modal="false" :destroy-on-close="true" @close="$emit('update:isShow', false)" :show-close="true">
    <div slot="title" class="title">
      {{ options.ads_position_id ? '编辑' : '新增' }}广告版位
    </div>

    <div class="content" v-loading="loading">
      <el-form label-width="6em" :model="form" :rules="rules" ref="form">
        <el-form-item label="名称" prop="name">
          <el-input v-model="form.name" placeholder="请输入名称"></el-input>
        </el-form-item>

        <el-form-item label="标识" prop="code">
          <el-input v-model="form.code" placeholder="请输入标识"></el-input>
        </el-form-item>

        <!-- <el-form-item label="平台" prop="platform">
          <el-radio-group v-model="form.platform" size="medium">
            <el-radio label="1">pc</el-radio>
            <el-radio label="2">web</el-radio>
          </el-radio-group>
        </el-form-item> -->

        <el-form-item label="描述" prop="description">
          <el-input type="textarea" v-model="form.description" placeholder="请输入标识"></el-input>
        </el-form-item>
      </el-form>
    </div>

    <div slot="footer" v-show="!loading">
      <el-button type="success" :loading="submitLoading" @click="submit">保存</el-button>
      <el-button type="info" plain @click="$emit('update:isShow', false)">取消</el-button>
    </div>
  </el-dialog>
</template>
<script>
export default {
  props: {
    isShow: {
      type: Boolean,
      default: false,
    },
    options: {
      type: Object,
      default: function () {
        return {}
      },
    },
  },
  data() {
    return {
      showEditDialog: false,
      loading: true,
      submitLoading: false,
      form: {},
      rules: {
        name: [
          {
            required: true,
            message: '请输入名称',
            trigger: ['blur', 'change'],
          },
        ],
        code: [
          {
            required: true,
            message: '请输入代码',
            trigger: ['blur', 'change'],
          },
        ],
      },
    }
  },
  inject: ['refresh'],
  watch: {
    isShow: {
      immediate: true, //初始化立即执行
      handler: function (newVal) {
        this.showEditDialog = newVal
      },
    },
    options: {
      immediate: true, //初始化立即执行
      deep: true, //对象深度监测
      handler: function (newVal, oldVal) {
        // this.form = newVal.deepClone();
        // this.loading = false;
        this.init(newVal)
      },
    },
  },
  created() { },
  methods: {
    init(options) {
      this.form = options.deepClone()
      this.loading = false
      // if (options.ads_position_id) {
      //
      //
      // }else {
      //     this.loading = false;
      // }
    },
    submit() {
      this.$refs.form.validate((valid, obj) => {
        if (valid) {
          this.submitLoading = true

          this.$api.banner
            .banner(
              this.form.combination({
                module: 'admin',
                method: this.form.ads_position_id
                  ? 'set.ads.position.item'
                  : 'add.ads.position.item',
              })
            )
            .then((res) => {
              this.submitLoading = false
              this.refresh(1)
              this.$message.success('操作成功！')
            })
            .catch((err) => {
              this.submitLoading = false
              console.log(err)
            })
        } else {
          this.scrollView(obj)
        }
      })
    },
  },
}
</script>
