<template>
    <el-dialog class="editDialog" :append-to-body="true" width="600px" :visible.sync="showEditDialog"
        :close-on-click-modal="false" :destroy-on-close="true" @close="$emit('update:isShow', false)"
        :show-close="true">

        <div slot="title" class="title">{{ options.menu_id ? '编辑' : '新增' }}菜单</div>
        <div class="content" v-loading="loading">
            <el-form class="column2" label-width="6em" :model="form" :rules="rules" ref="form">
                <el-form-item label="父级菜单" prop="parent_id" ref="parent_id">
                    <Treeselect :normalizer="normalizer" @input="changeParentId" placeholder="请选择父级菜单"
                        v-model="form.parent_id" :options="treeselectOptions" />
                </el-form-item>
                <el-form-item :label="form.is_navi == 1 ? '菜单名称' : '按钮名称'" prop="name">
                    <el-input v-model="form.name" :placeholder="`请输入${form.typeId == 1 ? '按钮' : '菜单'}名称`"></el-input>
                </el-form-item>
                <el-form-item label="权限标识" prop="marking">
                    <el-input v-model="form.marking" placeholder="请输入权限标识"></el-input>
                </el-form-item>
                <el-form-item label="类型" prop="is_navi">
                    <el-radio-group v-model="form.is_navi" size="medium">
                        <el-radio :label="1">菜单</el-radio>
                        <el-radio :label="0">按钮</el-radio>
                    </el-radio-group>
                </el-form-item>
                <el-form-item label="linkto" prop="href" v-if="form.is_navi === 1">
                    <el-input v-model="form.linkto" placeholder="请输入linkto"></el-input>
                </el-form-item>
                <el-form-item label="组件路径" prop="component_url" v-if="form.is_navi === 1">
                    <el-input v-model="form.component_url" placeholder="请输入组件路径"></el-input>
                </el-form-item>
                <el-form-item label="图标" prop="icon" v-if="form.is_navi === 1">
                    <el-autocomplete popperClass="autocompleteIcon" v-model="form.icon"
                        :fetch-suggestions="regularQuerySearch" placeholder="请输入图标" @select="regularHandleSelect">
                        <div slot-scope="{item}" :class="`iconfont icon${item['font_class']}`"></div>
                    </el-autocomplete>
                </el-form-item>
                <el-form-item label="排序" prop="sort" v-if="form.is_navi === 1">
                    <el-input v-model="form.sort" placeholder="请输入排序"></el-input>
                </el-form-item>
                <el-form-item label="是否启用" prop="status" v-if="form.is_navi === 1">
                    <el-radio-group v-model="form.status" size="medium">
                        <el-radio :label="1">启用</el-radio>
                        <el-radio :label="0">禁用</el-radio>
                    </el-radio-group>
                </el-form-item>
            </el-form>
        </div>

        <div slot="footer" v-show="!loading">
            <el-button type="success" :loading="submitLoading" @click="submit">保存</el-button>
            <el-button type="info" plain @click="$emit('update:isShow', false)">取消</el-button>
        </div>

    </el-dialog>

</template>
<script>
import { initTreeData } from '@/util/common'
import '@riophae/vue-treeselect/dist/vue-treeselect.css'

import iconJson from '@/styles/icon/iconfont.json'

export default {
    components: {
        Treeselect: () => import('@riophae/vue-treeselect'),
    },
    props: {
        isShow: {
            type: Boolean,
            default: false
        },
        options: {
            type: Object,
            default: function () {
                return {}
            }
        },
        treeselectOptions: {
            type: Array,
            default: function () {
                return []
            }
        },
        listData: {
            type: Array,
            default: function () {
                return []
            }
        }
    },
    data() {
        return {
            showEditDialog: false,
            loading: true,
            submitLoading: false,
            form: {
                parent_id: 0,
                is_navi: 1,
                status: 1,
            },
            rules: {
                parent_id: [
                    { required: true, message: '请选择父级菜单', trigger: ['blur', 'change'] },
                    {
                        trigger: ['change', 'blur'],
                        validator: (rule, value, callback) => {
                            if (this.form.menu_id == value) {
                                callback(new Error('父级菜单不能为自身'));
                            };
                            callback();
                        }
                    }
                ],
                is_navi: [{ required: true, message: '请选择类型', trigger: ['blur', 'change'] }],
                marking: [
                    { required: true, message: '请填写权限标识', trigger: ['blur', 'change'] },
                    {
                        trigger: ['change', 'blur'],
                        validator: (rule, value, callback) => {
                            if (this.listData.some(r => { return r.menu_id != this.form.menu_id && r.marking == value })) {
                                callback(new Error('权限标识不能重复'));
                            };
                            callback();
                        }
                    }
                ],

            },
        };
    },
    inject: ['refresh'],
    watch: {
        isShow: {
            immediate: true,//初始化立即执行
            handler: function (newVal) {
                // this.$emit('update:loading', false);
                this.showEditDialog = newVal;
            }
        },
        options: {
            immediate: true,//初始化立即执行
            deep: true,//对象深度监测
            handler: function (newVal, oldVal) {
                this.init(newVal);
            }
        }
    },
    created() { },
    methods: {

        init(options) {
            if (options.menu_id) {//编辑进来的会有id需要
                this.loading = true;

                this.$api.menu.menu({
                    module: 'admin',
                    menu_id: options.menu_id,
                    method: 'get.menu.item',
                }).then(res => {

                    this.form = res.data;
                    this.loading = false;


                }).catch(err => {
                    this.loading = false;
                    console.log(err);
                })

            } else {
                this.form = {
                    parent_id: 0,
                    is_navi: 1,
                    status: 1,
                };
                this.loading = false;
            };
        },
        regularQuerySearch(queryString, cb) {
            var restaurants = iconJson.glyphs;
            var results = queryString ? restaurants.filter(this.regularCreateFilter(queryString)) : restaurants;
            // 调用 callback 返回建议列表的数据
            cb(results);
        },
        regularCreateFilter(queryString) {
            return (restaurant) => {
                return (`${iconJson['css_prefix_text']}${restaurant['font_class']}`.toLowerCase().indexOf(queryString.toLowerCase()) === 0);
            };
        },
        regularHandleSelect(item) {
            this.form.icon = `${iconJson['css_prefix_text']}${item['font_class']}`;
        },
        normalizer(node) {
            return {
                id: node.menu_id,
                label: node.name,
                children: node.children,
            }
        },
        changeParentId() {
            this.$refs.form.validateField('parentId');
        },
        submit() {
            this.$refs.form.validate((valid, obj) => {
                if (valid) {

                    this.submitLoading = true;
                    this.$api.menu.menu(this.form.combination({
                        module: 'admin',
                        method: this.form.menu_id ? 'set.menu.item' : 'add.menu.item',
                    })).then(res => {
                        this.submitLoading = false;
                        this.refresh(1);
                        this.$message.success("操作成功！");

                    }).catch(err => {
                        this.submitLoading = false;
                        console.log(err);
                    })
                } else {
                    this.scrollView(obj)
                };
            });
        },
    },
};
</script>
