<template>
  <el-dialog class="editDialog" :append-to-body="true" width="900px" :visible.sync="showEditDialog"
    :close-on-click-modal="false" :destroy-on-close="true" @close="$emit('update:isShow', false)" :show-close="true">
    <div slot="title" class="title">
      {{ options.goods_id ? "编辑" : "新增" }}商品
    </div>

    <div class="content">
      <el-form label-width="6em" :model="form" :rules="rules" ref="form">
        <el-form-item label="商品分类" prop="goods_category_id" ref="goods_category_id">
          <el-select v-model="form.goods_category_id">
            <el-option v-for="item in goods_category_options" :key="item.goods_category_id" :label="item.name"
              :value="item.goods_category_id">
            </el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="名称" prop="name" ref="name">
          <el-input v-model="form.name" placeholder="请输入名称"></el-input>
        </el-form-item>
        <el-form-item label="划线价" prop="market_price" ref="market_price">
          <el-input-number v-model="form.market_price" controls-position="right" :min="1"></el-input-number>
        </el-form-item>
        <el-form-item label="售价" prop="shop_price" ref="shop_price">
          <el-input-number v-model="form.shop_price" controls-position="right" :min="1"></el-input-number>
        </el-form-item>
        <el-form-item label="库存" prop="store_qty" ref="store_qty">
          <el-input-number v-model="form.store_qty" controls-position="right" :min="0"></el-input-number>
        </el-form-item>
        <el-form-item label="是否包邮" prop="is_fee" ref="is_fee">
          <el-select v-model="form.is_fee">
            <el-option :key="1" label="是" :value="1"></el-option>
            <el-option :key="2" label="否" :value="2"></el-option>
          </el-select>
        </el-form-item>
        <el-form-item v-if="form.is_fee == 2" label="邮费" prop="delivery_fee" ref="delivery_fee">
          <el-input v-model="form.delivery_fee" placeholder="请输入邮费"></el-input>
        </el-form-item>
        <el-form-item label="是否推荐" prop="is_recommend" ref="is_recommend">
          <el-select v-model="form.is_recommend">
            <el-option :key="1" label="是" :value="1"></el-option>
            <el-option :key="0" label="否" :value="0"></el-option>
          </el-select>
        </el-form-item>

        <el-form-item label="排序" prop="sort" ref="sort">
          <el-input-number v-model="form.sort" controls-position="right" :min="1"></el-input-number>
        </el-form-item>

        <el-form-item label="状态" prop="status" ref="status">
          <el-select v-model="form.status">
            <el-option :key="1" label="开启" :value="1"></el-option>
            <el-option :key="2" label="禁用" :value="2"></el-option>
          </el-select>
        </el-form-item>

        <el-form-item label="简介" prop="description" ref="description">
          <el-input type="textarea" :rows="2" placeholder="请输入简介" v-model="form.description">
          </el-input>
        </el-form-item>
        <el-form-item label="内容" prop="content" ref="content">
          <Editor :value.sync="form.content"></Editor>
        </el-form-item>
        <el-form-item label="封面图" prop="attachment" ref="attachment">
          <UploadImage v-model="form.attachment[0]" />
        </el-form-item>
      </el-form>
    </div>

    <div slot="footer">
      <el-button type="success" :loading="submitLoading" @click="submit">保存</el-button>
      <el-button type="info" plain @click="$emit('update:isShow', false)">取消</el-button>
    </div>
  </el-dialog>
</template>
<script>

export default {
  components: {
    UploadImage: () => import("@/components/upload/Image.vue"),
    Editor: () => import("@/components/editor/index.vue"),
  },
  props: {
    isShow: {
      type: Boolean,
      default: false,
    },
    options: {
      type: Object,
      default: function () {
        return {};
      },
    },
  },
  data() {
    return {
      showEditDialog: false,
      submitLoading: false,
      form: {},
      groupData: [],
      goods_category_options: [],
      rules: {
        name: [
          {
            required: true,
            message: "请输入名称",
            trigger: ["blur", "change"],
          },
        ],
        market_price: [
          {
            required: true,
            message: "请输入划线价",
            trigger: ["blur", "change"],
          },
        ],
        shop_price: [
          {
            required: true,
            message: "请输入售价",
            trigger: ["blur", "change"],
          },
        ],
        store_qty: [
          {
            required: true,
            message: "请输入库存",
            trigger: ["blur", "change"],
          },
        ],
        is_fee: [
          {
            required: true,
            message: "请输入是否包邮",
            trigger: ["blur", "change"],
          },
        ],
        delivery_fee: [
          {
            required: true,
            message: "请输入邮费",
            trigger: ["blur", "change"],
          },
        ],
      },
      // 富文本编辑器配置
      content: null,
      editorOption: {
        modules: {
          toolbar: [
            ["bold", "italic", "underline", "strike"], // 加粗 斜体 下划线 删除线
            ["blockquote", "code-block"], // 引用  代码块
            [{ header: 1 }, { header: 2 }], // 1、2 级标题
            [{ list: "ordered" }, { list: "bullet" }], // 有序、无序列表
            [{ script: "sub" }, { script: "super" }], // 上标/下标
            [{ indent: "-1" }, { indent: "+1" }], // 缩进
            // [{'direction': 'rtl'}],                         // 文本方向
            [{ size: ["small", false, "large", "huge"] }], // 字体大小
            [{ header: [1, 2, 3, 4, 5, 6, false] }], // 标题
            [{ color: [] }, { background: [] }], // 字体颜色、字体背景颜色
            [{ font: [] }], // 字体种类
            [{ align: [] }], // 对齐方式
            ["clean"], // 清除文本格式
            ["link", "image", "video"] // 链接、图片、视频
          ], //工具菜单栏配置
        },
        placeholder: '请在这里添加产品描述', //提示
        readyOnly: false, //是否只读
        theme: 'snow', //主题 snow/bubble
        syntax: true, //语法检测
      }
    };
  },
  computed: {
    editor() {
      return this.$refs.myTextEditor.quillEditor;
    }
  },
  inject: ["refresh"],
  watch: {
    isShow: {
      immediate: true, //初始化立即执行
      handler: function (newVal) {
        this.$refs.form && this.$refs.form.resetFields();
        this.showEditDialog = newVal;
      },
    },
    options: {
      immediate: true, //初始化立即执行
      deep: true, //对象深度监测
      handler: function (newVal, oldVal) {
        const xxxx = newVal;
        this.init(xxxx);
      },
    },
  },
  created() { },
  methods: {
    init(options) {
      this.getCategory();
      if (options.goods_id) {
        //编辑进来的会有id需要 (不能直接从列表获取值，好像列表的值不全)
        this.getInfo(options.goods_id);

      } else {
        this.form = { sort: 1, status: 1, attachment: [] }
      }
    },
    getInfo(goods_id) {
      this.submitLoading = true;
      this.$api.goods
        .goods({ method: "get.goods.item", goods_id: goods_id })
        .then((res) => {
          this.submitLoading = false;
          if (res.status == 200) {
            this.form = res.data;
          }
        })
        .catch((err) => {
          this.submitLoading = false;
          console.log(err);
        });
    },
    getCategory() {
      this.submitLoading = true;
      this.$api.goodsCategory
        .goodsCategory(
          this.form.combination({
            module: "admin",
            method: "get.goods_category.select",
          })
        )
        .then((res) => {
          this.submitLoading = false;
          if (res.status == 200) {
            this.goods_category_options = res.data;
          }

        })
        .catch((err) => {
          this.submitLoading = false;
          console.log(err);
        });
    },
    submit() {
      this.$refs.form.validate((valid, obj) => {
        if (valid) {
          this.submitLoading = true;
          this.$api.goods
            .goods(
              {
                goods_category_id: this.form.goods_category_id,
                name: this.form.name,
                market_price: this.form.market_price,
                shop_price: this.form.shop_price,
                description: this.form.description,
                goods_id: this.form.goods_id,
                is_recommend: this.form.is_recommend,
                attachment: this.form.attachment,
                sort: this.form.sort,
                status: this.form.status,
                store_qty: this.form.store_qty,
                content: this.form.content,
                is_fee: this.form.is_fee,
                delivery_fee: this.form.delivery_fee,
              }.combination({
                module: "admin",
                method: this.form.goods_id ? "set.goods.item" : "add.goods.item",
              })
            )
            .then((res) => {
              this.submitLoading = false;
              this.refresh(1);
              this.$message.success("操作成功！");
            })
            .catch((err) => {
              this.submitLoading = false;
              console.log(err);
            });
        } else {
          this.scrollView(obj);
        }
      });
    },
    // 失去焦点
    onEditorBlur(editor) { },
    // 获得焦点
    onEditorFocus(editor) { },
    // 开始
    onEditorReady(editor) { },
    // 值发生变化
    onEditorChange(editor) {
      this.content = editor.html;
      console.log(editor);
    }
  },
};
</script>
