<template>
  <div>
    <Crumbs></Crumbs>
    <div class="dictMain">
      <div class="mainList box-shadow">
        <div class="mainList_operation clearfloat">
          <div class="mainList_operation_search"></div>
          <div class="fr">
            <el-button type="primary" icon="el-icon-plus" @click="add()"
              >新增</el-button
            >
          </div>
        </div>
        <div class="mainList_content">
          <div class="mainList_content_sub">
            <el-table
              ref="multipleTable"
              border
              :data="tableData"
              tooltip-effect="dark"
              height="string"
              :row-style="rowStyle"
              @row-click="rowClick"
              v-loading="loading"
            >
              <el-table-column
                align="center"
                prop="name"
                label="名称"
              ></el-table-column>
              <el-table-column
                prop="code"
                align="center"
                label="标识"
              ></el-table-column>
              <el-table-column
                prop="description"
                align="center"
                label="描述"
              ></el-table-column>
              <el-table-column
                label="操作"
                fixed="right"
                align="center"
                width="160"
              >
                <template slot-scope="scope">
                  <el-button type="text" size="small" @click="edit(scope.row)"
                    >编辑</el-button
                  >
                  <el-button
                    type="text"
                    size="small"
                    class="danger"
                    @click="del(scope)"
                    >删除</el-button
                  >
                </template>
              </el-table-column>
            </el-table>
            <el-pagination
              @size-change="handleSizeChange"
              @current-change="pageChangeHandler"
              :current-page.sync="parameter.page_no"
              :page-size="parameter.page_size"
              prev-text="上一页"
              next-text="下一页"
              :total="parameter.total_result"
            >
            </el-pagination>
          </div>
        </div>
      </div>
      <Sub class="box-shadow" :data="activeData" />
    </div>
    <Edit :isShow.sync="showEditDialog" :options="options"></Edit>
  </div>
</template>
<script>
export default {
  components: {
    Edit: () => import('./Edit.vue'),
    Crumbs: () => import('@/components/Crumbs.vue'),
    Sub: () => import('./Sub.vue'),
  },
  data() {
    return {
      parameter: {
        method: 'get.ads.position.list',
        page_no: 1,
        page_size: 50,
        total_result: 0,
      },
      activeData: {},
      options: {},
      tableData: [],
      loading: false,
      showEditDialog: false,
      editDialogLoading: false,
      activeId: null,
    }
  },
  created() {
    this.getData()
  },
  provide() {
    return {
      refresh: this.refresh,
    }
  },
  methods: {
    refresh() {
      this.showEditDialog = false
      this.pageChangeHandler(1)
    },
    rowClick(row) {
      this.activeData = row
    },
    rowStyle(res) {
      if (res.row.code == this.activeData.code) {
        return {
          background: '#f5f7fa',
        }
      } else {
        return {}
      }
    },
    pageChangeHandler(val) {
      this.parameter.page_no = val
      this.getData()
    },
    handleSizeChange(val) {
      this.parameter.page_size = val
      this.parameter.page_no = 1
      this.getData()
    },
    add() {
      this.options = {}
      this.showEditDialog = true
    },
    edit(row) {
      this.options = row
      this.showEditDialog = true
    },
    getData() {
      this.loading = true

      this.$api.banner
        .banner(this.parameter)
        .then((res) => {
          this.loading = false
          this.tableData = res.data.items
          this.parameter.total_result = res.data.total_result
        })
        .catch((err) => {
          console.log(err)
          this.loading = false
        })
    },
    del(scope) {
      this.$confirm('此操作将永久删除该数据, 是否继续?', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning',
      })
        .then(() => {
          this.$api.banner
            .banner({
              module: 'admin',
              method: 'del.ads.position.list',
              'ads_position_id[]': scope.row.ads_position_id,
            })
            .then((res) => {
              this.$message.success('删除成功！')

              var hasLastPage =
                this.parameter.pageSize * this.parameter.pageNumber >=
                this.parameter.total
              var lastData = this.tableData.length < 2
              if (lastData && hasLastPage && this.parameter.pageNumber > 1) {
                this.parameter.pageNumber--
              }
              this.getData()
            })
            .catch((err) => {
              console.log(err)
            })
        })
        .catch(() => {
          //用户取消
        })
    },
  },
}
</script>
<style scoped lang="scss">
@import '@/styles/config.scss';
.dictMain {
  display: flex;
  justify-content: space-between;
  height: calc(100% - 45px);
  padding: 20px;
  .mainList {
    background: #fff;
    border-radius: 8px;
    .mainList_operation {
      padding: 0;
    }
    .mainList_content_sub {
      padding: 0;
    }
    &:first-child {
      width: 45%;
    }
    &:last-child {
      width: calc(55% - 20px);
    }
  }
}
</style>
