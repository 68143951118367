<template>
    <el-dialog class="editDialog" :append-to-body="true" width="600px" :visible.sync="showEditDialog"
        :close-on-click-modal="false" :destroy-on-close="true" @close="$emit('update:isShow', false)"
        :show-close="true">

        <div slot="title" class="title">{{ options.user_level_id ? '编辑' : '新增' }}会员等级</div>

        <div class="content" v-loading="loading">
            <el-form class="column2" label-width="7em" :model="form" :rules="rules" ref="form">

                <el-form-item label="等级名称" prop="name" ref="name">
                    <el-input v-model="form.name" placeholder="请输入等级名称"></el-input>
                </el-form-item>

                <el-form-item label="图标" prop="icon">
                    <el-autocomplete popperClass="autocompleteIcon" v-model="form.icon"
                        :fetch-suggestions="regularQuerySearch" placeholder="请输入图标" @select="regularHandleSelect">
                        <div slot-scope="{item}" :class="`iconfont icon${item['font_class']}`"></div>
                    </el-autocomplete>
                </el-form-item>

                <el-form-item label="提现利率" prop="discount" ref="discount">
                    <el-input v-model="form.discount" placeholder="请输入提现利率"><template
                            slot="append">%</template></el-input>
                </el-form-item>

                <el-form-item label="有效期" prop="validaty" ref="validaty">
                    <el-input v-model="form.validaty" placeholder="请输入有效期(单位:天)"><template
                            slot="append">天</template></el-input>
                </el-form-item>

                <el-form-item label="原价" prop="original_price" ref="original_price">
                    <el-input v-model="form.original_price" placeholder="请输入原价"><template
                            slot="append">元</template></el-input>
                </el-form-item>

                <el-form-item label="首次优惠价" prop="amount" ref="amount">
                    <el-input v-model="form.amount" placeholder="请输入首次优惠价"><template
                            slot="append">元</template></el-input>
                </el-form-item>

                <el-form-item label="等级描述" prop="description" ref="description">
                    <el-input type="textarea" v-model="form.description" placeholder="请输入等级描述"></el-input>
                </el-form-item>

                <el-form-item label="赠送积分" prop="point" ref="point">
                    <el-input v-model="form.point" placeholder="请输入赠送积分"></el-input>
                </el-form-item>

            </el-form>
        </div>

        <div slot="footer" v-show="!loading">
            <el-button type="success" :loading="submitLoading" @click="submit">保存</el-button>
            <el-button type="info" plain @click="$emit('update:isShow', false)">取消</el-button>
        </div>

    </el-dialog>

</template>
<script>
import iconJson from '@/styles/icon/iconfont.json'

export default {
    props: {
        isShow: {
            type: Boolean,
            default: false
        },
        options: {
            type: Object,
            default: function () {
                return {}
            }
        },
    },
    data() {
        return {
            showEditDialog: false,
            loading: true,
            submitLoading: false,
            form: {},
            groupData: [],
            rules: {
                name: [{ required: true, message: '请输入等级名称', trigger: ['blur', 'change'] }],
                discount: [{ required: true, message: '请输入提现利率', trigger: ['blur', 'change'] }],
                validaty: [{ required: true, message: '请输入有效期', trigger: ['blur', 'change'] }],
                original_price: [{ required: true, message: '请输入原价', trigger: ['blur', 'change'] }],
                amount: [{ required: true, message: '请输入首次优惠价', trigger: ['blur', 'change'] }],
                napoipointntme: [{ required: true, message: '请输入赠送积分', trigger: ['blur', 'change'] }]

            },
        };
    },
    inject: ['refresh'],
    watch: {
        isShow: {
            immediate: true,//初始化立即执行
            handler: function (newVal) {
                this.$nextTick(() => {
                    newVal && this.$refs.form && this.$refs.form.clearValidate();
                });
                this.showEditDialog = newVal;
            }
        },
        options: {
            immediate: true,//初始化立即执行
            deep: true,//对象深度监测
            handler: function (newVal, oldVal) {
                this.init(newVal);
            }
        }
    },
    created() {

    },
    methods: {
        init(options) {
            if (options.user_level_id) {//编辑进来的会有id需要
                this.loading = true;

                this.$api.user.user_level({
                    module: 'admin',
                    user_level_id: options.user_level_id,
                    method: 'get.user.level.item',
                }).then(res => {

                    this.form = res.data;
                    this.loading = false;


                }).catch(err => {
                    this.loading = false;
                    console.log(err);
                })

            } else {
                this.form = {
                    status: 1
                };
                this.loading = false;
            };
        },
        submit() {
            this.$refs.form.validate((valid, obj) => {
                if (valid) {

                    this.submitLoading = true;
                    this.$api.user.user_level(this.form.combination({
                        module: 'admin',
                        method: this.form.user_level_id ? 'set.user.level.item' : 'add.user.level.item',
                    })).then(res => {
                        this.submitLoading = false;
                        this.refresh(1);
                        this.$message.success("操作成功！");

                    }).catch(err => {
                        this.submitLoading = false;
                        console.log(err);
                    })
                } else {
                    this.scrollView(obj)
                };
            });
        },
        regularQuerySearch(queryString, cb) {
            var restaurants = iconJson.glyphs;
            var results = queryString ? restaurants.filter(this.regularCreateFilter(queryString)) : restaurants;
            // 调用 callback 返回建议列表的数据
            cb(results);
        },
        regularCreateFilter(queryString) {
            return (restaurant) => {
                return (`${iconJson['css_prefix_text']}${restaurant['font_class']}`.toLowerCase().indexOf(queryString.toLowerCase()) === 0);
            };
        },
        regularHandleSelect(item) {
            this.form.icon = `${iconJson['css_prefix_text']}${item['font_class']}`;
        },
    },
};
</script>
