<template>
    <el-dialog class="editDialog" :append-to-body="true" width="600px" :visible.sync="showEditDialog"
        :close-on-click-modal="false" :destroy-on-close="true" @close="$emit('update:isShow', false)"
        :show-close="true">

        <div slot="title" class="title">{{ options.group_id ? '编辑' : '新增' }}角色</div>

        <div class="content" v-loading="loading">
            <el-form label-width="6em" :model="form" :rules="rules" ref="form">

                <el-form-item label="角色名称" prop="name" ref="name">
                    <el-input v-model="form.name" placeholder="请输入角色名称"></el-input>
                </el-form-item>

                <el-form-item label="是否启用" prop="status">
                    <el-radio-group v-model="form.status" size="medium">
                        <el-radio :label="1">启用</el-radio>
                        <el-radio :label="0">禁用</el-radio>
                    </el-radio-group>
                </el-form-item>

                <el-form-item label="角色描述" prop="description" ref="description">
                    <el-input type="textarea" v-model="form.description" placeholder="请输入角色描述"></el-input>
                </el-form-item>



            </el-form>
        </div>

        <div slot="footer" v-show="!loading">
            <el-button type="success" :loading="submitLoading" @click="submit">保存</el-button>
            <el-button type="info" plain @click="$emit('update:isShow', false)">取消</el-button>
        </div>

    </el-dialog>

</template>
<script>
import { admin } from "@/api/user";

export default {
    props: {
        isShow: {
            type: Boolean,
            default: false
        },
        options: {
            type: Object,
            default: function () {
                return {}
            }
        },
    },
    data() {
        return {
            showEditDialog: false,
            loading: true,
            submitLoading: false,
            form: {},
            groupData: [],
            rules: {
                name: [{ required: true, message: '请输入角色名称', trigger: ['blur', 'change'] }],

            },
        };
    },
    inject: ['refresh'],
    watch: {
        isShow: {
            immediate: true,//初始化立即执行
            handler: function (newVal) {
                // this.$emit('update:loading', false);
                this.showEditDialog = newVal;
            }
        },
        options: {
            immediate: true,//初始化立即执行
            deep: true,//对象深度监测
            handler: function (newVal, oldVal) {
                this.init(newVal);
            }
        }
    },
    created() {

    },
    methods: {
        init(options) {
            if (options.group_id) {//编辑进来的会有id需要
                this.loading = true;

                this.$api.role.auth_group({
                    module: 'admin',
                    group_id: options.group_id,
                    method: 'get.auth.group.item',
                }).then(res => {

                    this.form = res.data;
                    this.loading = false;


                }).catch(err => {
                    this.loading = false;
                    console.log(err);
                })

            } else {
                this.form = {
                    status: 1
                };
                this.loading = false;
            };
        },
        submit() {
            this.$refs.form.validate((valid, obj) => {
                if (valid) {

                    this.submitLoading = true;
                    this.$api.role.auth_group(this.form.combination({
                        module: 'admin',
                        method: this.form.group_id ? 'set.auth.group.item' : 'add.auth.group.item',
                    })).then(res => {
                        this.submitLoading = false;
                        this.refresh(1);
                        this.$message.success("操作成功！");

                    }).catch(err => {
                        this.submitLoading = false;
                        console.log(err);
                    })
                } else {
                    this.scrollView(obj)
                };
            });
        },
    },
};
</script>
