<template>
  <el-dialog class="editDialog" :append-to-body="true" width="600px" :visible.sync="showEditDialog"
    :close-on-click-modal="false" :destroy-on-close="true" @close="$emit('update:isShow', false)" :show-close="true">
    <div slot="title" class="title">
      {{ options.user_level_id ? "编辑" : "新增" }}会员卡
    </div>

    <div class="content">
      <el-form label-width="6em" :model="form" :rules="rules" ref="form">

        <el-form-item label="名字" prop="name" ref="name">
          <el-input v-model="form.name" placeholder="请输入名字"></el-input>
        </el-form-item>

        <el-form-item label="会员卡图标" prop="icon" ref="icon">
          <el-input v-model="form.icon"></el-input>
        </el-form-item>

        <el-form-item label="有效期" prop="validaty" ref="validaty">
          <el-input v-model="form.validaty"></el-input>
        </el-form-item>

        <el-form-item label="首次购买价格" prop="original_price" ref="original_price">
          <el-input v-model="form.original_price"></el-input>
        </el-form-item>

        <el-form-item label="会员卡价格" prop="amount" ref="amount">
          <el-input v-model="form.amount"></el-input>
        </el-form-item>

        <el-form-item label="赠送积分" prop="point" ref="point">
          <el-input v-model="form.point"></el-input>
        </el-form-item>

        <el-form-item label="描述" prop="description" ref="description">
          <el-input type="textarea" :rows="2" placeholder="请输入简介" v-model="form.description">
          </el-input>
        </el-form-item>
      </el-form>
    </div>

    <div slot="footer">
      <el-button type="success" :loading="submitLoading" @click="submit">保存</el-button>
      <el-button type="info" plain @click="$emit('update:isShow', false)">取消</el-button>
    </div>
  </el-dialog>
</template>
<script>
import { mobile, email } from '@/regular'

export default {
  props: {
    isShow: {
      type: Boolean,
      default: false,
    },
    options: {
      type: Object,
      default: function () {
        return {}
      },
    },
  },
  data() {
    return {
      showEditDialog: false,
      submitLoading: false,
      form: {},
      groupData: [],
      rules: {
        name: [
          {
            required: true,
            message: '请输入名字',
            trigger: ['blur', 'change'],
          },
        ],
      },
    }
  },
  inject: ['refresh'],
  watch: {
    isShow: {
      immediate: true, //初始化立即执行
      handler: function (newVal) {
        this.$refs.form && this.$refs.form.resetFields()
        this.showEditDialog = newVal
      },
    },
    options: {
      immediate: true, //初始化立即执行
      deep: true, //对象深度监测
      handler: function (newVal, oldVal) {
        const xxx = newVal
        this.init(xxx)
      },
    },
  },
  created() { },
  methods: {
    init(options) {
      console.log(options)
      if (options.user_level_id) {
        //编辑进来的会有id需要
        this.form = options
        console.log(this.form)
      } else {
        this.form = {}
      }
    },
    submit() {
      this.$refs.form.validate((valid, obj) => {
        if (valid) {
          this.submitLoading = true
          this.$api.userLevel
            .userLevel(
              this.form.combination({
                module: 'admin',
                method: this.form.user_level_id
                  ? 'set.user.level.item'
                  : 'add.user.level.item',
              })
            )
            .then((res) => {
              this.submitLoading = false
              this.refresh(1)
              this.$message.success('操作成功！')
            })
            .catch((err) => {
              this.submitLoading = false
              console.log(err)
            })
        } else {
          this.scrollView(obj)
        }
      })
    },
  },
}
</script>
