<template>
  <div>
    <Crumbs></Crumbs>
    <div class="mainList">
      <div
        v-if="multipleSelection.length"
        class="mainList_operation clearfloat"
      >
        <div class="fr">
          <el-button type="danger" @click="delMore">批量删除</el-button>
          <el-button @click="changeStatusMore()">批量启用</el-button>
          <el-button @click="changeStatusMore(2)">批量禁用</el-button>
          <el-button @click="toggleSelection()">取消选择</el-button>
        </div>
      </div>
      <div class="mainList_content">
        <div class="mainList_content_sub">
          <el-table
            ref="multipleTable"
            border
            :data="tableData"
            tooltip-effect="dark"
            height="string"
            v-loading="loading"
            @selection-change="handleSelectionChange"
          >
            <el-table-column
              align="center"
              type="selection"
              width="55"
            ></el-table-column>
            <el-table-column
              label="序号"
              type="index"
              align="center"
              width="70"
            ></el-table-column>
            <el-table-column
              align="center"
              prop="user_id"
              label="发布人ID"
            ></el-table-column>
            <el-table-column align="left" label="发布人昵称">
              <template slot-scope="scope">
                <div class="img_box">
                  <el-avatar
                    v-if="scope.row.head_pic"
                    :src="scope.row.head_pic"
                    size="small"
                  ></el-avatar>
                  <span> {{ scope.row.nickname }}</span>
                </div>
              </template>
            </el-table-column>
            <el-table-column
              align="center"
              prop="content"
              label="评论内容"
            ></el-table-column>
            <el-table-column
              align="center"
              prop="give_praise_num"
              label="点赞数量"
            ></el-table-column>
            <el-table-column
              align="center"
              prop="type_text"
              label="类型"
            ></el-table-column>
            <el-table-column
              align="center"
              prop="status_text"
              label="状态"
            ></el-table-column>
            <el-table-column
              align="center"
              prop="remarks"
              label="备注"
            ></el-table-column>
            <el-table-column
              align="center"
              prop="update_time"
              label="编辑时间"
            ></el-table-column>
            <el-table-column label="操作" align="center" width="200">
              <template slot-scope="scope">
                <el-button type="text" size="small" @click="viewReply(scope)"
                  >查看回复</el-button
                >
                <template>
                  <el-button
                    type="text"
                    v-if="scope.row.status == 1"
                    size="small"
                    @click="changeStatus(scope)"
                    >禁用</el-button
                  >
                  <el-button
                    type="text"
                    v-else
                    size="small"
                    @click="changeStatus(scope)"
                    >启用</el-button
                  >
                </template>
                <el-button
                  class="danger"
                  type="text"
                  size="small"
                  @click="del(scope)"
                >
                  删除</el-button
                >
              </template>
            </el-table-column>
          </el-table>
          <el-pagination
            @size-change="handleSizeChange"
            @current-change="pageChangeHandler"
            :current-page.sync="parameter.page_no"
            :page-size="parameter.page_size"
            prev-text="上一页"
            next-text="下一页"
            :total="parameter.total_result"
          >
          </el-pagination>
        </div>
      </div>
    </div>
    <ViewReply
      :isShow.sync="showViewReplyDialog"
      :options="options"
    ></ViewReply>
  </div>
</template>
<script>
export default {
  components: {
    ViewReply: () => import('./ViewReply.vue'),
    Crumbs: () => import('@/components/Crumbs.vue'),
  },
  data() {
    return {
      parameter: {
        method: 'get.user_moment.list',
      },
      options: {},
      tableData: [],
      loading: false,
      showViewReplyDialog: false,
      multipleSelection: [],
    }
  },
  provide() {
    return {
      refresh: this.refresh,
    }
  },
  watch: {},
  created() {
    this.getData()
  },
  methods: {
    refresh() {
      this.showViewReplyDialog = false
      this.pageChangeHandler(1)
    },
    pageChangeHandler(val) {
      this.parameter.page_no = val
      this.getData()
    },
    handleSizeChange(val) {
      this.parameter.page_size = val
      this.parameter.page_no = 1
      this.getData()
    },
    viewReply(scope) {
      this.options = scope.row
      this.showViewReplyDialog = true
    },
    getData() {
      this.loading = true
      this.$api.user
        .user_moment(this.parameter)
        .then((res) => {
          this.loading = false
          this.tableData = res.data.items
          this.parameter.total_result = res.data.total_result
        })
        .catch((err) => {
          console.log(err)
          this.loading = false
        })
    },
    del(scope) {
      this.delFc([scope.row.user_moment_id])
    },
    delMore() {
      const delList = []
      this.multipleSelection.forEach((item) =>
        delList.push(item.user_moment_id)
      )
      this.delFc(delList)
    },
    delFc(delData) {
      this.$confirm('此操作将永久删除该数据, 是否继续?', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning',
      })
        .then(() => {
          console.log(123)
          this.$api.user
            .user_moment({
              method: 'del.user_moment.list',
              user_moment_id: delData,
            })
            .then((res) => {
              this.$message.success('删除成功！')
              this.getData()
            })
            .catch((err) => {
              console.log(err)
            })
        })
        .catch(() => {
          //用户取消
        })
    },
    changeStatus(scope) {
      const status = scope.row.status == 1 ? 2 : 1
      this.changeStatusFc(status, [scope.row.user_moment_id])
    },
    changeStatusMore(status = 1) {
      const stausList = []
      this.multipleSelection.forEach((item) =>
        stausList.push(item.user_moment_id)
      )
      this.changeStatusFc(status, stausList)
    },
    changeStatusFc(status, statusData) {
      this.$confirm(
        `此操作将${status == 1 ? '启用' : '禁用'}该数据, 是否继续?`,
        '提示',
        {
          confirmButtonText: '确定',
          cancelButtonText: '取消',
          type: 'warning',
        }
      )
        .then(() => {
          this.$api.user
            .user_moment({
              method: 'set.user_moment.status',
              status,
              user_moment_id: statusData,
            })
            .then((res) => {
              this.$message.success(`${status == 1 ? '启用' : '禁用'}成功！`)
              this.getData()
            })
            .catch((err) => {
              console.log(err)
            })
        })
        .catch(() => {
          //用户取消
        })
    },
    toggleSelection(rows) {
      if (rows) {
        rows.forEach((row) => {
          this.$refs.multipleTable.toggleRowSelection(row)
        })
      } else {
        this.$refs.multipleTable.clearSelection()
      }
    },
    handleSelectionChange(val) {
      this.multipleSelection = val
    },
  },
}
</script>
<style scoped lang="scss">
@import '@/styles/config.scss';
.img_box {
  display: inline-flex;
  justify-content: left;
  align-items: flex-end;
  span {
    margin-left: 5px;
  }
}
</style>
