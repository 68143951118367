<template>
  <div>
    <Crumbs></Crumbs>
    <div class="mainList">
      <div class="mainList_operation clearfloat">
        <div class="mainList_operation_search">
          <el-input class="item" placeholder="请输入商品名称" clearable v-model="parameter.keywords"></el-input>
          <el-select class="item" placeholder="请选择优惠券类型" v-model="parameter.type">
            <el-option label="新用户优惠券" :value="1"></el-option>
            <el-option label="专属优惠券" :value="2"></el-option>
            <el-option label="邀新奖励优惠券" :value="3"></el-option>
          </el-select>
          <el-button type="primary" plain icon="el-icon-search" @click="pageChangeHandler(1)">搜索</el-button>
        </div>
        <div class="fr">
          <el-button type="primary" icon="el-icon-plus" @click="add">新增</el-button>
        </div>

      </div>
      <div class="mainList_content">
        <div class="mainList_content_sub">
          <el-table ref="multipleTable" border :data="tableData" tooltip-effect="dark" height="string" v-loading="loading" @selection-change="setSelectRows">
            <!-- <el-table-column align="center" show-overflow-tooltip type="selection" width="55"></el-table-column> -->
            <el-table-column align="center" type="index" width="70" label="序号"></el-table-column>
            <el-table-column align="left" prop="name" width="120" label="优惠券名称"></el-table-column>
            <el-table-column align="left" prop="type" width="120" label="优惠券类型">
              <template slot-scope="scope">
                {{  scope.row.type | showCouponType  }}
              </template>
            </el-table-column>
            <el-table-column align="left" prop="module" width="150" label="专属优惠券类型">
              <template slot-scope="scope">
                {{  scope.row.module | showCouponModule  }}
              </template>
            </el-table-column>
            <el-table-column align="left" prop="money" label="优惠金额"></el-table-column>
            <el-table-column align="left" prop="quota" label="最低使用金额"></el-table-column>
            <el-table-column align="left" prop="frequency" label="最多领取次数"></el-table-column>
            <el-table-column align="left" prop="validity" label="有效期(天)"></el-table-column>
            <el-table-column align="left" prop="status" label="状态">
              <template slot-scope="scope">
                {{  scope.row.status | showStatus  }}
              </template>
            </el-table-column>
            <el-table-column label="操作" align="center" width="120" fixed="right">
              <template slot-scope="scope">
                <el-button type="text" size="small" @click="edit(scope)">编辑</el-button>
                <el-button type="text" size="small" @click="giveAs(scope)">发券</el-button>
              </template>
            </el-table-column>
          </el-table>
          <el-pagination @size-change="handleSizeChange" @current-change="pageChangeHandler" :current-page.sync="parameter.page_no" :page-size="parameter.page_size" prev-text="上一页" next-text="下一页" :total="parameter.total_result">
          </el-pagination>
        </div>
      </div>
    </div>
    <Edit :isShow.sync="showEditDialog" :options="options"></Edit>
    <el-dialog title="发券" :visible.sync="showGiveAsUserDialog" width="50%">
      <div style="margin-bottom: 100px;">
        <ChoiceData :selectedData="giveAsUsers" :configure="userConfigure" @choiceDataChange="userDataChange">
          <el-input slot="button" v-model="giveAsUserNamesText" placeholder="请选择用户"></el-input>
          <div slot="choiceItem" slot-scope="{ data }">{{data.nickname}}</div>
          <div class="mainList_operation clearfloat" slot="search" slot-scope="{ params, search }">
            <div class="mainList_operation_search">
              <el-input class="item" placeholder="请输入昵称" clearable v-model="params.nickname"></el-input>
              <el-input class="item" placeholder="请输入手机号" clearable v-model="params.mobile"></el-input>
              <el-button type="primary" plain icon="el-icon-search" @click="search(1)">搜索</el-button>
            </div>
          </div>
          <template slot="tableColumn">
            <el-table-column align="center" prop="nickname" label="昵称"></el-table-column>
            <el-table-column align="center" prop="mobile" label="手机号"></el-table-column>
            <el-table-column align="center" prop="email" label="邮箱"></el-table-column>
            <el-table-column align="center" prop="birthday" label="出生日期"></el-table-column>
          </template>
        </ChoiceData>
        <div style="margin:30px auto;">
          <el-alert title="优惠券将发放给如下用户：" :closable="false" type="warning"></el-alert>
        </div>
        <el-table :data="giveAsUsers">
          <el-table-column align="center" prop="nickname" label="昵称"></el-table-column>
          <el-table-column align="center" prop="mobile" label="手机号"></el-table-column>
          <el-table-column align="center" prop="email" label="邮箱"></el-table-column>
          <el-table-column align="center" prop="birthday" label="出生日期"></el-table-column>
        </el-table>
      </div>
      <span slot="footer" class="dialog-footer">
        <el-button @click="showGiveAsUserDialog = false">取 消</el-button>
        <el-button type="primary" @click="affirmGiveAsCoupon">确 定</el-button>
      </span>
    </el-dialog>
  </div>
</template>
<script>
export default {
  components: {
    Edit: () => import('./Edit.vue'),
    Crumbs: () => import('@/components/Crumbs.vue'),
    ChoiceData: () => import('@/components/ChoiceData.vue'),
  },
  data() {
    return {
      parameter: {
        method: 'get.coupon.list',
        page_no: 1,
        page_size: 50,
        total_result: 0,
        keywords: '',
        type: '',
        order_field: 'type',
        order_type: 'desc',
      },
      selectRows: [],
      activeData: {},
      options: {},
      tableData: [],
      loading: false,
      showEditDialog: false,
      showGiveAsUserDialog: false,
      giveAsCouponId: '',
      giveAsUsers: [],
      giveAsUserNamesText: '',
      userConfigure: {
        request: this.$api.user.user,
        idKey: 'user_id',
        type: 'checkbox',
        params: {
          method: 'get.user.list',
        },
        title: '请选择用户',
      },
    }
  },
  provide() {
    return {
      refresh: this.refresh,
    }
  },
  watch: {},
  created() {
    this.getData()
  },
  methods: {
    setSelectRows(val) {
      this.selectRows = val
    },
    refresh() {
      this.showEditDialog = false
      this.pageChangeHandler(1)
    },
    pageChangeHandler(val) {
      this.parameter.page_no = val
      this.getData()
    },
    handleSizeChange(val) {
      this.parameter.page_size = val
      this.parameter.page_no = 1
      this.getData()
    },
    edit(scope) {
      this.options = JSON.parse(JSON.stringify(scope.row))
      this.showEditDialog = true
    },
    add() {
      this.options = {}
      this.showEditDialog = true
    },
    getData() {
      this.loading = true
      this.$api.coupon
        .coupon(this.parameter)
        .then((res) => {
          this.loading = false
          console.log(res.data)
          if (res.data.items && Array.isArray(res.data.items)) {
            this.tableData = res.data.items
          } else {
            this.tableData = []
          }
          this.parameter.total_result = res.data.total_result
        })
        .catch((err) => {
          console.log(err)
          this.loading = false
        })
    },
    /***
     * 删除方法(暂无接口)
     * @param row
     * @returns {boolean}
     */
    handleDelete(row) {
      const ids = []
      if (row.coupon_id) {
        ids.push(id)
      } else {
        if (this.selectRows.length > 0) {
          const itemIds = this.selectRows.map((item) => item.coupon_id)
          for (var i = 0; i < itemIds.length; i++) {
            ids.push(itemIds[i])
          }
        } else {
          this.$message.error('未选中任何行')
          return false
        }
      }

      console.log(ids)
      this.$confirm('此操作将永久删除该数据, 是否继续?', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning',
      })
        .then(() => {
          this.$api.goods
            .goods({
              module: 'admin',
              method: 'del.coupon.list',
              coupon_id: ids,
            })
            .then((res) => {
              if (res.status == 200) {
                this.$message.success('删除成功！')
                var hasLastPage =
                  this.parameter.page_size * this.parameter.page_no >=
                  this.parameter.total_result
                var lastData = this.tableData.length < 2
                if (lastData && hasLastPage && this.parameter.page_no > 1) {
                  this.parameter.page_no--
                }
                this.getData()
              }
            })
            .catch((err) => {
              console.log(err)
            })
        })
        .catch(() => {
          //用户取消
        })
    },
    userDataChange(datas) {
      this.giveAsUsers = datas
      this.giveAsUserNamesText =
        datas
          .slice(0, 5)
          .map((item) => {
            return item.nickname
          })
          .join('、') + '...'
    },
    // 发券
    giveAs(scope) {
      const item = JSON.parse(JSON.stringify(scope.row))
      this.giveAsCouponId = item.coupon_id
      this.showGiveAsUserDialog = true
    },
    // 确认发券
    affirmGiveAsCoupon() {
      this.$confirm(
        '确定要发券吗?发券后无法撤回,请谨慎操作并核对人员信息无误!',
        '提示',
        {
          confirmButtonText: '确认发券',
          cancelButtonText: '取消',
          type: 'warning',
        }
      )
        .then(() => {
          this.$api.coupon
            .coupon_give({
              method: 'add.coupon_give.all',
              coupon_id: this.giveAsCouponId,
              user_id: this.giveAsUsers.map((item) => {
                return item.user_id
              }),
            })
            .then((res) => {
              console.log(res)
              this.showGiveAsUserDialog = false
              this.giveAsCouponId = ''
              this.giveAsUsers = []
              this.giveAsUserNamesText = ''
              this.$message({
                message: '发券成功',
                type: 'success',
              })
            })
        })
        .catch(() => {})
    },
  },
}
</script>
<style scoped lang='scss'>
@import '@/styles/config.scss';
</style>
