<template>
  <el-dialog class="editDialog" :append-to-body="true" width="600px" :visible.sync="showEditDialog"
    :close-on-click-modal="false" :destroy-on-close="true" @close="$emit('update:isShow', false)" :show-close="true">
    <div slot="title" class="title">
      {{ options.user_address_id ? "编辑" : "新增" }}用户地址
    </div>

    <div class="content">
      <el-form label-width="6em" :model="form" :rules="rules" ref="form">
        <el-form-item label="姓名" prop="consignee" ref="consignee">
          <el-input v-model="form.consignee" placeholder="请输入姓名"></el-input>
        </el-form-item>


        <el-form-item label="手机号" prop="mobile" ref="mobile">
          <el-input v-model="form.mobile" placeholder="请输入手机号"></el-input>
        </el-form-item>


        <el-form-item label="地区" prop="district" ref="district">
          <AreaChoice placeholder="请选择地区" v-model="form.district" @areaChange="areaChange" />
        </el-form-item>

        <el-form-item label="详细地址" prop="address" ref="address">
          <el-input v-model="form.address" placeholder="请输入详细地址"></el-input>
        </el-form-item>


      </el-form>
    </div>

    <div slot="footer">
      <el-button type="success" :loading="submitLoading" @click="submit">保存</el-button>
      <el-button type="info" plain @click="$emit('update:isShow', false)">取消</el-button>
    </div>
  </el-dialog>
</template>
<script>
import { mobile, email } from "@/regular";

export default {
  components: {
    AreaChoice: () => import("@/components/AreaChoice.vue"),
  },
  props: {
    isShow: {
      type: Boolean,
      default: false,
    },
    options: {
      type: Object,
      default: function () {
        return {};
      },
    },
  },
  data() {

    return {
      showEditDialog: false,
      submitLoading: false,
      form: {
        district: ''
      },
      areaData: [],
      rules: {

      },
    };
  },
  inject: ["refresh"],
  watch: {
    isShow: {
      immediate: true, //初始化立即执行
      handler: function (newVal) {
        //this.$refs.form && this.$refs.form.resetFields();
        this.showEditDialog = newVal;
      },
    },
    options: {
      immediate: true, //初始化立即执行
      deep: true, //对象深度监测
      handler: function (newVal, oldVal) {
        this.form = newVal.deepClone();
      },
    },
  },
  created() { },
  methods: {
    areaChange(r) {

      try {
        this.form.city = r[0].parent.data.region_id
      } catch (e) {
        console.log(e)
      };
      try {
        this.form.province = r[0].parent.parent.data.region_id
      } catch (e) {
        console.log(e)

      };
    },

    submit() {
      this.$refs.form.validate((valid, obj) => {
        if (valid) {
          this.submitLoading = true;
          this.$api.user.user_address(
            this.form.combination({
              module: "admin",
              method: this.form.user_address_id ? "set.user.address.item" : "add.user.address.item",
            })
          )
            .then((res) => {
              this.submitLoading = false;
              this.$message.success("操作成功！");
            })
            .catch((err) => {
              this.submitLoading = false;
              console.log(err);
            });
        } else {
          this.scrollView(obj);
        }
      });
    },
  },
};
</script>
