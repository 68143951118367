<template>

  <div class="UploadImage" v-loading="file.loading">

    <div v-if="file.fileUrl" class="UploadImageBtn" style="cursor: auto;">
      <video class="video" :src="file.fileUrl" controls></video>
      <div class="operation">
        <Upload @upload="upload" accept="video/*">
          <i slot="button" class="el-icon-upload2"></i>
        </Upload>
        <i class="el-icon-delete" @click="$emit('change', '')"></i>
      </div>
    </div>

    <Upload v-else @upload="upload" accept="video/*">
      <div slot="button" class="UploadImageBtn">
        <i class="el-icon-plus avatar-uploader-icon"></i>
      </div>
    </Upload>

  </div>
</template>

<script>
export default {
  props: {
    disabled: {
      type: Boolean,
      default: false,
    },
    value: {},
  },
  components: {
    Upload: () => import('./Upload.vue'),
  },
  model: {
    prop: 'value',
    event: 'change',
  },
  data() {
    return {
      file: {
        loading: false,
        fileUrl: '',
      },
    }
  },
  computed: {},
  watch: {
    value: {
      immediate: true, //初始化立即执行
      handler: function (newVal) {
        this.file.fileUrl = newVal
      },
    },
    file: {
      deep: true,
      handler: function (newVal) {
        if (!newVal.loading) {
          this.$emit('change', newVal.fileUrl)
        }
      },
    },
  },
  mounted() {},
  methods: {
    upload(r) {
      this.file = r[0]
    },
  },
}
</script>

<style lang="scss" scoped>
.UploadImage {
  display: inline-block;
  text-align: center;
  .el-image {
    display: block;
    margin-bottom: 10px;
  }
  /deep/.UploadImageBtn {
    position: relative;
    font-size: 28px;
    color: #8c939d;
    width: 100px;
    height: 100px;
    line-height: 100px;
    text-align: center;
    border: 1px dashed #d9d9d9;
    border-radius: 6px;
    cursor: pointer;
    .video {
      width: 100%;
      height: 100%;
    }
    &:hover {
      .operation {
        display: block;
      }
    }
    .operation {
      position: absolute;
      top: 0;
      left: 0;
      right: 0;
      bottom: 0;
      pointer-events: none;
      background: rgba(0, 0, 0, 0.5);
      display: none;
      .el-icon-delete {
        position: absolute;
        top: 10px;
        right: 10px;
        color: #fff;
        font-size: 16px;
        pointer-events: auto;
        cursor: pointer;
      }
      .el-icon-upload2 {
        position: absolute;
        top: 10px;
        right: 35px;
        color: #fff;
        font-size: 16px;
        pointer-events: auto;
        cursor: pointer;
      }
    }
  }
}
</style>
